






























import Vue from 'vue'
import { mapGetters } from 'vuex'
import Chip from '~/components/PassporterUI/Chip.vue'
import Calendar from '~/passporter-services/shared/calendar'
import CircleProgressBar from '~/components/context/items/itineraries/configuration/CircleProgressBar.vue'
import Cover from '~/components/PassporterUI/Cover.vue'

export default Vue.extend({
	name: 'ProfileItineraryForm',
	components: { Cover, CircleProgressBar, Chip },
	props: {
		urlCover: {
			type: String,
			default: undefined,
		},
		destination: {
			type: String,
			default: undefined,
		},
		startDate: {
			type: String,
			default: undefined,
		},
		endDate: {
			type: String,
			default: undefined,
		},
		timePeriod: {
			type: String,
			default: undefined,
		},
		percent: {
			type: Number,
			default: 0,
		},
		isMobile: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			calendar: new Calendar(this.$i18n.locale),
		}
	},
	computed: {
		...mapGetters({
			theme: 'theme',
		}),
		defineHeader(): string {
			let result = this.$t('createTrip_title_myTripTo')

			if (this.destination) {
				result = this.$t('createTrip_title_desktop_myTripToDestination', {
					Destination: this.destination,
				})
			}
			return result as string
		},
		defineTime(): string {
			let result = ''
			if (this.startDate) {
				result = `${this.calendar.formatShowMothName(this.startDate)}`
				if (this.endDate) {
					result += ` - ${this.calendar.formatShowMothName(this.endDate)}`
				}
			}

			if (this.timePeriod) {
				result = this.periods()[this.timePeriod]
			}
			return result
		},
		formatedPercentage(): string {
			return `${this.percent}%`
		},
	},
	methods: {
		periods(): Record<string, string> {
			return {
				'2': this.$t('B2C_itinerarycreation_step1_selector_period_weekend') as string,
				'7': this.$t('B2C_itinerarycreation_step1_selector_period_oneweek') as string,
				'14': this.$t('B2C_itinerarycreation_step1_selector_period_twoweeks') as string,
			}
		},
	},
})
