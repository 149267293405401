import { DestinationMapperEntity } from '~/passporter-services/destination/toEntity'
import { SiteInfo } from '~/models/SiteInfo'
import { LinkEntityMapper } from '~/passporter-services/link/entityMapper'
import Guide, { InspirationSource } from '~/models/Guide'
import { Site2 as Site, ClientSite, Stat } from '~/models/Site'

export class SiteEntityMapper {
	static toSite = (site: any): Site => {
		const destination = DestinationMapperEntity.toDestination(site.destination)
		const result = {
			id: site.id,
			views: site.num_views,
			hasContent: site.has_content,
			destination,
			isOficial: !!site.client,
		} as Site

		if (site.client) {
			result.client = this.toClient(site.client)
		}

		if (site.stats) {
			result.stats = this.toStats(site.stats)
		}

		return result
	}

	private static toClient = (client: Record<string, any>): ClientSite => {
		const result = {
			userId: client.user_id,
			name: client.name,
			image: client.picture_url,
		}
		return result
	}

	private static toStats = (stats: Record<string, any>[]): Stat[] => {
		const result = stats.map((stat) => {
			const item = {
				id: stat.id,
				name: stat.name,
				iconUrl: stat.icon_url,
				percentage: stat.percentage,
			}
			return item
		})
		return result
	}

	static toInfo = (info: Record<string, any>): SiteInfo => {
		const links = LinkEntityMapper.toLinks(info.links)
		const parsed = {
			id: info.id,
			title: info.title,
			summary: info.summary,
			description: info.description,
			icon: info.icon_url,
			links,
		}
		return new SiteInfo(parsed)
	}

	static toGuide = (content: any) => {
		return new Guide({
			id: content.id,
			title: content.title,
			subtitle: content.subtitle,
			description: content.description,
			state: content.state,
			spotsNumber: content.num_spots,
			destinations: content.num_destinations,
			travelers: content.num_travelers,
			notes: content.num_notes,
			views: content.num_views,
			covers: content.covers,
			creatorId: content.creator_id,
			travelersPictures: content.travelers_pictures,
			inspirationSources: content.sources ? this.toInspirationSources(content.sources) : undefined,
		})
	}

	static toGuides = (contents: Record<string, any>[]): Guide[] => {
		return contents.map((content) => {
			return this.toGuide(content)
		})
	}

	static toInspirationSources = (sources: Record<string, any>[]): InspirationSource[] => {
		const result = sources.map((source) => {
			const item: InspirationSource = {
				name: source.name,
				url: source.url,
			}
			if (source.logo) {
				item.logo = source.logo
			}
			return item
		})
		return result
	}
}
