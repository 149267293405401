import { gAnalytics4 } from '~/plugins/google-analytics-config'

export default class Tracker {
	mixpanel
	constructor(mixpanel: any) {
		this.mixpanel = mixpanel
	}

	event(event: string, params: Record<string, string | boolean>) {
		if (!process.client) return
		GoogleTracker.sendAnalytics(event, params)
		this.mixpanel.track(event, params)
	}

	getContext(routeName: string | null | undefined): string | undefined {
		if (!routeName) return

		const context: Record<string, string> = {
			'destinations-destination_id': 'microsite',
			'itineraries-itinerary_id-destinations-destination_id': 'microsite',
			'destinations-destination_id-guides-guide_id-spots-spot_id': 'microsite',
			'itineraries-itinerary_id-destinations-destination_id-spots-spot_id': 'microsite',
			'destinations-destination_id-map': 'map',
			'destinations-destination_id-spots-spot_id': 'map',
			'itineraries-itinerary_id-destinations-destination_id-map': 'map',
			'destinations-destination_id-guides-guide_id': 'guide',
			'itineraries-itinerary_id-destinations-destination_id-guides-guide_id': 'guide',
			'itineraries-itinerary_id-destinations-destination_id-guides-guide_id-spots-spot_id': 'guide',
			'itineraries-itinerary_id-spots-spot_id': 'itinerary',
			'itineraries-itinerary_id': 'travel dashboard',
			'itineraries-itinerary_id-map': 'travel dashboard',
			'home': 'home',
		}
		const name = routeName.split('___')?.[0]
		return context[name] || undefined
	}
}

class GoogleTracker {
	static sendAnalytics(event: string, params: Record<string, string | boolean>) {
		// @ts-ignore
		gAnalytics4('event', event, params)
	}
}
