



























import Vue from 'vue'

export default Vue.extend({
	name: 'Dropdown',
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		hoverable: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isActive: false,
			hoverOutTimeOut: undefined as ReturnType<typeof setTimeout> | undefined,
			contentTop: undefined as number | undefined,
			contentBottom: undefined as number | undefined,
			contentLeft: undefined as number | undefined,
			contentRight: undefined as number | undefined,
		}
	},
	methods: {
		open() {
			if (!this.disabled) {
				this.isActive = true
				if (this.isActive) {
					this.$nextTick(() => {
						this.adjustPosition()
					})
				}
			}
		},
		close() {
			this.isActive = false
		},
		toggle() {
			if (this.isActive) {
				this.close()
			} else {
				this.open()
			}
		},
		adjustPosition(step: number = 0) {
			this.$nextTick(() => {
				const _gap = 4
				const _trigger = this.$refs.trigger as HTMLDivElement
				const _triggerRect = _trigger.getBoundingClientRect()
				const _content = this.$refs.content as HTMLDivElement
				const _contentRect = _content.getBoundingClientRect()

				if (step === 0) {
					this.contentTop = _triggerRect.bottom
					this.contentBottom = undefined
					this.contentLeft = _triggerRect.left
					this.contentRight = undefined

					if (this.contentTop + _contentRect.height > window.innerHeight - 24) {
						this.contentTop = undefined
						this.contentBottom = window.innerHeight - _triggerRect.top
					}
					if (this.contentLeft + _contentRect.width > window.innerWidth - 24) {
						this.contentLeft = undefined
						this.contentRight = window.innerWidth - _triggerRect.right
					}
				}

				if (step === 1) {
					if (
						this.contentRight &&
						this.contentRight + _contentRect.width > window.innerWidth - 24
					) {
						this.contentLeft = 24
						this.contentRight = 24
					}
				}

				_content.style.top = this.contentTop !== undefined ? this.contentTop + _gap + 'px' : 'auto'
				_content.style.bottom =
					this.contentBottom !== undefined ? this.contentBottom + _gap + 'px' : 'auto'
				_content.style.left = this.contentLeft !== undefined ? this.contentLeft + 'px' : 'auto'
				_content.style.right = this.contentRight !== undefined ? this.contentRight + 'px' : 'auto'

				if (step < 1) {
					this.adjustPosition(step + 1)
				}
			})
		},
		hoverIn() {
			if (this.hoverable) {
				if (this.hoverOutTimeOut) {
					clearTimeout(this.hoverOutTimeOut)
				}
				if (!this.isActive) {
					this.open()
				}
			}
		},
		hoverOut() {
			if (this.hoverable) {
				this.hoverOutTimeOut = setTimeout(() => {
					if (this.isActive) {
						this.close()
					}
				}, 200)
			}
		},
	},
})
