import { Context } from '@nuxt/types'

export default (context: Context) => ({
	getTermsRoute(): string {
		const routes: Record<string, string> = {
			es: 'https://passporter.notion.site/T-rminos-y-condiciones-66bfe07fd1af4e7d9600091e862ec23f',
			en: 'https://passporter.notion.site/Terms-and-conditions-5ad8475297d542ee83db8e457a575541',
		}
		let route = routes[context.i18n.locale]
		if (!route) {
			route = routes.en
		}
		return route
	},
	getPrivacyPolicyRoute(): string {
		const routes: Record<string, string> = {
			es: 'https://passporter.notion.site/Pol-tica-de-privacidad-d1143ad3c7124b51a630da558d73ed88',
			en: 'https://passporter.notion.site/Privacy-Policy-db306d498f8f4600aef7a1089b708792',
		}
		let route = routes[context.i18n.locale]
		if (!route) {
			route = routes.en
		}
		return route
	},
})
