import Profile from '~/models/Profile'
import BaseService from '~/services/BaseService'
import { ProfileEntityMapper } from '~/passporter-services/profile/entityMapper'

export default class ProfileService extends BaseService {
	async obtainUser(id: string): Promise<Profile | undefined> {
		const res = await this.repositories.profile.user(id)
		const { status, data } = res
		if (status !== 200) return undefined
		const user = ProfileEntityMapper.toProfile(data)
		return user
	}
}
