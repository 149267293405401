export type SearcherCategoryType =
	| 'google'
	| 'googleDestination'
	| 'place'
	| 'destination'
	| 'itinerary'
	| 'traveler'

export default class SearcherCategory {
	id: SearcherCategoryType
	icon?: string
	name: string
	app?: boolean
	googleApi?: boolean
	constructor(searcherCategory: SearcherCategory) {
		this.id = searcherCategory.id
		this.icon = searcherCategory.icon
		this.name = searcherCategory.name
		this.app = searcherCategory.app
		this.googleApi = searcherCategory.googleApi
	}
}
