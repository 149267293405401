














































































import Vue, { PropOptions } from 'vue'
import { mapGetters } from 'vuex'
import Avatar from '~/components/PassporterUI/Avatar.vue'
import Row from '~/components/UI/Row.vue'
import ResponsiveData from '~/models/Responsive'

export default Vue.extend({
	name: 'Chip',
	components: { Row, Avatar },
	props: {
		color: {
			type: String,
			default: 'neutral-25',
		},
		activeColor: {
			type: String,
			default: undefined,
		},
		hoverColor: {
			type: String,
			default: undefined,
		},
		borderHoverColor: {
			type: String,
			default: undefined,
		},
		textColor: {
			type: String,
			default: 'primary-500',
		},
		selectedBold: {
			type: Boolean,
			default: false,
		},
		selectedColor: {
			type: String,
			default: 'primary-500',
		},
		selectedTextColor: {
			type: String,
			default: undefined,
		},
		avatar: {
			type: String,
			default: null,
		},
		avatarEnd: {
			type: String,
			default: null,
		},
		selected: {
			type: Boolean,
			default: false,
		},
		translucent: {
			type: Boolean,
			default: false,
		},
		outlined: {
			type: Boolean,
			default: false,
		},
		selectedOutlined: {
			type: Boolean,
			default: false,
		},
		unselectedOutlined: {
			type: Boolean,
			default: false,
		},
		outlinedColor: {
			type: String,
			default: 'neutral-200',
		},
		selectedOutlinedColor: {
			type: String,
			default: 'neutral-200',
		},
		xLarge: {
			type: Boolean,
			default: false,
		},
		large: {
			type: Boolean,
			default: false,
		},
		small: {
			type: Boolean,
			default: false,
		},
		xSmall: {
			type: Boolean,
			default: false,
		},
		isIcon: {
			type: Boolean,
			default: false,
		},
		icon: {
			type: [String, Object],
			default: undefined,
		},
		cols: {
			type: [String, Number],
			default: 0,
		},
		shadow: {
			type: Boolean,
			default: false,
		},
		isHoverBackground: {
			type: Boolean,
			default: false,
		},
		isHoverBorder: {
			type: Boolean,
			default: false,
		},
		isActiveBackground: {
			type: Boolean,
			default: false,
		},
		maxContent: {
			type: Boolean,
			default: false,
		},
		screenSize: {
			type: Object,
			default: undefined,
		} as PropOptions<ResponsiveData>,
		defaultAvatar: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters({
			theme: 'theme',
		}),
		hoverColorValue(): string | undefined {
			return 'var(--color-' + this.hoverColor + ')'
		},
		activeColorValue(): string | undefined {
			return 'var(--color-' + this.activeColor + ')'
		},
		borderHoverColorValue(): string | undefined {
			return 'var(--color-' + this.borderHoverColor + ')'
		},
		asyncIcon() {
			if (this.icon.includes('.svg')) {
				return () => import('~/components/UI/Icons/' + this.icon.split('.')[0] + '.vue')
			} else {
				return () => import('~/components/PassporterUI/Icon/PIcon' + this.icon + '.vue')
			}
		},
	},
	methods: {
		clicked(event: Event) {
			this.$emit('click', event)
		},
		mouseEntered(event: Event) {
			this.$emit('mouseenter', event)
		},
		mouseLeaved(event: Event) {
			this.$emit('mouseleave', event)
		},
		hexColor(color: string): string {
			if (color[0] === '#') {
				return color
			} else {
				const toneColor = color.split('-')
				if (toneColor.length === 2) {
					return this.theme?.current.colors[toneColor[0]]?.[toneColor[1]]
				} else {
					return color
				}
			}
		},
	},
})
