interface ErrorViewData {
	statusCode: number
	message: string
}

export default class ErrorView implements ErrorViewData {
	private readonly _statusCode
	private readonly _message

	constructor({ statusCode, message }: ErrorViewData) {
		this._statusCode = statusCode
		this._message = message
	}

	get statusCode(): number {
		return this._statusCode
	}

	get message(): string {
		return this._message
	}
}
