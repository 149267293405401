
































import Vue, { PropOptions } from 'vue'
import { mapGetters } from 'vuex'
import { Loader } from '@googlemaps/js-api-loader'
import Spinner from '~/components/PassporterUI/Spinner.vue'
import IconLocation from '~/components/UI/Icons/IconLocation.vue'
import PButton from '~/components/PassporterUI/PButton.vue'
import { Marker2 as Marker } from '~/models/Marker'
import { Spot2 as Spot } from '~/models/Spot'
import { Destination2 as Destination } from '~/models/Destination'
import GPlace from '~/models/GPlace'
import ItineraryMarkers from '~/components/context/items/itineraries/ItineraryMarkers.vue'
import SpotMapPopUp from '~/components/context/items/spots/SpotMapPopup.vue'

const defaultCenter = [0, 0]

export default Vue.extend({
	name: 'Map',
	components: {
		SpotMapPopUp,
		PButton,
		IconLocation,
		Spinner,
	},
	props: {
		mapId: {
			type: String,
			default: 'map',
		},
		center: {
			type: Array,
			default: () => defaultCenter,
		} as PropOptions<number[]>,
		markers: {
			type: Array,
			default: () => [],
		} as PropOptions<Marker[]>,
		initZoom: {
			type: Number,
			default: 13,
		},
		zoomPosition: {
			type: String,
			default: 'bottomright',
		},
		noHover: {
			type: Boolean,
			default: false,
		},
		noClick: {
			type: Boolean,
			default: false,
		},
		loadData: {
			type: Function,
			default: undefined,
		},
		showControls: {
			type: Boolean,
			default: true,
		},
		isRounded: {
			type: Boolean,
			default: false,
		},
		freezeMap: {
			type: Boolean,
			default: true,
		},
		padding: {
			type: Object,
			default: () => ({}),
		},
		openMarkerId: {
			type: String,
			default: undefined,
		},
		topButtonsPosition: {
			type: String,
			default: '12px',
		},
	},
	data() {
		return {
			spot: null as Spot | null,
			hasLocation: false,
			locationChanged: false,
			errorLocation: false,
			currentLocation: null as null | { lat: number; lng: number },
			map: null as any,
			maxFitZoom: 15,
			mapMarkers: [] as {
				mapMarker: Marker
				mapGoogleMarker: google.maps.Marker
			}[],
			markerSelected: null as null | Marker,
			markerInfoWindow: undefined as undefined | google.maps.InfoWindow,
			googleInfoWindow: undefined as undefined | google.maps.InfoWindow,
			zoom: 3,
			initialized: false,
		}
	},
	computed: {
		...mapGetters({
			isAuthenticated: 'auth/isAuthenticated',
			screenSize: 'screenSize',
		}),
		mapTile(): string | undefined {
			return process.env.MAP_TILE
		},
	},
	watch: {
		markers(markers) {
			if (this.map) {
				this.setMarkers(markers)
			} else {
				this.loadMap()
			}
		},
		zoom(zoom) {
			this.map.setZoom(zoom)
		},
		hasLocation() {
			this.locationChanged = true
		},
		openMarkerId(markerId) {
			if (markerId) {
				const mapMarkerSelected =
					this.mapMarkers?.find((mapMarker) => mapMarker.mapMarker.id === markerId) || null
				if (mapMarkerSelected) {
					this.displayCard(mapMarkerSelected.mapMarker, mapMarkerSelected.mapGoogleMarker)
				}
			} else {
				this.closeMarkerInfo()
			}
		},
	},
	mounted() {
		this.$nuxt.$on('close-window', () => {
			this.googleInfoWindow?.close()
			this.closeMarkerInfo()
		})
		this.$nuxt.$nextTick(() => {
			this.initializeMap()
		})
	},
	beforeDestroy() {
		this.$nuxt.$off('close-window')
	},
	methods: {
		initializeMap() {
			if (!this.initialized) {
				this.loadMap()
			}
		},
		async loadMap() {
			this.initialized = true
			const loader = new Loader({
				apiKey: process.env.GOOGLE_MAPS_API_ID!,
				version: 'weekly',
			})

			await loader.load()
			const { Map, InfoWindow } = (await google.maps.importLibrary(
				'maps'
			)) as google.maps.MapsLibrary
			this.markerInfoWindow = new InfoWindow({
				content: this.$refs.markerInfoWindow as any,
			})
			this.googleInfoWindow = new InfoWindow({
				content: this.$refs.infoWindow as any,
			})
			this.map = new Map(document.getElementById(this.mapId) as HTMLElement, {
				mapId: '7d0d4043184d1421',
				zoomControl: false,
				mapTypeControl: false,
				fullscreenControl: false,
				center: {
					lat: this.center[0],
					lng: this.center[1],
				},
				zoomControlOptions: {
					position: google.maps.ControlPosition.RIGHT_TOP,
				},
				streetViewControlOptions: {
					position: google.maps.ControlPosition.RIGHT_TOP,
				},
				streetViewControl: false,
				zoom: this.zoom,
				gestureHandling: this.screenSize.xs ? 'cooperative' : 'auto',
			})
			this.map.addListener('click', this.actionsWhenClick())
			this.setMarkers(this.markers)
			if (this.freezeMap) {
				this.$store.commit('maps/setCurrentMapInstance', Object.freeze(this.map))
			}
		},
		setMarkers(markers: Marker[]) {
			const mapHasMarkers = this.mapMarkers.length > 0
			let markersToDraw = markers ?? []
			const markersToDelete = this.obtainMarkersToDelete(markers)
			this.removeMarkers(markersToDelete)

			if (mapHasMarkers) {
				markersToDraw = this.filterMarkersToDraw(markers)
			}
			this.drawMarkers(markersToDraw)
			this.adjustMapZoom()
			this.updateLocationChangedFlag()
		},
		async placeInformation(place: any) {
			const res: Spot | Destination | GPlace = await this.$store.dispatch(
				'maps/googleMapPlace',
				place
			)
			this.spot = res as Spot
			if (!this.spot) {
				this.googleInfoWindow?.close()
				await this.$store.dispatch('alerts/setError', this.$t('common_internal_error'))
			} else {
				const location = {
					lng: Number(res?.location?.longitude),
					lat: Number(res?.location?.latitude),
				}
				this.googleInfoWindow?.setPosition(location)
			}
		},

		adjustMapZoom() {
			const zoomChangeBoundsListener = google.maps.event.addListener(
				this.map,
				'bounds_changed',
				() => {
					google.maps.event.removeListener(zoomChangeBoundsListener)
					this.map.setZoom(Math.min(this.maxFitZoom, this.map.getZoom()))
				}
			)

			const bounds = new google.maps.LatLngBounds()
			if (this.hasLocation && this.currentLocation) {
				bounds.extend(this.currentLocation)
				this.fitBounds(bounds)
			} else if (this.mapMarkers.length) {
				this.mapMarkers.forEach(({ mapMarker }) => {
					const position = new google.maps.LatLng(
						mapMarker.location.latitude,
						mapMarker.location.longitude
					)
					bounds.extend(position)
				})
				this.fitBounds(bounds)
			}

			if (this.center !== defaultCenter && !this.mapMarkers.length) {
				this.zoom = this.initZoom
			}
		},
		updateLocationChangedFlag(): void {
			if (this.locationChanged) {
				this.locationChanged = false
			}
		},
		obtainMarkersToDelete(markers: Marker[]): {
			mapMarker: Marker
			mapGoogleMarker: google.maps.Marker
		}[] {
			return this.mapMarkers.filter(
				({ mapMarker }) => !markers.some((marker) => marker.id === mapMarker.id)
			)
		},
		removeMarkers(markersToDelete: any) {
			markersToDelete.map((marker: { mapMarker: Marker; mapGoogleMarker: google.maps.Marker }) =>
				marker.mapGoogleMarker.setMap(null)
			)
			this.mapMarkers = this.mapMarkers.filter(
				({ mapMarker }) =>
					!markersToDelete.some((marker: any) => marker.mapMarker.id === mapMarker.id)
			)
		},
		drawMarkers(markersToDraw: Marker[]) {
			markersToDraw.forEach((marker) => {
				if (marker.location) {
					this.removeDuplicateMarker(marker)
					this.drawSingleMarker(marker)
				}
			})
		},
		removeDuplicateMarker(marker: Marker) {
			const markerIndex = this.mapMarkers.findIndex(({ mapMarker }) => mapMarker.id === marker.id)
			if (markerIndex >= 0) {
				const currentMarker = this.mapMarkers[markerIndex]
				currentMarker.mapGoogleMarker.setMap(null)
				this.mapMarkers = this.mapMarkers.filter(
					(m) => m.mapMarker.id !== currentMarker.mapMarker.id
				)
			}
		},
		drawSingleMarker(marker: Marker) {
			const position = new google.maps.LatLng(marker.location.latitude, marker.location.longitude)

			const mapMarker = new google.maps.Marker({
				position,
				map: this.map,
				icon: this.markerIcons(marker),
				zIndex: marker.isChecked ? 1 : 0,
			})
			mapMarker.set('id', marker.id)
			this.addMarkerEventListeners(marker, mapMarker)

			this.mapMarkers.push({
				mapMarker: marker,
				mapGoogleMarker: mapMarker,
			})
		},
		addMarkerEventListeners(marker: Marker, mapMarker: google.maps.Marker) {
			if (!this.noHover) {
				mapMarker.addListener('mouseover', () => {
					this.addTrackerSpot()
					this.displayCard(marker, mapMarker)
				})
			}
			mapMarker.addListener('click', () => {
				this.$emit('markerClicked', marker)
				this.addTrackerSpot()
				this.displayCard(marker, mapMarker)
			})
		},
		filterMarkersToDraw(markers: Marker[]) {
			const markersInMapIds = this.mapMarkers.map(({ mapMarker }) => mapMarker.id)
			return markers.filter((marker) => {
				if (markersInMapIds.includes(marker.id)) {
					const existingMarker = this.mapMarkers.find(({ mapMarker }) => mapMarker.id === marker.id)
					if (existingMarker) {
						return this.isMarkerPropertiesChanged(existingMarker.mapMarker, marker)
					}
				}
				return true
			})
		},
		isMarkerPropertiesChanged(existingMarker: Marker, newMarker: Marker) {
			const { icon, isChecked, isReference, location } = existingMarker

			return (
				icon !== newMarker.icon ||
				isChecked !== newMarker.isChecked ||
				isReference !== newMarker.isReference ||
				location?.latitude !== newMarker.location?.latitude ||
				location?.longitude !== newMarker.location?.longitude ||
				this.locationChanged
			)
		},
		addTrackerSpot() {
			this.$mixpanel?.track('Spot Map Click')
		},
		async displayCard(marker: Marker, mapMarker: google.maps.Marker) {
			try {
				if (!marker.data && !this.loadData) return
				if (!marker.data && this.loadData) {
					await (this.loadData as Function)(marker)
				}
				this.markerSelected = marker
				if (!this.noClick) {
					this.googleInfoWindow?.close()
					this.openMarkerInfo(mapMarker)
				}
			} catch (e) {}
		},
		closeMarkerInfo() {
			this.markerInfoWindow?.close()
		},
		openMarkerInfo(mapMarker: google.maps.Marker) {
			this.markerInfoWindow?.open({
				anchor: mapMarker,
				map: this.map,
				shouldFocus: false,
			})
		},
		actionsWhenClick() {
			return (e: any) => {
				this.googleInfoWindow?.close()
				this.closeMarkerInfo()
				if (e.placeId) {
					this.addTrackerSpot()
					this.spot = null
					e.stop()
					this.googleInfoWindow?.setPosition(e.latLng)
					this.googleInfoWindow?.setOptions({
						pixelOffset: new google.maps.Size(0, -30),
					})
					this.googleInfoWindow?.open(this.map)
					this.placeInformation(e)
				}
			}
		},
		iconUrl({
			width,
			height,
			bgColor,
			check,
			reference,
		}: {
			width?: number
			height?: number
			bgColor?: string
			check?: boolean
			reference?: boolean
		}) {
			const LocationIconConstructor = Vue.extend(ItineraryMarkers)
			const iconComponent = new LocationIconConstructor({
				propsData: {
					width,
					height,
					bgColor,
					check,
					reference,
				},
			})
			iconComponent.$mount()
			const iconString = new XMLSerializer().serializeToString(iconComponent.$el)
			iconComponent.$destroy()
			return 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(iconString)
		},
		getLocation() {
			this.$mixpanel?.track('Get current location map clicked')
			this.hasLocation = !this.hasLocation
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					(position: GeolocationPosition) => {
						this.currentLocation = {
							lat: position.coords.latitude,
							lng: position.coords.longitude,
						}
						if (this.hasLocation) {
							const bounds = new google.maps.LatLngBounds()
							bounds.extend(this.currentLocation)
							this.fitBounds(bounds)
							const iconImg = {
								url: require('~/assets/markers/marker-location.svg'),
								anchor: new google.maps.Point(20, 20),
							}
							const marker = new google.maps.Marker({
								title: this.$t('Location_active_') as string,
								icon: iconImg,
								position: this.currentLocation,
								zIndex: 2,
							})
							marker.setMap(this.map)
						} else {
							this.loadMap()
						}
					},
					this.locationErrors,
					{
						timeout: 10000,
					}
				)
			}
		},
		locationErrors() {
			this.errorLocation = true
		},
		markerIcons(marker: Marker) {
			if (marker.icon && this.isUrl(marker.icon)) {
				return marker.icon
			}
			return this.colors(marker.color, {
				check: marker.isChecked,
				reference: marker.isReference,
			})
		},
		isUrl(url: string) {
			try {
				return Boolean(new URL(url))
			} catch (e: any) {
				return false
			}
		},
		colors(bgColor?: string, data?: any) {
			return this.iconUrl({
				bgColor,
				width: data.width,
				height: data.height,
				check: data.check,
				reference: data.reference,
			})
		},
		fitBounds(bounds: google.maps.LatLngBounds) {
			const southWestLat = bounds.getSouthWest().lat()
			const southWestLng = bounds.getSouthWest().lng()
			const northEastLat = bounds.getNorthEast().lat()
			const northEastLng = bounds.getNorthEast().lng()
			const isOnlyOneBound = southWestLat === northEastLat && southWestLng === northEastLng
			if (isOnlyOneBound) {
				bounds.extend({
					lat: southWestLat + 1,
					lng: southWestLng + 1,
				})
				bounds.extend({
					lat: southWestLat - 1,
					lng: southWestLng - 1,
				})
			}
			this.map.fitBounds(bounds, {
				left: this.padding.left,
				right: this.padding.right,
				top: this.padding.top,
				bottom: this.padding.bottom,
			})
		},
	},
})
