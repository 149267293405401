import { Plugin } from '@nuxt/types'
import Tracker from '~/services/Tracker'

declare module 'vue/types/vue' {
	interface Vue {
		$tracker: Tracker
	}
}
declare module '@nuxt/types' {
	interface NuxtAppOptions {
		$tracker: Tracker
	}
	interface Context {
		$tracker: Tracker
	}
}

const customPlugin: Plugin = (context, inject) => {
	inject('tracker', new Tracker(context.$mixpanel))
}

export default customPlugin
