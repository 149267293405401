





















































import Vue from 'vue'
import { mapGetters } from 'vuex'

type pButtonType =
	| 'neutral'
	| 'filled'
	| 'outlined'
	| 'ghost'
	| 'neutral-outlined'
	| 'ghost-outlined'
	| 'light'

type pButtonColor = 'primary' | 'secondary' | 'background' | 'content' | 'danger' | 'social'

type pButtonSize = 'sm' | 'md' | 'lg'

export default Vue.extend({
	name: 'PButton',
	props: {
		md: { type: Boolean, default: false },
		sizeMd: { type: Boolean, default: false },
		lg: { type: Boolean, default: false },
		sizeLg: { type: Boolean, default: false },
		sizeSm: { type: Boolean, default: false },
		primary: { type: Boolean, default: false }, // deprecated
		colorPrimary: { type: Boolean, default: false },
		secondary: { type: Boolean, default: false }, // deprecated
		colorSecondary: { type: Boolean, default: false },
		colorContent: { type: Boolean, default: false },
		colorBackground: { type: Boolean, default: false },
		error: { type: Boolean, default: false }, // deprecated
		colorDanger: { type: Boolean, default: false },
		colorSocial: { type: Boolean, default: false },
		neutral: { type: Boolean, default: false }, // deprecated
		typeNeutral: { type: Boolean, default: false },
		filled: { type: Boolean, default: false }, // deprecated
		typeFilled: { type: Boolean, default: false },
		outlined: { type: Boolean, default: false }, // deprecated
		typeOutlined: { type: Boolean, default: false },
		ghost: { type: Boolean, default: false }, // deprecated
		typeGhost: { type: Boolean, default: false },
		typeNeutralOutlined: { type: Boolean, default: false },
		typeGhostOutlined: { type: Boolean, default: false },
		typeLight: { type: Boolean, default: false },
		block: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		type: { type: String, default: undefined }, // deprecated
		focusable: { type: Boolean, default: false },
		isRounded: { type: Boolean, default: false },
	},
	computed: {
		...mapGetters({
			theme: 'theme',
		}),
		slots(): { [p: string]: any } {
			return { ...this.$slots, ...this.$scopedSlots }
		},
		buttonSize(): pButtonSize {
			return (
				(this.sizeSm && 'sm') ||
				(this.md && 'md') ||
				(this.sizeMd && 'md') ||
				(this.lg && 'lg') ||
				(this.sizeLg && 'lg') ||
				'md'
			)
		},
		buttonType(): pButtonType {
			return (
				(this.neutral && 'neutral') ||
				(this.typeNeutral && 'neutral') ||
				(this.filled && 'filled') ||
				(this.typeFilled && 'filled') ||
				(this.outlined && 'outlined') ||
				(this.typeOutlined && 'outlined') ||
				(this.ghost && 'ghost') ||
				(this.typeGhost && 'ghost') ||
				(this.typeNeutralOutlined && 'neutral-outlined') ||
				(this.typeGhostOutlined && 'ghost-outlined') ||
				(this.typeLight && 'light') ||
				'neutral'
			)
		},
		buttonColor(): pButtonColor {
			return (
				(this.colorBackground && 'background') ||
				(this.colorContent && 'content') ||
				(this.primary && 'primary') ||
				(this.colorPrimary && 'primary') ||
				(this.secondary && 'secondary') ||
				(this.colorSecondary && 'secondary') ||
				(this.error && 'danger') ||
				(this.colorDanger && 'danger') ||
				(this.colorSocial && 'social') ||
				'primary'
			)
		},
		height(): string {
			return (
				(this.buttonSize === 'sm' && '32px') ||
				(this.buttonSize === 'md' && '40px') ||
				(this.buttonSize === 'lg' && '48px') ||
				'40px'
			)
		},
		bgColor(): string | false {
			return (
				(this.buttonType === 'neutral' && this.buttonColor === 'danger' && 'bg-error-25') ||
				(this.buttonType === 'neutral' &&
					this.slots.icon &&
					!this.slots.default &&
					'bg-neutral-25') ||
				(this.buttonType === 'neutral' && 'bg-neutral-50') ||
				(this.buttonType === 'filled' && this.buttonColor === 'background' && `bg-neutral-25`) ||
				(this.buttonType === 'filled' && this.buttonColor === 'content' && `bg-neutral-800`) ||
				(this.buttonType === 'filled' && this.buttonColor === 'danger' && `bg-error-500`) ||
				(this.buttonType === 'filled' && `bg-${this.buttonColor}-500`) ||
				(this.buttonType === 'outlined' && 'bg-neutral-25') ||
				(this.buttonType === 'neutral-outlined' && `bg-neutral-25`) ||
				(this.buttonType === 'ghost-outlined' && `bg-transparent`) ||
				(this.buttonType === 'light' && this.buttonColor === 'primary' && `bg-primary-200`) ||
				(this.buttonType === 'ghost' && 'bg-transparent')
			)
		},
		borderColor(): string | false {
			return (
				(this.buttonType === 'outlined' &&
					this.buttonColor === 'background' &&
					`border-neutral-500`) ||
				(this.buttonType === 'ghost-outlined' &&
					this.buttonColor === 'background' &&
					`border-neutral-25`) ||
				(this.buttonType === 'outlined' &&
					this.buttonColor === 'content' &&
					`border-neutral-200`) ||
				(this.buttonType === 'ghost-outlined' &&
					this.buttonColor === 'content' &&
					`border-neutral-900`) ||
				(['outlined', 'ghost-outlined'].includes(this.buttonType) &&
					this.buttonColor === 'danger' &&
					`border-error-500`) ||
				(['outlined', 'ghost-outlined'].includes(this.buttonType) &&
					`border-${this.buttonColor}-500`) ||
				(this.buttonType === 'neutral-outlined' && 'border-neutral-200') ||
				(this.buttonType === 'neutral' &&
					this.slots.icon &&
					!this.slots.default &&
					'border-neutral-200') ||
				false
			)
		},
		hoverBorderColor(): string | false {
			const isOutlinedContent = this.buttonType === 'outlined' && this.buttonColor === 'content'
			const isGhostOutlinedBackground =
				this.buttonType === 'ghost-outlined' && this.buttonColor === 'background'

			if (isOutlinedContent) {
				return 'border-neutral-900'
			}

			if (isGhostOutlinedBackground) {
				return 'border-neutral-100'
			}

			return this.borderColor || false
		},
		focusBorderColor(): string | false {
			return this.borderColor
		},
		textColor(): string | false {
			return (
				(this.buttonType === 'neutral' &&
					this.slots.icon &&
					!this.slots.default &&
					this.theme.current.colors.neutral['900']) ||
				(this.buttonType === 'neutral' &&
					this.buttonColor === 'content' &&
					this.theme.current.colors.neutral['900']) ||
				(this.buttonType === 'neutral' &&
					this.buttonColor === 'background' &&
					this.theme.current.colors.neutral['25']) ||
				(this.buttonType === 'neutral' &&
					this.buttonColor === 'danger' &&
					this.theme.current.colors.error['500']) ||
				(this.buttonType === 'neutral' && this.theme.current.colors[this.buttonColor]['500']) ||
				(this.buttonType === 'filled' &&
					this.buttonColor === 'background' &&
					this.theme.current.colors.neutral['900']) ||
				(this.buttonType === 'filled' && this.theme.current.colors.neutral['25']) ||
				(this.buttonType === 'outlined' &&
					this.buttonColor === 'content' &&
					this.theme.current.colors.neutral['900']) ||
				(this.buttonType === 'outlined' &&
					this.buttonColor === 'background' &&
					this.theme.current.colors.neutral['25']) ||
				(this.buttonType === 'outlined' &&
					this.buttonColor === 'danger' &&
					this.theme.current.colors.error['500']) ||
				(this.buttonType === 'outlined' && this.theme.current.colors[this.buttonColor]['500']) ||
				(this.buttonType === 'ghost' &&
					this.buttonColor === 'content' &&
					this.theme.current.colors.neutral['900']) ||
				(this.buttonType === 'ghost' &&
					this.buttonColor === 'background' &&
					this.theme.current.colors.neutral['25']) ||
				(this.buttonType === 'ghost' &&
					this.buttonColor === 'danger' &&
					this.theme.current.colors.error['500']) ||
				(this.buttonType === 'ghost' && this.theme.current.colors[this.buttonColor]['500']) ||
				(this.buttonType === 'ghost-outlined' &&
					this.buttonColor === 'content' &&
					this.theme.current.colors.neutral['900']) ||
				(this.buttonType === 'ghost-outlined' &&
					this.buttonColor === 'background' &&
					this.theme.current.colors.neutral['25']) ||
				(this.buttonType === 'ghost-outlined' &&
					this.buttonColor === 'danger' &&
					this.theme.current.colors.error['500']) ||
				(this.buttonType === 'ghost-outlined' &&
					this.theme.current.colors[this.buttonColor]['500']) ||
				(this.buttonType === 'light' &&
					this.buttonColor === 'primary' &&
					this.theme.current.colors.neutral['25']) ||
				(this.buttonType === 'neutral-outlined' && this.theme.current.colors.neutral['900'])
			)
		},

		hoverBgColor(): string | false {
			return (
				(this.buttonType === 'neutral' &&
					this.buttonColor === 'danger' &&
					this.theme.current.colors.secondary['50']) ||
				(this.buttonType === 'neutral' &&
					this.slots.icon &&
					!this.slots.default &&
					this.theme.current.colors.neutral['100']) ||
				(this.buttonType === 'neutral' &&
					this.buttonColor === 'content' &&
					this.theme.current.colors.neutral['200']) ||
				(this.buttonType === 'neutral' &&
					this.buttonColor === 'background' &&
					this.theme.current.colors.neutral['200']) ||
				(this.buttonType === 'neutral' &&
					this.buttonColor === 'danger' &&
					this.theme.current.colors.error['25']) ||
				(this.buttonType === 'neutral' && this.theme.current.colors?.[this.buttonColor]['25']) ||
				(this.buttonType === 'neutral-outlined' && this.theme.current.colors?.neutral['200']) ||
				(this.buttonType === 'filled' &&
					this.buttonColor === 'content' &&
					this.theme.current.colors?.neutral['700']) ||
				(this.buttonType === 'filled' &&
					this.buttonColor === 'background' &&
					this.theme.current.colors?.neutral['200']) ||
				(this.buttonType === 'filled' &&
					this.buttonColor === 'danger' &&
					this.theme.current.colors.error['600']) ||
				(this.buttonType === 'filled' && this.theme.current.colors?.[this.buttonColor]['600']) ||
				(this.buttonType === 'outlined' &&
					this.buttonColor === 'content' &&
					this.theme.current.colors?.neutral['25']) ||
				(this.buttonType === 'outlined' &&
					this.buttonColor === 'background' &&
					this.theme.current.colors?.neutral['200']) ||
				(this.buttonType === 'outlined' &&
					this.buttonColor === 'danger' &&
					this.theme.current.colors.error['25']) ||
				(this.buttonType === 'outlined' && this.theme.current.colors?.[this.buttonColor]['25']) ||
				(this.buttonType === 'ghost' &&
					this.buttonColor === 'danger' &&
					this.theme.current.colors.secondary['50']) ||
				(this.buttonType === 'ghost' &&
					this.buttonColor === 'background' &&
					this.theme.current.colors.neutral['600']) ||
				(this.buttonType === 'light' &&
					this.buttonColor === 'primary' &&
					this.theme.current.colors?.[this.buttonColor]['300']) ||
				(this.buttonType === 'ghost' && this.theme.current.colors.neutral['100'])
			)
		},
		focusBgColor(): string | false {
			return (
				(this.buttonType === 'neutral' &&
					this.buttonColor === 'danger' &&
					this.theme.current.colors.error['200']) ||
				(this.buttonType === 'neutral' &&
					this.slots.icon &&
					!this.slots.default &&
					this.theme.current.colors.neutral['200']) ||
				(this.buttonType === 'neutral' &&
					this.buttonColor === 'content' &&
					this.theme.current.colors?.neutral['300']) ||
				(this.buttonType === 'neutral' &&
					this.buttonColor === 'background' &&
					this.theme.current.colors?.neutral['25']) ||
				(this.buttonType === 'neutral' && this.theme.current.colors?.[this.buttonColor]['50']) ||
				(this.buttonType === 'neutral-outlined' && this.theme.current.colors?.neutral['100']) ||
				(this.buttonType === 'filled' &&
					this.buttonColor === 'content' &&
					this.theme.current.colors?.neutral['500']) ||
				(this.buttonType === 'filled' &&
					this.buttonColor === 'background' &&
					this.theme.current.colors?.neutral['300']) ||
				(this.buttonType === 'filled' &&
					this.buttonColor === 'danger' &&
					this.theme.current.colors.error['700']) ||
				(this.buttonType === 'filled' && this.theme.current.colors?.[this.buttonColor]['700']) ||
				(this.buttonType === 'outlined' &&
					this.buttonColor === 'content' &&
					this.theme.current.colors?.neutral['25']) ||
				(this.buttonType === 'outlined' &&
					this.buttonColor === 'background' &&
					this.theme.current.colors?.neutral['25']) ||
				(this.buttonType === 'outlined' &&
					this.buttonColor === 'danger' &&
					this.theme.current.colors.error['50']) ||
				(this.buttonType === 'outlined' && this.theme.current.colors?.[this.buttonColor]['50']) ||
				(this.buttonType === 'ghost' &&
					this.buttonColor === 'danger' &&
					this.theme.current.colors.error['100']) ||
				(this.buttonType === 'light' &&
					this.buttonColor === 'primary' &&
					this.theme.current.colors?.[this.buttonColor]['400']) ||
				(this.buttonType === 'ghost' && this.theme.current.colors.neutral['200'])
			)
		},
		disabledBgColor(): string | false {
			return (
				(this.buttonType === 'neutral' &&
					this.buttonColor === 'danger' &&
					this.theme.current.colors.secondary['50']) ||
				(this.buttonType === 'neutral' &&
					this.slots.icon &&
					!this.slots.default &&
					this.theme.current.colors.neutral['25']) ||
				(this.buttonType === 'neutral' && this.theme.current.colors.neutral['100']) ||
				(this.buttonType === 'filled' &&
					this.buttonColor === 'content' &&
					this.theme.current.colors?.neutral['900']) ||
				(this.buttonType === 'filled' &&
					this.buttonColor === 'background' &&
					this.theme.current.colors?.neutral['25']) ||
				(this.buttonType === 'filled' &&
					this.buttonColor === 'danger' &&
					this.theme.current.colors.error['50']) ||
				(this.buttonType === 'filled' && this.theme.current.colors?.[this.buttonColor]['50']) ||
				(this.buttonType === 'outlined' && this.theme.current.colors.neutral['25']) ||
				(this.buttonType === 'light' &&
					this.buttonColor === 'primary' &&
					this.theme.current.colors?.[this.buttonColor]['25']) ||
				(this.buttonType === 'ghost' && 'transparent')
			)
		},
		disabledTextColor(): string | false {
			return (
				(this.buttonType === 'neutral' &&
					this.buttonColor === 'danger' &&
					this.theme.current.colors.error['25']) ||
				(this.buttonType === 'neutral' &&
					this.buttonColor === 'content' &&
					this.theme.current.colors?.neutral['200']) ||
				(this.buttonType === 'neutral' &&
					this.buttonColor === 'background' &&
					this.theme.current.colors?.neutral['50']) ||
				(this.buttonType === 'neutral' &&
					this.slots.icon &&
					!this.slots.default &&
					this.theme.current.colors.neutral['200']) ||
				(this.buttonType === 'neutral' && this.theme.current.colors?.[this.buttonColor]['50']) ||
				(this.buttonType === 'filled' && this.theme.current.colors.neutral['25']) ||
				(this.buttonType === 'outlined' &&
					this.buttonColor === 'content' &&
					this.theme.current.colors?.neutral['200']) ||
				(this.buttonType === 'outlined' &&
					this.buttonColor === 'background' &&
					this.theme.current.colors?.neutral['50']) ||
				(this.buttonType === 'outlined' &&
					this.buttonColor === 'danger' &&
					this.theme.current.colors.error['50']) ||
				(this.buttonType === 'outlined' && this.theme.current.colors?.[this.buttonColor]['50']) ||
				(this.buttonType === 'ghost' &&
					this.buttonColor === 'content' &&
					this.theme.current.colors?.neutral['200']) ||
				(this.buttonType === 'ghost' &&
					this.buttonColor === 'background' &&
					this.theme.current.colors?.neutral['50']) ||
				(this.buttonType === 'ghost' &&
					this.buttonColor === 'danger' &&
					this.theme.current.colors.error['50']) ||
				(this.buttonType === 'light' &&
					this.buttonColor === 'primary' &&
					this.theme.current.colors?.neutral['200']) ||
				(this.buttonType === 'ghost' && this.theme.current.colors?.[this.buttonColor]['50'])
			)
		},
	},
	methods: {
		click(e: Event): void {
			this.$emit('click', e)
		},
	},
})
