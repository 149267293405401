








































































































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import Row from '~/components/UI/Row.vue'
import Col from '~/components/UI/Col.vue'
import Container from '~/components/PassporterUI/Container.vue'
import Scrollbar from '~/components/PassporterUI/Scrollbar.vue'
import PIconChevron from '~/components/PassporterUI/Icon/PIconChevron.vue'

export interface ScrollEvent {
	item: number
	position: number
}

export default Vue.extend({
	name: 'HorizontalScroll',
	components: { Container, Col, Row, Scrollbar, PIconChevron },
	props: {
		paddingY: {
			type: Number,
			default: 0,
		},
		paddingX: {
			type: Number,
			default: 0,
		},
		gap: {
			type: Number,
			default: 3,
		},
		spacing: {
			type: Number,
			default: undefined,
		},
		outsideButtons: {
			type: Boolean,
			default: false,
		},
		grow: {
			type: Boolean,
			default: false,
		},
		small: {
			type: Boolean,
			default: false,
		},
		alignEnd: {
			type: Boolean,
			default: false,
		},
		justify: {
			type: String,
			default: undefined,
		},
	},
	data() {
		return {
			hovering: false,
			availableLeftArrow: false,
			availableRightArrow: false,
			leftPrev: 0,
			mutationObserver: null as MutationObserver | null,
		}
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
		}),

		gapPixels(): number {
			return this.gap * 4
		},
		showLeftArrow(): boolean {
			return this.availableLeftArrow && this.hovering
		},
		showRightArrow(): boolean {
			return this.availableRightArrow && this.hovering
		},
	},
	mounted() {
		this.$nextTick(() => {
			if (!this.screenSize.xs) {
				setTimeout(() => {
					this.checkArrows()
				}, 500)
				this.observeMutations()
				window.addEventListener('resize', this.handleResize)
			}
		})
	},
	beforeDestroy() {
		this.disconnectObserver()
		window.removeEventListener('resize', this.handleResize)
	},
	methods: {
		observeMutations() {
			const targetNode = (this.$refs.horizontalScroll as any)?.$el
			if (targetNode) {
				this.mutationObserver = new MutationObserver(() => {
					this.checkArrows()
				})
				this.mutationObserver.observe(targetNode, {
					childList: true,
					subtree: true,
				})
			}
		},
		disconnectObserver() {
			if (this.mutationObserver) {
				this.mutationObserver.disconnect()
			}
		},
		handleResize() {
			this.checkArrows()
		},
		checkArrows() {
			this.availableLeftArrow = Boolean(this.leftPrev)
			const _scrollBox = (this.$refs.horizontalScroll as any)?.$el
			if (_scrollBox) {
				const isFinalScroll = _scrollBox.scrollWidth > _scrollBox.clientWidth
				this.availableRightArrow = isFinalScroll
			}
		},
		scrollBack() {
			const _scrollBox = (this.$refs.horizontalScroll as any)?.$el
			if (_scrollBox) {
				const _scrollPosition = _scrollBox.scrollLeft
				const _scrollLimit = _scrollPosition - _scrollBox.clientWidth
				const _scrollItems = _scrollBox.querySelectorAll(
					'.horizontal-scroll-list > *'
				) as NodeListOf<HTMLDivElement>
				let _nextItemStart = 0
				let _scrolledItems = 0
				_scrollItems.forEach((_item: HTMLDivElement, index) => {
					const _matrix = new WebKitCSSMatrix(_item.style.transform)
					const _translateX = _matrix.m41
					const _nextStart = _nextItemStart + _translateX
					if (
						_nextStart < _scrollLimit &&
						_scrollItems[index + 1] &&
						_nextStart + _item.offsetWidth + this.gapPixels + _scrollItems[index + 1].offsetWidth <=
							_scrollPosition + _scrollBox.clientWidth
					) {
						_nextItemStart += _item.offsetWidth + this.gapPixels
						_scrolledItems++
					}
				})
				this.scrollTo(_scrollBox, _nextItemStart, _scrolledItems)
			}
		},
		scrollForward() {
			const _scrollBox = (this.$refs.horizontalScroll as any)?.$el
			if (_scrollBox) {
				const _scrollPosition = _scrollBox?.scrollLeft
				const _scrollLimit = _scrollPosition + _scrollBox.clientWidth
				const _scrollItems = _scrollBox.querySelectorAll(
					'.horizontal-scroll-list > *'
				) as NodeListOf<HTMLDivElement>
				let _nextItemStart = 0
				let _scrolledItems = 0
				_scrollItems.forEach((_item: HTMLDivElement) => {
					const _matrix = new WebKitCSSMatrix(_item.style.transform)
					const _translateX = _matrix.m41
					const _nextStart = _nextItemStart + _translateX
					if (_nextStart + _item.offsetWidth < _scrollLimit || _nextStart <= _scrollPosition) {
						_nextItemStart += _item.offsetWidth + this.gapPixels
						_scrolledItems++
					}
				})
				if (_scrollBox.scrollWidth <= _scrollBox.clientWidth * 2) {
					_nextItemStart =
						this.leftPrev +
						(_scrollBox.scrollWidth - _scrollBox.clientWidth) +
						(this.spacing || 0) * 4
				}
				this.scrollTo(_scrollBox, _nextItemStart, _scrolledItems)
			}
		},
		scrollTo(box: HTMLDivElement, left: number, item: number) {
			if (box) {
				const _box = box
				const _left = left
				this.leftPrev = _left
				const _item = item
				const _scrollItems = _box.querySelectorAll(
					'.horizontal-scroll-list > *'
				) as NodeListOf<HTMLDivElement>
				_scrollItems.forEach((item) => {
					item.style.transform = `translateX(${_left * -1}px)`
				})
				this.$emit('scroll', { position: _left, item: _item } as ScrollEvent)
				setTimeout(() => {
					this.checkArrows()
				}, 500)
			}
		},
	},
})
