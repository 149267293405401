import { Plugin } from '@nuxt/types'
import apiModel from '~/apiModels/ApiModel'

declare module 'vue/types/vue' {
	interface Vue {
		$apiModel: ReturnType<typeof apiModel>
	}
}
declare module '@nuxt/types' {
	interface NuxtAppOptions {
		$apiModel: ReturnType<typeof apiModel>
	}
	interface Context {
		$apiModel: ReturnType<typeof apiModel>
	}
}
declare module 'vuex/types/index' {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	interface Store<S> {
		$apiModel: ReturnType<typeof apiModel>
	}
}

const myPlugin: Plugin = ({}, inject) => {
	inject('apiModel', apiModel())
}

export default myPlugin
