import Itinerary from '~/models/Itinerary'
import ItineraryGroup from '~/models/ItineraryGroup'
import ItinerarySpot from '~/models/ItinerarySpot'
import BookMark from '~/models/BookMark'
import ItineraryCategory from '~/models/ItineraryCategory'

interface ExternalModels {
	spot: any
}

interface ApiItineraryGroup {
	id: string
	itinerary_id: string
	text: string
	color: string
	light_color: string
	order: number
}
interface ApiItineraryCategory {
	id: string
	name: string
	logo: string
}
interface ApiItinerarySpot {
	spot: any
	is_reference: boolean
	groups: ApiItineraryGroup[]
	categories: ApiItineraryCategory[]
}

const parseItinerary = (itinerary: any) => {
	return new Itinerary({
		covers: itinerary.covers,
		id: itinerary.id,
		numDays: itinerary.num_days,
		numNotes: itinerary.num_notes,
		numDestinations: itinerary.num_destinations,
		numSpots: itinerary.num_spots,
		numTravelers: itinerary.num_travelers,
		isPrivate: itinerary.state === 'private',
		name: itinerary.title,
		startDate: itinerary.start_date,
		endDate: itinerary.end_date,
		travelersPictures: itinerary.travelers_pictures,
		agencyId: itinerary.agency_id,
		cover: itinerary.cover,
		creatorId: itinerary.creator_id,
		numViews: itinerary.num_views,
	})
}

const parseItineraries = (itineraries: any[]) => {
	return itineraries.map((itinerary: any) => parseItinerary(itinerary))
}

const parseOptions = (options: any) => {
	return {
		isVisible: options.actions.includes('GET'),
		isEditable: options.actions.includes('PATCH'),
		isRemovable: options.actions.includes('DELETE'),
		isTrip: options.trip && !options.discover,
		isChecklist: options.checklist && !options.bookings,
		isBookings: options.bookings,
		isDiscover: options.discover,
		role: options.role,
		access: options.access,
	}
}

const parseItineraryGroup = (itineraryGroup: ApiItineraryGroup) =>
	new ItineraryGroup({
		id: itineraryGroup.id,
		itineraryId: itineraryGroup.itinerary_id,
		name: itineraryGroup.text,
		color: itineraryGroup.color,
		lightColor: itineraryGroup.light_color,
	})

const parseItineraryGroups = (itineraryGroups: ApiItineraryGroup[]): ItineraryGroup[] => {
	return itineraryGroups.map((itineraryGroup) => parseItineraryGroup(itineraryGroup))
}

const parseItineraryCategory = (itineraryCategory: ApiItineraryCategory) =>
	new ItineraryCategory({
		id: itineraryCategory.id,
		name: itineraryCategory.name,
		logo: itineraryCategory.logo,
	})

const parseItineraryCategories = (
	itineraryCategories: ApiItineraryCategory[]
): ItineraryCategory[] => {
	return itineraryCategories.map((itineraryCategory) => parseItineraryCategory(itineraryCategory))
}

const parseItinerarySpot = (
	{ spot, is_reference: isReference, groups, categories }: ApiItinerarySpot,
	models: ExternalModels
) => {
	const parsedSpot = models.spot.parseSpot(spot)
	const parsedGroups = groups && parseItineraryGroups(groups)
	const parsedCategories = categories && parseItineraryCategories(categories)
	return new ItinerarySpot({
		...parsedSpot.toJSON(),
		isReference,
		groups: parsedGroups,
		categories: parsedCategories,
	})
}
const parseItinerarySpots = (itinerarySpots: any[], models: ExternalModels): ItinerarySpot[] => {
	return itinerarySpots.map((itinerarySpot: ApiItinerarySpot) =>
		parseItinerarySpot(itinerarySpot, models)
	)
}

const parseBlog = (blog: any) => {
	return new BookMark({
		link: blog.link_url,
		cover: blog.link_media,
		title: blog.header_text,
	})
}
const parseTrip = (trip: any): { blogs: BookMark[]; videos: BookMark[] } => {
	const _blogs = trip?.seo_block?.content || []
	const _videos = trip?.video_block?.content || []
	const _parsedBlogs = _blogs.map((_blog: any) => parseBlog(_blog))
	const _parsedVideos = _videos.map((_video: any) => parseBlog(_video))
	return { blogs: _parsedBlogs, videos: _parsedVideos }
}

export default (models: ExternalModels) => ({
	parseItinerary,
	parseItineraries,
	parseOptions,
	parseItineraryGroup,
	parseItineraryGroups,
	parseItinerarySpot: (itinerarySpot: any): ItinerarySpot => {
		return parseItinerarySpot(itinerarySpot, models)
	},
	parseItinerarySpots: (itinerarySpots: any[]): ItinerarySpot[] => {
		return parseItinerarySpots(itinerarySpots, models)
	},
	parseTrip,
	parseBlog,
	parseItineraryCategories,
})
