import { Context } from '@nuxt/types'

export default (context: Context) => ({
	getDestinationGuideRoute(destinationId: string, guideId: string): string {
		return `/${context.i18n.locale}/destinations/${destinationId}/guides/${guideId}`
	},

	getItineraryDestinationGuideRoute(
		itineraryId: string,
		destinationId: string,
		guideId: string
	): string {
		return `/${context.i18n.locale}/itineraries/${itineraryId}/destinations/${destinationId}/guides/${guideId}`
	},
})
