interface ResponsiveData {
	screenSize: number
	screenWidth: number
	xs: boolean
	xsAndUp: boolean
	sm: boolean
	smAndUp: boolean
	smAndDown: boolean
	md: boolean
	mdAndUp: boolean
	mdAndDown: boolean
	lg: boolean
	lgAndUp: boolean
	lgAndDown: boolean
	xl: boolean
	xlAndDown: boolean
}

const maxSizes = {
	xs: 600,
	sm: 960,
	md: 1264,
	lg: 1904,
}

export default class Responsive implements ResponsiveData {
	private readonly sizes: number[] = [0, ...Object.values(maxSizes)]
	private _screenSize = 4

	constructor(width?: number) {
		if (width) {
			this.setScreenWidth(width)
		}
	}

	get screenSize() {
		return this._screenSize
	}

	set screenSize(screenSize: number) {
		if (screenSize) {
			if (screenSize < 1 || screenSize > 5) {
				throw new Error('screenSize must be between 1 and 5')
			}
		}
		this._screenSize = screenSize
	}

	get screenWidth() {
		return this.sizes[this.screenSize]
	}

	get xs() {
		return this.screenSize === 1
	}

	get xsAndUp() {
		return this.screenSize >= 1
	}

	get sm() {
		return this.screenSize === 2
	}

	get smAndUp() {
		return this.screenSize >= 2
	}

	get smAndDown() {
		return this.screenSize <= 2
	}

	get md() {
		return this.screenSize === 3
	}

	get mdAndUp() {
		return this.screenSize >= 3
	}

	get mdAndDown() {
		return this.screenSize <= 3
	}

	get lg() {
		return this.screenSize === 4
	}

	get lgAndUp() {
		return this.screenSize >= 4
	}

	get lgAndDown() {
		return this.screenSize <= 4
	}

	get xl() {
		return this.screenSize === 5
	}

	get xlAndDown() {
		return this.screenSize <= 5
	}

	setScreenWidth(width: number) {
		let screenSize = this.sizes.findIndex((size) => size > width)
		if (screenSize === -1) {
			screenSize = this.sizes.length
		}
		if (this.screenSize !== screenSize) {
			this.screenSize = screenSize
		}
	}

	toJSON(): ResponsiveData {
		return {
			screenSize: this.screenSize,
			screenWidth: this.screenWidth,
			xs: this.xs,
			xsAndUp: this.xsAndUp,
			sm: this.sm,
			smAndUp: this.smAndUp,
			smAndDown: this.smAndDown,
			md: this.md,
			mdAndUp: this.mdAndUp,
			mdAndDown: this.mdAndDown,
			lg: this.lg,
			lgAndUp: this.lgAndUp,
			lgAndDown: this.lgAndDown,
			xl: this.xl,
			xlAndDown: this.xlAndDown,
		}
	}
}
