
























































import Vue from 'vue'
import { mapGetters } from 'vuex'
import Dialog, { DialogType } from '~/components/UI/Dialog.vue'
import IconEye from '~/components/UI/Icons/IconEye.vue'
import IconEyeOff from '~/components/UI/Icons/IconEyeOff.vue'

import PButton from '~/components/PassporterUI/PButton.vue'
import InputText from '~/components/PassporterUI/InputText.vue'
import Form from '~/components/UI/Form.vue'

export default Vue.extend({
	name: 'RecoveryPassword',
	components: { Form, InputText, PButton, Dialog, IconEyeOff, IconEye },
	props: {
		show: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			form: {
				code: '',
				email: '',
				password: '',
				confirmPassword: '',
			},
			validatedForm: false,
			showPassword: false,
			showConfirmPassword: false,
			loader: false,
			passwordApiError: null as string | null,
			confirmPasswordApiError: null as string | null,
			passwordSuccess: undefined as string | undefined,
			confirmPasswordSuccess: undefined as string | undefined,
		}
	},
	computed: {
		...mapGetters({
			isAuthenticated: 'auth/isAuthenticated',
		}),
		disabledForm(): boolean {
			return !this.validatedForm || this.loader
		},
	},
	mounted() {
		this.form.code = this.$route.query.code as string
		this.form.email = this.$route.query.email as string
		if (this.form.code && this.form.email && !this.isAuthenticated) {
			this.open()
		}
	},
	methods: {
		open() {
			this.refresh()
			const dialog: DialogType = this.$refs.dialog as DialogType
			dialog.open()
		},
		close() {
			const dialog: DialogType = this.$refs.dialog as DialogType
			dialog.close()
		},
		refresh() {
			this.clearApiErrors()
			this.clearMessages()
		},
		validate() {
			const form: any = this.$refs.form
			form.validate()
		},
		async send() {
			this.loader = true
			try {
				await this.$store.dispatch('auth/resetPassword', this.form)
				this.close()
				await this.$store.dispatch('alerts/setSuccessI18n', {
					index: 'T_reset_password_screen_success_message',
				})
				await this.$store.dispatch('auth/login', this.form)
				await this.$store.dispatch('openHome')
			} catch (e: any) {
				if (e.formErrors) {
					this.passwordApiError = e.formErrors.password
					this.confirmPasswordApiError = e.formErrors.confirmPassword
				}
			} finally {
				this.loader = false
			}
		},
		clearApiErrors() {
			this.passwordApiError = null
			this.confirmPasswordApiError = null
		},
		clearMessages() {
			this.confirmPasswordSuccess = undefined
		},
		passwordValidate(v: string) {
			if (v.length < 8) {
				this.passwordSuccess = undefined
				return this.$t('reset_password_password_error_weak') as string
			}
			this.passwordSuccess = this.$t('reset_password_password_succes_strong') as string

			return true
		},
		confirmPasswordValidate(v: string) {
			if (!v) {
				this.confirmPasswordSuccess = undefined
				return false
			}
			if (v !== this.form.password) {
				this.confirmPasswordSuccess = undefined
				return this.$t('E_error_passwords_dont_match')
			}

			this.confirmPasswordSuccess = this.$t('reset_password_passwords_match') as string

			return true
		},
	},
})
