import { ActionTree, GetterTree, MutationTree } from 'vuex'
import Vue from 'vue'
import { RootState } from '~/store/index'
import Block, { BlockContentType } from '~/models/Block'
import { BlockEntityMapper } from '~/passporter-services/block/entityMapper'
import { Spot2 as Spot } from '~/models/Spot'

const initState = () => ({
	blocks: [],
	block: undefined as undefined | Block<BlockContentType>,
	topSpots: undefined as Spot[] | undefined,
	customSpots: undefined as Spot[] | undefined,
	topSpotsNextPage: undefined as number | undefined,
	customSpotsNextPage: undefined as number | undefined,
})
export const state = initState

export type DiscoveryModuleState = ReturnType<typeof state>

export const mutations: MutationTree<DiscoveryModuleState> = {
	setBlocks(state, blocks) {
		state.blocks = blocks
	},
	setBlock(state, block) {
		state.block = block
	},
	setBlockContent(state, { block, nextPage, content }) {
		if (block) {
			block.content = content
			block.nextPage = nextPage
		} else {
			if (!state.block) return
			Vue.set(state.block, 'content', content)
			Vue.set(state.block, 'nextPage', nextPage)
		}
	},
	pushBlockContent(state, { nextPage, content }) {
		if (!state.block) return
		const newBlock = { ...state.block }
		content.forEach((itemContent: BlockContentType) => {
			const indexed = newBlock.content?.find(
				(indexedItemContent: BlockContentType) => indexedItemContent.id === itemContent.id
			)
			if (!indexed) {
				newBlock.content?.push(itemContent)
			}
		})
		newBlock.nextPage = nextPage
		state.block = newBlock
	},
	setTopSpots(state, { topSpots, nextPage }: { topSpots: Spot[]; nextPage: number }) {
		state.topSpots = topSpots
		state.topSpotsNextPage = nextPage
	},
	setCustomSpots(state, { customSpots, nextPage }: { customSpots: Spot[]; nextPage: number }) {
		state.customSpots = customSpots
		state.customSpotsNextPage = nextPage
	},
	pushTopSpots(state, { topSpots, nextPage }: { topSpots: Spot[]; nextPage: number }) {
		topSpots.forEach((spot) => {
			if (!state.topSpots?.find((s) => s.id === spot.id)) {
				state.topSpots?.push(spot)
			}
		})
		state.topSpotsNextPage = nextPage
	},
	pushCustomSpots(state, { customSpots, nextPage }: { customSpots: Spot[]; nextPage: number }) {
		customSpots.forEach((spot) => {
			if (!state.customSpots?.find((s) => s.id === spot.id)) {
				state.customSpots?.push(spot)
			}
		})
		state.customSpotsNextPage = nextPage
	},
	reset(state) {
		Object.assign(state, initState())
	},
}

export const actions: ActionTree<DiscoveryModuleState, RootState> = {
	async getDestinationBlocks({ commit }) {
		try {
			const blocks = await this.$services.discovery.obtainBlocks()
			commit('setBlocks', blocks)
		} catch (error) {
			return null
		}
	},
	async getBlock({ commit, dispatch }, id) {
		try {
			const parsedResult = await dispatch('getBlockPromise', {
				id,
				content: false,
			})
			commit('setBlock', parsedResult)
			await dispatch('getBlockContent')
		} catch (e) {
			commit('setBlock', null)
			await dispatch('error', e, { root: true })
		}
	},
	async getBlockPromise({ dispatch }, { id, content = true }) {
		try {
			const res = await this.$repositories.discovery.getBlock(id)
			const { data } = res
			const block = BlockEntityMapper.toBlock(data)
			if (content && block.content) {
				await dispatch('getBlockContent', block)
			}
			return block
		} catch (e) {
			return null
		}
	},
	async getBlockContent({ dispatch, commit, state }, block: Block<any>) {
		try {
			const _block = block || state.block
			if (_block.id) {
				const res = await this.$repositories.discovery.getBlockContent(_block.id, 0)
				const { data } = res
				const { next, results } = data
				const parsedResults = await BlockEntityMapper.toBlockContent(_block.type, results)
				await commit('setBlockContent', {
					block: _block,
					nextPage: next,
					content: parsedResults,
				})
			}
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},

	async getMoreBlockContent({ commit, state, dispatch }) {
		try {
			if (state.block) {
				const res = await this.$repositories.discovery.getBlockContent(
					state.block.id,
					state.block.nextPage
				)
				const { data } = res
				const { next, results } = data
				const parsedResults = await BlockEntityMapper.toBlockContent(state.block.type, results)
				commit('pushBlockContent', {
					nextPage: next,
					content: parsedResults,
				})
			}
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async getTopSpots({ dispatch, commit }) {
		try {
			const res = await this.$repositories.discovery.getTopSpots(0)
			const { data } = res
			const { next, results } = data
			const parsedResults = this.$apiModel.spot.parseSpots(results)
			commit('setTopSpots', {
				topSpots: parsedResults,
				nextPage: next,
			})
		} catch (e) {
			commit('setTopSpots', { nextPage: 0, spots: [] })
			await dispatch('error', e, { root: true })
		}
	},
	async getMoreTopSpots({ commit, state, dispatch }) {
		try {
			if (state.topSpots && state.topSpotsNextPage) {
				const res = await this.$repositories.discovery.getTopSpots(state.topSpotsNextPage)
				const { data } = res
				const { next, results } = data
				const parsedResults = this.$apiModel.spot.parseSpots(results)
				commit('pushTopSpots', {
					nextPage: next,
					topSpots: parsedResults,
				})
			}
		} catch (e) {
			commit('setTopSpots', { nextPage: 0, spots: [] })
			await dispatch('error', e, { root: true })
		}
	},
	async getCustomSpots({ dispatch, commit }) {
		try {
			const res = await this.$repositories.discovery.getCustomSpots(0)
			const { data } = res
			const { next, results } = data
			const parsedResults = this.$apiModel.spot.parseSpots(results)
			commit('setCustomSpots', {
				customSpots: parsedResults,
				nextPage: next,
			})
			return parsedResults
		} catch (e) {
			commit('setCustomSpots', { nextPage: 0, spots: [] })
			await dispatch('error', e, { root: true })
		}
	},
	async getMoreCustomSpots({ commit, state, dispatch }) {
		try {
			if (state.customSpots && state.customSpotsNextPage) {
				const res = await this.$repositories.discovery.getCustomSpots(state.customSpotsNextPage)
				const { data } = res
				const { next, results } = data
				const parsedResults = this.$apiModel.spot.parseSpots(results)
				commit('pushCustomSpots', {
					nextPage: next,
					customSpots: parsedResults,
				})
			}
		} catch (e) {
			commit('setCustomSpots', { nextPage: 0, spots: [] })
			await dispatch('error', e, { root: true })
		}
	},
	openBlock({}, blockId) {
		this.$router.push(`/${this.$i18n.locale}/discovery/${blockId}`)
	},
	async reset({ commit }) {
		await commit('reset')
	},
}

export const getters: GetterTree<DiscoveryModuleState, RootState> = {
	blocks: (state) => {
		return state.blocks
	},
	block: (state) => {
		return state.block
	},
	topSpots: (state) => {
		return state.topSpots
	},
	moreTopSpots: (state) => {
		return state.topSpotsNextPage
	},
	customSpots: (state) => {
		return state.customSpots
	},
	moreCustomSpots: (state) => {
		return state.customSpotsNextPage
	},
}
