import Media, { MediaData, MediaProvider, MediaProviderName } from '~/models/Media'
import { removeUndefinedValues } from '~/models/helpers/removeUndefined'

const parseMedias = (medias: any[], options?: { pending: boolean }): Media[] => {
	const result = medias.map((media) => parseMedia(media, options))
	return result
}
const parseMedia = (media: any, options?: { pending: boolean }): Media => {
	const result: MediaData = {
		created_at: media.created,
		id: media.id,
		metadata: media.metadata,
		name: media.name,
		ownerId: media.owner_id,
		size: media.size,
		state: media.state,
		type: media.type,
		url: media.url,
		pending: options?.pending || false,
	}

	if (media.provider) {
		result.provider = parseMediaProvider(media.provider, media.source)
	}
	return new Media(result)
}

const parseMediaProvider = (provider: string, source: Record<string, any>): MediaProvider => {
	const result: MediaProvider = {} as MediaProvider
	result.name = provider as unknown as MediaProviderName
	result.authorName = source.author_name
	result.authorProfileUrl = source.attribution_url
	result.authorPicture = source.author_picture

	return removeUndefinedValues(result) as MediaProvider
}
export default () => ({
	parseMedia,
	parseMedias,
})
