export type Color = 'primary' | 'secondary' | 'neutral' | 'error' | 'social'
export type Tone =
	| '25'
	| '50'
	| '100'
	| '200'
	| '300'
	| '400'
	| '500'
	| '600'
	| '700'
	| '800'
	| '900'
export type ThemeColors = {
	[key in Color]: {
		[key in Tone]: string
	}
}

export interface ThemeData {
	name: string
	colors: ThemeColors
}

export interface ThemeCollectionData {
	current: ThemeData
	list: ThemeData[]
}

export default class ThemeCollection {
	private _current: ThemeData
	private _list: ThemeData[]

	constructor(themeCollection: ThemeCollectionData) {
		this._current = this.current = themeCollection.current
		this._list = this.list = themeCollection.list
	}

	get current(): ThemeData {
		return this._current
	}

	set current(theme: ThemeData) {
		const _theme = JSON.parse(JSON.stringify(theme))
		if (typeof window !== 'undefined') {
			const _colorKeys = Object.keys(_theme.colors) as Color[]
			_colorKeys.forEach((_colorKey) => {
				const _color = Object.keys(_theme.colors[_colorKey]) as Tone[]
				_color.forEach((_tone) => {
					document.documentElement.style.setProperty(
						`--color-${_colorKey}-${_tone}`,
						_theme.colors[_colorKey][_tone]
					)
				})
			})
		}
		this._current = _theme
	}

	get list(): ThemeData[] {
		return this._list
	}

	set list(others: ThemeData[]) {
		this._list = JSON.parse(JSON.stringify(others))
	}

	get others(): ThemeData[] {
		return this.list.filter((theme) => theme.name !== this.current.name)
	}

	setPreviousTheme(repeat = false): void {
		const currentIndex = this.list.findIndex((theme) => theme.name === this.current.name)
		if (currentIndex > 0) {
			this.current = this.list[currentIndex - 1]
		} else if (repeat) {
			this.current = this.list[this.list.length - 1]
		} else {
			throw new Error("There isn't previous theme.")
		}
	}

	setNextTheme(repeat = false): void {
		const currentIndex = this.list.findIndex((theme) => theme.name === this.current.name)
		if (currentIndex < this.list.length - 1) {
			this.current = this.list[currentIndex + 1]
		} else if (repeat) {
			this.current = this.list[0]
		} else {
			throw new Error("There isn't next theme.")
		}
	}

	toJSON(): ThemeCollectionData {
		return {
			current: this.current,
			list: this.list,
		}
	}
}
