import * as Cookies from 'js-cookie'
;(function (t: any, a) {
	t.TapfiliateObject = a
	t[a] =
		t[a] ||
		function () {
			;(t[a].q = t[a].q || []).push(arguments)
		}
})(window, 'tap')

// @ts-ignore
tap('create', process.env.TAPFILIATE_ID, { integration: 'javascript' })

// @ts-ignore
tap('detect', {}, (data) => {
	// eslint-disable-next-line import/namespace
	Cookies.set('tapfiliate-click_id', data.id, {
		secure: process.env.ENVIRONMENT !== 'development',
	})
})
