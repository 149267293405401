var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.percent,staticClass:"wrapper",style:({
		'--forward-right': _vm.forwardRight,
		'--from-degrees-right': _vm.fromDegreesRight,
		'--degrees-right': _vm.degreesRight,
		'--forward-left': _vm.forwardLeft,
		'--from-degrees-left': _vm.fromDegreesLeft,
		'--degrees-left': _vm.degreesLeft,
		'--progress-duration-left': _vm.progressDurationLeft,
		'--progress-duration-right': _vm.progressDurationRight,
		'--forward-end-left': _vm.forwardEndLeft,
		'--layer-start-left': _vm.layerStartLeft,
		'--layer-end-left': _vm.layerEndLeft,
	})},[_c('div',{staticClass:"left"}),_vm._v(" "),_c('div',{staticClass:"right"}),_vm._v(" "),_c('div',{staticClass:"left-progress"}),_vm._v(" "),_c('div',{staticClass:"right-progress"}),_vm._v(" "),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }