import { Destination2 as Destination } from '~/models/Destination'
import { ApiDestination } from '~/passporter-services/destination/apiTypes'

export class DestinationMapperEntity {
	static toDestination(destination: ApiDestination): Destination {
		return {
			id: destination.id,
			parentId: destination.parent_id,
			name: destination.name,
			address: destination.address,
			location: destination.location,
			cover: destination.cover,
			type: destination.type,
			travelersCount: destination.num_travelers,
			travelersAvatars: destination.travelers_pictures,
			civitatisTotalActivities: destination.civitatisTotalActivities ?? 0,
			referenceType: 'destination',
			description: destination.description,
			bounds: destination.bounds,
			placeId: destination.place_id,
		} as Destination
	}

	static toDestinations = (destinations: any[]) => {
		return destinations.map((destination) => this.toDestination(destination))
	}
}
