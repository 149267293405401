





































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import Chip from '~/components/PassporterUI/Chip.vue'
import PIconTicket from '~/components/PassporterUI/Icon/PIconTicket.vue'
import PIconFlight from '~/components/PassporterUI/Icon/PIconFlight.vue'
import PIconSticky from '~/components/PassporterUI/Icon/PIconSticky.vue'

export default Vue.extend({
	name: 'SpotDetails',
	components: { Chip, PIconTicket, PIconFlight, PIconSticky },
	props: {
		savedQuantity: {
			type: Number,
			default: 0,
		},
		travelGuidesIncluded: {
			type: Number,
			default: 0,
		},
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
		}),
	},
})
