














import Vue from 'vue'
import { mapGetters } from 'vuex'
import PButton from '~/components/PassporterUI/PButton.vue'
import Itinerary from '~/models/Itinerary'
import PIconAdd from '~/components/PassporterUI/Icon/PIconAdd.vue'

export default Vue.extend({
	name: 'PlanYourTripButton',
	components: { PButton, PIconAdd },
	props: {
		isFloatingTrack: {
			type: Boolean,
			default: false,
		},
		block: {
			type: Boolean,
			default: false,
		},
		buttonColor: {
			type: String,
			default: 'primary',
		},
		isIcon: {
			type: Boolean,
			default: false,
		},
		sizeLarge: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters({
			site: 'site/site',
			screenSize: 'screenSize',
		}),
	},
	methods: {
		createItineraryMicrosite(): void {
			let itinerary = {}
			if (this.site) {
				itinerary = new Itinerary({
					id: null,
					destinations: [this.site.destination],
				})
			}
			this.addTracker()
			this.$nuxt.$emit('open-itineraryEdit', itinerary)
		},
		isMobile(): Boolean {
			return !this.screenSize.xs
		},
		obtainMixPanelKey(): string {
			return !this.isFloatingTrack && !this.isMobile()
				? 'fixed_planner_cta'
				: 'floating_planner_cta'
		},
		addTracker(): void {
			this.$mixpanel?.track('Click', {
				page: 'microsites',
				context: 'body',
				value: 'floating_planner_cta',
				component: 'button',
			})
		},
	},
})
