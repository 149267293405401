








import Vue from 'vue'
import PIconCheck from '~/components/PassporterUI/Icon/PIconCheck.vue'

export default Vue.extend({
	name: 'OkIcon',
	components: {
		PIconCheck,
	},
})
