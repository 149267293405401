const resource = 'v1/medias'
export default (http: any) => ({
	upload(file: File) {
		const formData = new FormData()
		formData.append('file', file)
		return http.post(`${resource}/`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
	},
	deleteMedia(mediaId: string) {
		return http.delete(`${resource}/${mediaId}/`)
	},
})
