import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { Loader } from '@googlemaps/js-api-loader'
import { RootState } from '~/store/index'
import { DestinationLocationData } from '~/models/Destination'
import { Marker2 as Marker } from '~/models/Marker'
import { DestinationMapperEntity } from '~/passporter-services/destination/toEntity'

const initState = () => ({
	googleApiSession: undefined,
	expires: undefined as Date | undefined,
	currentMapInstance: undefined as HTMLDivElement | undefined,
	browserMarkers: [] as Marker[],
})
export const state = initState
export type MapsModuleState = ReturnType<typeof state>

export const mutations: MutationTree<MapsModuleState> = {
	setGoogleApiSession(state, { token }) {
		const minutesToExpire = 1
		state.expires = new Date(new Date().getTime() + minutesToExpire * 60 * 1000)
		state.googleApiSession = token
	},
	reset(state) {
		Object.assign(state, initState())
	},
	setCurrentMapInstance(state, mapInstance) {
		state.currentMapInstance = mapInstance
	},
	setBrowserMarkers(state, browserMarkers) {
		state.browserMarkers = browserMarkers
	},
}

export const actions: ActionTree<MapsModuleState, RootState> = {
	reset({ commit }) {
		commit('reset')
	},
	async search(
		{ dispatch, commit, state },
		{ search, types, bounds }: { search: string; types: string[]; bounds?: DestinationLocationData }
	) {
		try {
			const loader = new Loader({
				apiKey: process.env.GOOGLE_MAPS_API_ID!,
				version: 'weekly',
			})
			await loader.load()
			const sessionToken = state.googleApiSession
			const expires = state.expires
			const isExpired = expires && expires.getTime() < new Date().getTime()

			const { AutocompleteService, AutocompleteSessionToken } = (await google.maps.importLibrary(
				'places'
			)) as google.maps.PlacesLibrary
			if (!sessionToken || isExpired) {
				const sessionToken = new AutocompleteSessionToken()
				commit('setGoogleApiSession', { token: sessionToken })
			}

			let latLngBounds
			if (bounds) {
				latLngBounds = new google.maps.LatLngBounds(
					new google.maps.LatLng(bounds.southwest.latitude, bounds.southwest.longitude),
					new google.maps.LatLng(bounds.northeast.latitude, bounds.northeast.longitude)
				)
			}

			const autocompleteService = new AutocompleteService()

			const { predictions } = await autocompleteService.getPlacePredictions({
				input: search,
				sessionToken,
				types,
				locationRestriction: latLngBounds,
			})
			return predictions
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},

	getNextPage({ commit, rootGetters }, { results }) {
		const searchResults = rootGetters['searcher/searchResults']
		if (searchResults) {
			const parsedResults = this.$apiModel.spot.parseGooglePlacePredictionsPagination(results)
			commit('searcher/setSearchResults', [...searchResults, ...parsedResults], {
				root: true,
			})
		}
	},

	async googleMapPlace({ dispatch, state }, place) {
		if (state.currentMapInstance) {
			try {
				const result = await this.$repositories.destination.googleFind({
					placeId: place.placeId,
				})
				const { data } = result
				let parsedItem
				if (data.spot) {
					parsedItem = this.$apiModel.spot.parseSpot(data.spot)
				} else if (data.destination) {
					parsedItem = DestinationMapperEntity.toDestination(data.destination)
				}
				return parsedItem
			} catch (e) {
				dispatch('error', e, { root: true })
			}
		}
	},

	async searchWithPagination({ dispatch, commit, state }, { search }) {
		if (state.currentMapInstance) {
			try {
				const sessionToken = state.googleApiSession
				const expires = state.expires
				const isExpired = expires && expires.getTime() < new Date().getTime()
				if (!sessionToken || isExpired) {
					const sessionToken = new google.maps.places.AutocompleteSessionToken()
					await commit('setGoogleApiSession', { token: sessionToken })
				}

				const placesService = await new google.maps.places.PlacesService(state.currentMapInstance)
				return new Promise((resolve) => {
					placesService.textSearch(
						{
							query: search,
						},
						(results, status, pagination) => {
							if (status !== 'OK' || !results) return

							dispatch('getNextPage', { results })

							if (pagination && pagination.hasNextPage) {
								commit(
									'searcher/setPaginationData',
									{
										pagination,
										hasPagination: true,
										nextPageFn: pagination.nextPage,
									},
									{
										root: true,
									}
								)
							} else {
								commit('searcher/setPaginationData', null, {
									root: true,
								})
							}
							commit('searcher/setLoadingPagination', false, { root: true })
							resolve({ results })
						}
					)
				})
			} catch (e) {
				await dispatch('error', e, { root: true })
			}
		}
	},

	setBrowserMarkers({ commit }, browserMarkers) {
		commit('setBrowserMarkers', browserMarkers)
	},
}

export const getters: GetterTree<MapsModuleState, RootState> = {
	map: (state) => {
		return state.currentMapInstance
	},

	browserMarkers: (state) => {
		return state.browserMarkers
	},
}
