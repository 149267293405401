import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState } from '~/store/index'
import SearcherCategory from '~/models/SearcherCategory'
import { DestinationLocationData } from '~/models/Destination'

const initState = () => ({
	searchResults: [] as any[],
	history: [] as any[],
	lastSearch: '' as string,
	paginationData: null as any,
	isLoadingPagination: false as boolean,
})
export const state = initState
export type SearcherModuleState = ReturnType<typeof state>

export const mutations: MutationTree<SearcherModuleState> = {
	setSearchResults(state, searchResults) {
		state.searchResults = searchResults
	},
	setPaginationData(state, paginationData) {
		state.paginationData = paginationData
	},
	setLoadingPagination(state, isLoadingPagination) {
		state.isLoadingPagination = isLoadingPagination
	},
	setSearch(state, lastSearch) {
		state.lastSearch = lastSearch
		state.searchResults = []
	},
	pushHistory(state, lastHistory) {
		if (lastHistory) {
			state.history = state.history.slice(0, 8)
			state.history.unshift(lastHistory)
		}
	},
	reset(state) {
		Object.assign(state, initState())
	},
}

export const actions: ActionTree<SearcherModuleState, RootState> = {
	async search(
		{ commit, state, dispatch },
		{
			searchText,
			category,
			types,
			bounds,
		}: {
			searchText: string
			category: SearcherCategory
			types?: string[]
			bounds?: DestinationLocationData
		}
	) {
		try {
			if (searchText !== state.lastSearch) {
				commit('setSearch', searchText)
			}
			if (state.lastSearch && state.lastSearch.length > 2) {
				let parsedResults: any[] = []
				let emptyStateText = ''
				if (category.googleApi) {
					emptyStateText = this.$i18n.t('Common_Web_Emptycase_Search_Spots_Google') as string
					const results = await dispatch(
						'maps/search',
						{ search: searchText, types, bounds },
						{ root: true }
					)

					parsedResults = this.$apiModel.spot.parseGooglePlacePredictions(results)
				} else if (category.id === 'place') {
					emptyStateText = this.$i18n.t('Common_Web_Emptycase_Search_Spots_Google') as string
					const res = await this.$repositories.spot.search(state.lastSearch, 0)
					const { data } = res
					const { results } = data
					parsedResults = this.$apiModel.spot.parseSpots(results)
				}
				if (parsedResults.length) {
					commit('setSearchResults', parsedResults)
				} else {
					commit('setSearchResults', [
						{
							disabled: true,
							name: emptyStateText,
						},
					])
				}
				return parsedResults
			} else {
				commit('setSearchResults', [])
			}
		} catch (e) {
			commit('setSearchResults', [])
			await dispatch('error', e, { root: true })
		}
	},
	pushHistory({ commit }, searchResult) {
		commit('pushHistory', searchResult)
	},
	async reset({ commit }) {
		await commit('reset')
	},

	async paginationSearch({ dispatch, commit, state }, { searchText }) {
		const { results } = await dispatch(
			'maps/searchWithPagination',
			{ search: searchText },
			{ root: true }
		)

		const parsedResults = this.$apiModel.spot.parseGooglePlacePredictionsPagination(results)

		commit('setSearchResults', [...state.searchResults, ...parsedResults])

		return parsedResults
	},
}

export const getters: GetterTree<SearcherModuleState, RootState> = {
	searchResults: (state) => {
		return state.searchResults
	},
	searchHistory: (state) => {
		return state.history
	},
	paginationData: (state) => {
		return state.paginationData
	},
	isLoadingPagination: (state) => {
		return state.isLoadingPagination
	},
}
