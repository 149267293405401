import BaseService from '~/services/BaseService'
import ItinerarySpot from '~/models/ItinerarySpot'
import { SpotsRequest } from '~/repositories/ItineraryRepository'
import Product from '~/models/Product'

export type BookTrip = {
	startDate: string
	endDate: string
	origin: string
	destination: string
	engine: string
}
export default class ItineraryService extends BaseService {
	async obtainOptions(itineraryId: string): Promise<{
		isVisible: boolean
		isEditable: boolean
		isRemovable: boolean
		isTrip: boolean
		isDiscover: boolean
		isBookings: boolean
		role: string
		access: string
	}> {
		const res = await this.repositories.itinerary.getItineraryOptions(itineraryId)
		const { data } = res
		return this.apiModel.itinerary.parseOptions(data)
	}

	async obtainSpots(item: SpotsRequest): Promise<{
		count: number
		next: number
		results: ItinerarySpot[]
	}> {
		const res = await this.repositories.itinerary.getItinerarySpots(item)
		const { data } = res
		const { count, next, results } = data
		const parsedResults: ItinerarySpot[] = this.apiModel.itinerary.parseItinerarySpots(results)
		return { count, next, results: parsedResults }
	}

	async ensureNoDuplicateItinerary(itineraryId: string, nestedId: string): Promise<any> {
		return await this.repositories.itinerary.nestItinerary({
			itineraryId,
			nestedId,
		})
	}

	async obtainBookTripUrl(itineraryId: string, options: BookTrip): Promise<string> {
		try {
			const res = await this.repositories.itinerary.getBookTripUrl(itineraryId, options)
			const { data } = res
			return data.url
		} catch {
			return 'error'
		}
	}

	async obtainProducts(id: string): Promise<Product[]> {
		try {
			const res = await this.repositories.itinerary.getItineraryProducts(id)
			const { data } = res
			return this.apiModel.product.parseProducts(data.results)
		} catch {
			return []
		}
	}
}
