


















import Vue from 'vue'
import { mapGetters } from 'vuex'
import CoverSpot from '~/components/context/guides/spots/CoverSpot.vue'
import SpotDescription from '~/components/context/guides/spots/SpotDescription.vue'
import Dialog, { DialogType } from '~/components/PassporterUI/Dialog.vue'
import { Spot2 as Spot } from '~/models/Spot'

export default Vue.extend({
	name: 'SpotExperiencesView',
	components: {
		CoverSpot,
		SpotDescription,
		Dialog,
	},
	data() {
		const show: boolean = false
		const spot: Spot = {} as Spot
		const dialog: DialogType | null = null as unknown as DialogType
		return {
			show,
			spot,
			dialog,
		}
	},
	computed: {
		...mapGetters({ screenSize: 'screenSize' }),
		obtainMediasUrl(): string[] {
			const covers = []
			if (this.spot?.cover) covers.push(this.spot?.cover)
			return covers
		},
	},
	methods: {
		open(info: Spot) {
			this.show = true
			this.spot = info
			this.dialog = this.$refs.dialog as DialogType
			this.dialog?.open()
			this.track()
		},
		close() {
			this.show = false
		},
		track() {
			const context = this.$tracker.getContext(this.$route?.name)
			const params: Record<string, string> = {
				action: 'view spot',
				destination: '',
				element: 'spot',
				platform: 'web',
				title: this.spot?.name ?? '',
				id: this.spot?.id ?? '',
				client: 'passporter',
			}
			if (context) params.context = context
			this.$tracker.event('view', params)
		},
	},
})
