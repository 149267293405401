





































import Vue from 'vue'
import { mapGetters } from 'vuex'
import AuthCarousel from '~/components/context/auth/AuthCarousel.vue'
import Alerts from '~/components/context/core/Alerts.vue'

export default Vue.extend({
	name: 'AuthLayout',
	components: { Alerts, AuthCarousel },
	middleware({ store }) {
		store.dispatch('goToScrollPosition', 0)
	},
	head() {
		return {
			htmlAttrs: {
				lang: this.$i18n.locale,
			},
			meta: [
				{
					hid: 'og:description',
					property: 'og:description',
					content: this.$t('Web_meta_description') as string,
				},
			],
		}
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
		}),
	},
})
