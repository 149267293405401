














































































import Vue, { PropOptions } from 'vue'
import { mapGetters } from 'vuex'
import Row from '~/components/UI/Row.vue'
import Col from '~/components/UI/Col.vue'
import Cover from '~/components/PassporterUI/Cover.vue'
import ErrorMessage from '~/components/context/error/ErrorMessage.vue'

export default Vue.extend({
	components: {
		ErrorMessage,
		Cover,
		Col,
		Row,
	},
	props: {
		error: {
			type: Object,
			default: null,
		} as PropOptions<{ statusCode: number; message: string }>,
	},
	data() {
		return {
			pageNotFound: '404 Not Found',
			invitationErrors: ['invalid_invitation', 'already_used_invitation'] as string[],
		}
	},
	head() {
		const title: string = this.invitationError
			? (this.$t('Invitation_Expired_Title') as string)
			: this.error.statusCode === 404
			? (this.$t('Error_404_notfound_title') as string)
			: this.otherError
		return {
			title,
		}
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
		}),
		otherError(): string {
			return this.$t('error_request_error') as string
		},
		invitationError(): boolean {
			return this.invitationErrors.includes(this.error.message)
		},
		backgroundImage(): string {
			if (this.invitationError) {
				return '/img/bgInvitationExpired.webp'
			} else if (this.error.statusCode === 404) {
				return '/img/bgNotFound.webp'
			}
			return '/img/bgGenericError.webp'
		},
	},
	mounted() {
		if (this.invitationError) {
			try {
				this.$mixpanel?.track('Invitation Expired Impression')
			} catch (e) {}
		}
	},
	methods: {
		onInvitationExpired() {
			try {
				this.$mixpanel?.track('Invitation Expired CTA clicked')
				this.$store.dispatch('openHome')
			} catch (e) {}
		},
		goToDiscovery() {
			this.$store.dispatch('openHome')
		},
	},
})
