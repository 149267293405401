












































import Vue from 'vue'
import { mapGetters } from 'vuex'
import Dialog, { DialogType } from '~/components/UI/Dialog.vue'
import Profile from '~/models/Profile'
import PButton from '~/components/PassporterUI/PButton.vue'
import Form from '~/components/UI/Form.vue'
import InputText from '~/components/PassporterUI/InputText.vue'
import Col from '~/components/UI/Col.vue'
export default Vue.extend({
	name: 'ProfileEdit',
	components: { Col, InputText, Form, PButton, Dialog },
	props: {
		show: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			form: undefined as Profile | undefined,
			validatedForm: false,
			loader: false,
			nameApiError: null as string | null,
			usernameApiError: null as string | null,
		}
	},
	computed: {
		...mapGetters({
			isAuthenticated: 'auth/isAuthenticated',
			user: 'auth/user',
		}),
		disabledForm(): boolean {
			return !this.validatedForm || this.loader || !!this.usernameApiError
		},
	},
	mounted() {
		this.checkWelcome()
	},
	methods: {
		async open() {
			this.refresh()
			await this.$store.dispatch('auth/setUser')
			this.form = new Profile(this.user)
			const dialog: DialogType = this.$refs.dialog as DialogType
			dialog.open()
		},
		close() {
			const dialog: DialogType = this.$refs.dialog as DialogType
			dialog.close()
		},
		refresh() {
			this.clearApiErrors()
			this.validatedForm = false
		},
		async update() {
			this.loader = true
			try {
				await this.$store.dispatch('auth/update', this.form)
				await this.close()
			} catch (e: any) {
				if (e.formErrors) {
					this.nameApiError = e.formErrors.name
					this.usernameApiError = e.formErrors.username
				}
			} finally {
				this.loader = false
			}
		},
		checkUserName(username: string) {
			if (this.form && username) {
				this.form.username = username = username.toLowerCase()
				try {
					this.$store.dispatch('auth/availableUserName', username).then((available) => {
						if (!available) {
							this.usernameApiError = this.$t('common_not_available') as string
						} else {
							this.usernameApiError = null
						}
					})
				} catch (e: any) {}
			}
		},
		checkWelcome() {
			if (this.isAuthenticated && this.user && (!this.user.name || !this.user.username)) {
				this.open()
			}
		},
		clearApiErrors() {
			this.nameApiError = null
			this.usernameApiError = null
		},
		nameValidate(v: string) {
			if (!v) {
				return false
			}
			if (v && v.length < 3) {
				return this.$t('Name_Description_Characters')
			}
			return true
		},
		usernameValidate(v: string) {
			if (!v) {
				return false
			}
			if (v && v.length < 3) {
				return this.$t('E_error_invalid_username')
			}

			return true
		},
	},
})
