;(function (h: any, o: Document, t: String, j: String) {
	h.hj =
		h.hj ||
		function () {
			;(h.hj.q = h.hj.q || []).push(arguments)
		}
	h._hjSettings = { hjid: 2593880, hjsv: 6 }
	const a = o.getElementsByTagName('head')[0]
	const r = o.createElement('script')
	r.async = true
	r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
	a.appendChild(r)
})(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
