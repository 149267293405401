import Media from './Media'
import Destination from './Destination'
import { serialize } from './helpers/serialize'

export interface IBooking {
	currency: string
	freeCancellation: boolean
	name?: string
	price: number
	rate: number
	url: string
}

export interface SpotAssets {
	url?: string
	phone?: string
	booking?: IBooking
	link?: string
	price?: string
}

export type Location = {
	latitude: number
	longitude: number
}

export interface SpotData {
	id?: string | null
	referenceType?: 'spot'
	address?: string
	cover?: string
	covers?: string[]
	description?: string
	location: Location
	name: string
	numTravelers?: number
	isPrivate: boolean
	travelersPictures: string[]
	type?: string
	medias?: Media[]
	experiences?: SpotData[]
	experiencesCount?: number
	experiencesNextPage?: number
	assets?: SpotAssets
	ownerId?: string
	parentSpotId?: string
	visited: boolean
	placeId?: string
	spotParent?: SpotData
	nearbySpots?: SpotData[]
	nearbySpotsNextPage?: number
	parentId?: string
	parent?: Destination
	isSaved?: boolean
	mapMarker?: string
}

export interface Spot2 {
	id?: string | null
	referenceType?: 'spot'
	address?: string
	cover?: string
	covers?: string[]
	description?: string
	location: Location
	name: string
	numTravelers?: number
	isPrivate: boolean
	travelersPictures: string[]
	type?: string
	medias?: Media[]
	experiences?: Spot2[]
	experiencesCount?: number
	experiencesNextPage?: number
	assets?: SpotAssets
	ownerId?: string
	parentSpotId?: string
	visited: boolean
	placeId?: string
	spotParent?: Spot2
	nearbySpots?: Spot2[]
	nearbySpotsNextPage?: number
	parentId?: string
	parent?: Destination
	isSaved?: boolean
	mapMarker?: string
}

export type PaginateSpots = { nextPage: number; spots: Spot2[] }

export default class Spot implements SpotData {
	private readonly _id
	private readonly _referenceType = 'spot'
	private readonly _address
	private readonly _cover
	private readonly _covers
	private readonly _description
	private readonly _location
	private _name
	private readonly _numTravelers
	private _isPrivate
	private readonly _travelersPictures
	private readonly _type
	private _medias
	private _experiences
	private _experiencesCount
	private _assets
	private readonly _ownerId
	private readonly _parentSpotId
	private _visited
	private readonly _placeId
	private _spotParent
	private _nearbySpots
	private _nearbySpotsNextPage
	private _experiencesNextPage
	private _isSaved
	private readonly _parentId
	private _parent
	private readonly _mapMarker
	constructor(spot?: SpotData) {
		this._id = spot?.id || null
		this._address = spot?.address
		this._cover = spot?.cover
		this._covers = spot?.covers
		this._description = spot?.description
		this._location = spot?.location || { latitude: 0, longitude: 0 }
		this._name = spot?.name || ''
		this._numTravelers = spot?.numTravelers
		this._isPrivate = spot?.isPrivate === undefined ? true : spot.isPrivate
		this._travelersPictures = spot?.travelersPictures || []
		this._type = spot?.type
		this._medias = serialize(Media, spot?.medias)
		this._experiences = serialize(Spot, spot?.experiences)
		this._experiencesCount = spot?.experiencesCount
		this._experiencesNextPage = spot?.experiencesNextPage
		this._assets = spot?.assets
		this._ownerId = spot?.ownerId
		this._parentSpotId = spot?.parentSpotId
		this._visited = spot?.visited || false
		this._placeId = spot?.placeId
		this._spotParent = serialize(Spot, spot?.spotParent)
		this._nearbySpots = serialize(Spot, spot?.nearbySpots)
		this._nearbySpotsNextPage = spot?.nearbySpotsNextPage
		this._isSaved = spot?.isSaved
		this._parentId = spot?.parentId
		this._mapMarker = spot?.mapMarker
		this._parent = serialize(Destination, spot?.parent)
	}

	get id(): string | null {
		return this._id
	}

	get referenceType(): 'spot' {
		return this._referenceType
	}

	get address(): string | undefined {
		return this._address
	}

	get cover(): string | undefined {
		return this._cover
	}

	get covers(): string[] | undefined {
		return this._covers
	}

	get description(): string | undefined {
		return this._description
	}

	get location(): Location {
		return this._location
	}

	get name(): string {
		return this._name
	}

	set name(name: string) {
		this._name = name
	}

	get numTravelers(): number | undefined {
		return this._numTravelers
	}

	get isPrivate(): boolean {
		return this._isPrivate
	}

	set isPrivate(isPrivate: boolean) {
		this._isPrivate = isPrivate
	}

	get isPublic(): boolean {
		return !this._isPrivate
	}

	set isPublic(isPublic: boolean) {
		this._isPrivate = !isPublic
	}

	get travelersPictures(): string[] {
		return this._travelersPictures
	}

	get type(): string | undefined {
		return this._type
	}

	get medias(): Media[] | undefined {
		return this._medias
	}

	set medias(medias: Media[] | undefined) {
		this._medias = medias
	}

	get experiences(): any | undefined {
		return this._experiences
	}

	set experiences(experiences: any | undefined) {
		this._experiences = experiences
	}

	get experiencesNextPage(): number | undefined {
		return this._experiencesNextPage
	}

	set experiencesNextPage(experiencesNextPage: number | undefined) {
		this._experiencesNextPage = experiencesNextPage
	}

	get experiencesCount(): number | undefined {
		return this._experiencesCount
	}

	get assets(): SpotAssets | undefined {
		return this._assets
	}

	set assets(assets: SpotAssets | undefined) {
		this._assets = assets
	}

	get ownerId(): string | undefined {
		return this._ownerId
	}

	get parentSpotId(): string | undefined {
		return this._parentSpotId
	}

	get visited(): boolean {
		return this._visited
	}

	set visited(visited: boolean) {
		this._visited = visited
	}

	get placeId(): string | undefined {
		return this._placeId
	}

	get spotParent(): Spot | undefined {
		return this._spotParent
	}

	set spotParent(spotParent: Spot | undefined) {
		this._spotParent = spotParent
	}

	get nearbySpots(): any[] | undefined {
		return this._nearbySpots
	}

	get nearbySpotsNextPage(): number | undefined {
		return this._nearbySpotsNextPage
	}

	get isSaved(): boolean | undefined {
		return this._isSaved
	}

	set isSaved(isSaved: boolean | undefined) {
		this._isSaved = isSaved
	}

	get parentId(): string | undefined {
		return this._parentId
	}

	get parent(): Destination | undefined {
		return this._parent
	}

	set parent(parent: Destination | undefined) {
		if (parent?.id === this.parentId) {
			this._parent = parent
		} else {
			throw new Error('invalid parent')
		}
	}

	get mapMarker(): string | undefined {
		return this._mapMarker
	}

	setExperiences(
		experiences?: this[] | undefined,
		count?: number,
		nextPage?: number
	): void {
		this._experiences = experiences
		this._experiencesCount = count
		this._experiencesNextPage = nextPage
	}

	pushSpotExperiences(
		spotExperiences?: this[] | undefined,
		nextPage?: number
	): void {
		spotExperiences?.forEach((spotExperience: this) => {
			const indexed = this.experiences?.find(
				(indexedSpotExperience: any) =>
					indexedSpotExperience.id === spotExperience.id
			)
			if (!indexed) {
				this._experiences?.push(spotExperience)
			}
		})
		this._experiencesNextPage = nextPage
	}

	setNearbySpots(nearbySpots?: this[] | undefined, nextPage?: number): void {
		this._nearbySpots = nearbySpots
		this._nearbySpotsNextPage = nextPage
	}

	pushNearbySpots(nearbySpots?: this[] | undefined, nextPage?: number): void {
		nearbySpots?.forEach((nearbySpot: this) => {
			const indexed = this._nearbySpots?.find(
				(indexedNearbySpot: this) => indexedNearbySpot.id === nearbySpot.id
			)
			if (!indexed) {
				this._nearbySpots?.push(nearbySpot)
			}
		})
		this._nearbySpotsNextPage = nextPage
	}

	toJSON(): SpotData {
		return {
			id: this.id,
			referenceType: this.referenceType,
			address: this.address,
			cover: this.cover,
			description: this.description,
			location: this.location,
			name: this.name,
			numTravelers: this.numTravelers,
			isPrivate: this.isPrivate,
			travelersPictures: this.travelersPictures,
			type: this.type,
			medias: this.medias,
			experiences: this.experiences,
			experiencesCount: this.experiencesCount,
			experiencesNextPage: this.experiencesNextPage,
			assets: this.assets,
			ownerId: this.ownerId,
			parentSpotId: this.parentSpotId,
			visited: this.visited,
			placeId: this.placeId,
			spotParent: this.spotParent,
			nearbySpots: this.nearbySpots,
			nearbySpotsNextPage: this.nearbySpotsNextPage,
			isSaved: this.isSaved,
			mapMarker: this.mapMarker,
		}
	}
}
