import { Plugin } from '@nuxt/types'
import createNavigation from '~/navigation/Navigation'

declare module 'vue/types/vue' {
	interface Vue {
		$navigation: ReturnType<typeof createNavigation>
	}
}
declare module '@nuxt/types' {
	interface NuxtAppOptions {
		$navigation: ReturnType<typeof createNavigation>
	}
	interface Context {
		$navigation: ReturnType<typeof createNavigation>
	}
}
declare module 'vuex/types/index' {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	interface Store<S> {
		$navigation: ReturnType<typeof createNavigation>
	}
}

const myPlugin: Plugin = (context, inject) => {
	inject('navigation', createNavigation(context))
}

export default myPlugin
