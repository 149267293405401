









import Vue from 'vue'

export default Vue.extend({
	name: 'Divider',
	props: {
		borderColor: {
			type: String,
			default: 'border-neutral-200',
		},
		vertical: {
			type: Boolean,
			default: false,
		},
	},
})
