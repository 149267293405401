

























import Vue from 'vue'
export default Vue.extend({
	name: 'PIconMail',
	props: {
		width: {
			type: [Number, String],
			default: 24,
		},
		height: {
			type: [Number, String],
			default: 24,
		},
	},
})
