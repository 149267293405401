import createPersistedState from 'vuex-persistedstate'
import * as JsCookies from 'js-cookie'
import NodeCookies from 'cookie'

// @ts-ignore
export default ({ store, req }) => {
	createPersistedState({
		key: 'authentication-cookie',
		paths: [
			'auth.accessToken',
			'auth.refreshToken',
			'auth.itineraryDialog',
			'auth.expires',
			'auth.id',
			'auth.showAppBanner',
			'auth.onboards',
		],
		storage: {
			getItem: (key) => {
				return process.client
					? // eslint-disable-next-line import/namespace
					  JsCookies.getJSON(key)
					: // eslint-disable-next-line import/no-named-as-default-member
					  NodeCookies.parse(req.headers.cookie || '')[key]
			},
			setItem: (key, value) => {
				const valueJson = JSON.parse(value).auth
				let expires
				if (valueJson) {
					expires = new Date(valueJson.expires)
				}
				// eslint-disable-next-line import/namespace
				return JsCookies.set(key, value, {
					expires,
					secure: process.env.ENVIRONMENT !== 'development',
				})
			},
			// eslint-disable-next-line import/namespace
			removeItem: (key) => JsCookies.remove(key),
		},
	})(store)
}
