











import Vue from 'vue'

export default Vue.extend({
	name: 'ImageDotsMobile',
	props: {
		imagesLength: {
			type: Number,
			required: true,
		},
		activeIndeximage: {
			type: Number,
			required: true,
		},
	},
	methods: {
		seeThisImage(index: number): boolean {
			return this.activeIndeximage === index - 1
		},
	},
})
