import Spot from './Spot'
import Media from './Media'

interface ILocalStorageSpot {
	id?: string | null
	name?: string | null
	cover?: string | null
	address?: string | null
	description?: string | null
	location?: { latitude: number; longitude: number }
	mapMarker?: string
	isReference?: boolean
	referenceType?: 'travel-spot'
	data?: Spot
	medias?: Media[]
}

export default class PlannerSpot implements ILocalStorageSpot {
	id?: string | null
	name?: string | null
	cover?: string | null
	address?: string | null
	location?: { latitude: number; longitude: number }
	referenceType?: 'travel-spot'
	data?: Spot | undefined
	isReference: boolean
	mapMarker: string
	description?: string | null
	medias?: Media[]
	constructor(Storage?: ILocalStorageSpot) {
		this.id = Storage?.id || null
		this.name = Storage?.name || null
		this.cover = Storage?.cover || null
		this.address = Storage?.address || null
		this.description = Storage?.description ?? null
		this.location = Storage?.location || { latitude: 0, longitude: 0 }
		this.isReference = Storage?.isReference ?? false
		this.mapMarker =
			this.isReference && Storage?.mapMarker
				? Storage?.mapMarker
				: 'markerIconUncheckedMd'
		this.data = Storage?.data
		this.medias = Storage?.medias ?? []
		this.referenceType = 'travel-spot'
	}
}
