import { Context } from '@nuxt/types'

export default (context: Context) => ({
	getRoute(
		itineraryId: string,
		query?: Record<string, string>,
		section?: 'map' | 'discovery' | 'bookings'
	): string {
		const queryCollection: string[] = []

		if (query) {
			queryCollection.push(...Object.keys(query).map((key) => `${key}=${query[key]}`))
		}

		let newQuery = queryCollection.join('&')
		if (newQuery) {
			newQuery = `?${newQuery}`
		}

		return `/${context.i18n.locale}/itineraries/${itineraryId}${
			section ? `/${section}` : ''
		}${newQuery}`
	},
	getItineraryMapRoute(itineraryId: string): string {
		return `/${context.i18n.locale}/itineraries/${itineraryId}/map`
	},
	getItineraryProductsRoute(itineraryId: string): string {
		return `/${context.i18n.locale}/itineraries/${itineraryId}/products`
	},
	getDestination(itineraryId: string, destinationId: string): string {
		return `/${context.i18n.locale}/itineraries/${itineraryId}/destinations/${destinationId}`
	},
	getLandmark(itineraryId: string, destinationId: string, landmarkId: string): string {
		return `${this.getDestination(itineraryId, destinationId)}/landmark/${landmarkId}`
	},
	getNotesList(itineraryId: string): string {
		return `/${context.i18n.locale}/itineraries/${itineraryId}/bookings`
	},
})
