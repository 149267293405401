





















































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import Col from '~/components/UI/Col.vue'
import PIconClose from '~/components/PassporterUI/Icon/PIconClose.vue'
import POverlay from '~/components/PassporterUI/POverlay.vue'

type BottomSheetStatus = 'open' | 'closed' | 'half'
export type BottomSheetType = {
	resetPosition: () => void
}

export default Vue.extend({
	name: 'BottomSheet',
	components: { Col, PIconClose, POverlay },
	props: {
		value: {
			type: Boolean,
			default: undefined,
		},
		closable: {
			type: Boolean,
			default: false,
		},
		closeText: {
			type: String,
			default: undefined,
		},
		draggable: {
			type: Boolean,
			default: false,
		},
		offsetY: {
			type: Number,
			default: 56,
		},
		offsetClosed: {
			type: Number,
			default: 0,
		},
		contentClasses: {
			type: [Object],
			default: () => null,
		},
		contentStyles: {
			type: [Object],
			default: () => null,
		},
		overlay: {
			type: Boolean,
			default: false,
		},
		half: {
			type: Boolean,
			default: false,
		},
		lockOnOpen: {
			type: Boolean,
			default: false,
		},
		alwaysOpen: {
			type: Boolean,
			default: false,
		},
		contentMargin: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			open: false as boolean,
			isMoving: false as boolean,
			canMove: true as boolean,
			y: 0 as number,
			state: 'closed' as BottomSheetStatus,
			rect: null as DOMRect | null,
			closedHeight: 0 as number,
			contentScrollTop: 0 as number,
			sizeInit: true as boolean,
		}
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
		}),
		modelValue: {
			get(): boolean {
				if (typeof this.value === 'undefined') {
					return this.open
				}
				return this.value
			},
			set(newValue: boolean) {
				this.open = newValue
				if (typeof this.value !== 'undefined') {
					this.$emit('input', newValue)
				}
				if (!newValue) {
					this.resetPosition()
				}
			},
		},
		dragEnabled(): boolean {
			return (
				(this.draggable && !this.lockOnOpen) ||
				(this.draggable && this.lockOnOpen && this.state !== 'open')
			)
		},
	},
	watch: {
		modelValue(open) {
			if (open) {
				this.$nextTick(() => {
					this.getClosedHeight()
					this.bindContentScrollListener()
				})
			} else {
				this.unbindContentScrollListener()
			}
		},
	},
	mounted() {
		if (this.half && this.draggable) {
			this.y = window.innerHeight * 0.5
			this.state = 'half'
		} else {
			this.y = window.innerHeight - this.offsetY
		}

		this.bindContentScrollListener()
		window.addEventListener('resize', () => this.getRect())
	},
	beforeDestroy() {
		this.unbindContentScrollListener()
		window.removeEventListener('resize', () => this.getRect())
	},
	methods: {
		resetPosition() {
			if (this.half && this.draggable) {
				this.state = 'half'
			} else {
				this.y = this.rect ? this.rect.height - this.offsetY : 0
				this.state = 'closed'
			}
			const bottomSheetContent = this.$refs.bottomSheetContent as HTMLDivElement
			if (bottomSheetContent) {
				bottomSheetContent.scrollTop = 0
			}
			this.getClosedHeight()
			this.$emit('stateChange', 'closed')
		},
		bindContentScrollListener() {
			if (this.$refs.bottomSheetContent) {
				;(this.$refs.bottomSheetContent as HTMLDivElement).addEventListener('scroll', this.onScroll)
			}
		},
		unbindContentScrollListener() {
			if (this.$refs.bottomSheetContent) {
				;(this.$refs.bottomSheetContent as HTMLDivElement).removeEventListener(
					'scroll',
					this.onScroll
				)
			}
		},
		onMoving(event: TouchEvent) {
			const touch: Touch | undefined = event.touches.length ? event.touches[0] : undefined
			if (touch) {
				const _rect = (this.$refs.bottomSheet as Element)?.getBoundingClientRect()
				if (touch.clientY >= 0 && touch.clientY <= _rect.height) {
					this.y = touch.clientY
				}
			}
		},
		onMoved(event: TouchEvent) {
			event.stopPropagation()
			const touch: Touch | undefined = event.touches.length ? event.touches[0] : undefined
			if (touch) {
				this.isMoving = true
			}
		},
		onEnd(event: TouchEvent) {
			event.stopPropagation()
			if (this.isMoving) {
				this.isMoving = false
				if (!this.rect) {
					this.getRect()
				}
				if (this.rect) {
					const rectQ = this.rect.height * 0.25
					const rectH = this.rect.height * 0.5
					if (this.state === 'closed' || this.state === 'half') {
						if (this.y > rectQ) {
							this.state = 'half'
							this.$emit('stateChange', 'half')
						}
						if (this.y < rectH) {
							this.state = 'open'
							this.$emit('stateChange', 'open')
						}
						if (this.y > rectH + rectQ) {
							this.state = 'closed'
							this.$emit('stateChange', 'closed')
						}
						if (this.y >= this.rect.height - 20) {
							if (!this.alwaysOpen) {
								this.modelValue = false
							}
							this.resetPosition()
						}
					} else if (this.state === 'open') {
						if (this.y >= rectQ) {
							this.state = 'half'
							this.$emit('stateChange', 'half')
						}
					}
				}
			} else if (this.state === 'closed') {
				this.state = 'half'
				this.$emit('stateChange', 'half')
			}
		},
		getRect() {
			this.rect = (this.$refs.bottomSheet as Element)?.getBoundingClientRect()
		},
		getClosedHeight() {
			const closedContent = this.$refs.closedContent as HTMLElement | undefined
			if (closedContent?.clientHeight) {
				this.closedHeight = closedContent.clientHeight
			}
		},
		calcY() {
			if (!this.dragEnabled) {
				return this.offsetY
			}
			if (!this.rect) {
				this.getRect()
			}
			if (!this.closedHeight) {
				this.getClosedHeight()
			}
			if (this.rect && !this.isMoving) {
				if (this.state === 'closed') {
					return this.rect.height - this.closedHeight - this.offsetClosed
				} else if (this.state === 'open') {
					return this.offsetY
				} else if (this.state === 'half') {
					return this.rect.height * 0.5
				}
			}
			return this.y
		},
		close() {
			if (this.$listeners.close) {
				this.$emit('close')
			} else if (!this.alwaysOpen) {
				this.modelValue = false
			}
		},
		onScroll() {
			const bottomSheetContent = this.$refs.bottomSheetContent as HTMLDivElement
			const scrollTop = bottomSheetContent.scrollTop
			if (this.contentScrollTop > scrollTop) {
				this.$emit('scrollToTop')
			} else {
				this.$emit('scrollToBottom')
			}
			this.contentScrollTop = scrollTop
		},
	},
})
