































































































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import Dialog, { DialogType } from '~/components/UI/Dialog.vue'
import Row from '~/components/UI/Row.vue'
import Col from '~/components/UI/Col.vue'
import PButton from '~/components/PassporterUI/PButton.vue'
import IconPlus from '~/components/UI/Icons/IconPlus.vue'
import IconBookmark from '~/components/UI/Icons/IconBookmark.vue'

export default Vue.extend({
	name: 'WelcomeLightbox',
	components: {
		IconBookmark,
		IconPlus,
		PButton,
		Col,
		Row,
		Dialog,
	},
	computed: {
		...mapGetters({
			user: 'auth/user',
			screenSize: 'screenSize',
		}),
	},
	methods: {
		open() {
			const dialog: DialogType = this.$refs.dialog as DialogType
			dialog.open()
			this.$mixpanel?.track('welcome lightbox impression')
		},
		close() {
			const dialog: DialogType = this.$refs.dialog as DialogType
			dialog.close()
		},
		onStart() {
			this.$mixpanel?.track('welcome lightbox start')
			this.close()
		},
		onHowToClick() {
			this.$mixpanel?.track('welcome lightbox how to use')
			window.open('https://passporterapp.com/es/blog/viajes/itinerario-de-viaje/')
			this.close()
		},
		onClose(fromButton: boolean) {
			if (fromButton) {
				this.$mixpanel?.track('welcome lightbox close')
			}
		},
	},
})
