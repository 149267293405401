

















import Vue from 'vue'
export default Vue.extend({
	name: 'List',
	props: {
		tag: {
			type: String,
			default: 'ul',
		},
		minWidth: {
			type: [String, Number],
			default: undefined,
		},
		maxWidth: {
			type: [String, Number],
			default: undefined,
		},
		width: {
			type: [String, Number],
			default: undefined,
		},
		bordered: {
			type: Boolean,
			default: false,
		},
		gap: {
			type: Number,
			default: 0,
		},
	},
})
