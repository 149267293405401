import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState } from '~/store/index'

const initState = () => ({
	alerts: [] as any[],
})
export const state = initState
export type AlertsModuleState = ReturnType<typeof state>

export const mutations: MutationTree<AlertsModuleState> = {
	pushAlert(state, alert) {
		state.alerts = state.alerts.slice(0, 2)
		state.alerts.unshift(alert)
	},
	deleteAlert(state, id) {
		const index = state.alerts.findIndex((alert) => alert.id === id)
		if (index >= 0) {
			state.alerts.splice(index, 1)
		}
	},
	reset(state) {
		Object.assign(state, initState())
	},
}

export const actions: ActionTree<AlertsModuleState, RootState> = {
	setAlert({ commit }, alert) {
		if (!alert.id) {
			alert.id = new Date().getTime() + Math.random()
		}
		commit('pushAlert', alert)
		setTimeout(function () {
			commit('deleteAlert', alert.id)
		}, alert.duration || 10000)
	},
	async setSuccess({ dispatch }, { text, option }) {
		await dispatch('setAlert', { text, type: 'success', option })
	},
	async setSad({ dispatch }, { text, option }) {
		await dispatch('setAlert', { text, type: 'sad', option })
	},
	async setSuccessI18n({ dispatch }, { index, data }) {
		await dispatch('setAlert', {
			index,
			data,
			type: 'success',
		})
	},
	async setError({ dispatch }, text) {
		await dispatch('setAlert', { text, type: 'error' })
	},
	async setErrorI18n({ dispatch }, { index, data }) {
		await dispatch('setAlert', { index, data, type: 'error' })
	},
	async apiError({ dispatch }, e) {
		if (e?.response?.data?.detail) {
			await dispatch('setError', e.response.data.detail)
		} else {
			await dispatch('setErrorI18n', {
				index: 'E_Error_unknown_server_response',
			})
		}
	},
	async genericError({ dispatch }) {
		await dispatch('setErrorI18n', { index: 'E_Error_unknown_server_response' })
	},
	deleteAlert({ commit }, id) {
		commit('deleteAlert', id)
	},
	reset({ commit }) {
		commit('reset')
	},
}

export const getters: GetterTree<AlertsModuleState, RootState> = {
	alerts: (state) => {
		return state.alerts
	},
}
