
























































import Vue from 'vue'
import Form from '~/components/UI/Form.vue'
import InputText from '~/components/PassporterUI/InputText.vue'
import PButton from '~/components/PassporterUI/PButton.vue'
import { validEmail, validPassword } from '~/services/Validations'

export type SignupFormData = {
	email: string
	password: string
}
export default Vue.extend({
	name: 'SignupForm',
	components: { PButton, Form, InputText },
	props: {
		error: {
			type: String,
			default: undefined,
		},
	},
	mounted() {
		this.addViewTracker()
	},
	methods: {
		passwordValidate(value: string) {
			if (!value) {
				return this.$t('error_invalid_password')
			}
			if (!validPassword(value)) {
				return this.$t('error_invalid_password')
			}
			return true
		},
		emailValidate(value: string) {
			if (!value) {
				return this.$t('E_error_invalid_email')
			}
			if (!validEmail(value)) {
				return this.$t('E_error_invalid_email')
			}
			return true
		},
		addViewTracker() {
			const params = {
				action: 'view sign up',
				element: 'sign up',
				platform: 'web',
			}
			this.$tracker.event('view', params)
		},
	},
})
