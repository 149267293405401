





















import Vue from 'vue'
import { mapGetters } from 'vuex'
import Chip from '~/components/PassporterUI/Chip.vue'

export default Vue.extend({
	name: 'PrivateTag',
	components: { Chip },
	props: {
		isPrivate: {
			type: Boolean,
			required: true,
		},
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
		}),
	},
})
