import { Middleware } from '@nuxt/types'
import localesRedirectsJson from '../locales/locales-redirects.json'

type LocalesRedirects = {
	[key: string]: string
}
const localesRedirects: LocalesRedirects = localesRedirectsJson

export default (function ({ route, redirect }) {
	const fragmentedRoute = route.path.split('/')
	const language = fragmentedRoute[1]
	const section = fragmentedRoute[2]
	if (localesRedirects[language]) {
		let newRoute
		if (section !== undefined) {
			newRoute = route.path.replace(`/${language}/`, `/${localesRedirects[language]}/`)
		} else {
			newRoute = route.path.replace(`/${language}`, `/${localesRedirects[language]}`)
		}
		return redirect(301, newRoute)
	}
} as Middleware)
