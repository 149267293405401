const resource = 'v1/spots'
export default (http: any) => ({
	search(q: string, page: number) {
		return http.get(`${resource}/search/`, {
			params: {
				q,
				page,
				page_size: 20,
			},
		})
	},
	searchNearby({
		location,
		page,
	}: {
		location: { latitude: number; longitude: number }
		page: number
	}) {
		const { latitude, longitude } = location
		return http.get(`${resource}/search/`, {
			params: {
				latitude,
				longitude,
				radius: 10,
				page,
				page_size: 6,
			},
		})
	},
	getSpots({
		destinationId,
		categoryId,
		page,
	}: {
		destinationId: string
		categoryId: string
		page: number
	}) {
		return http.get(`${resource}/`, {
			params: {
				destination_id: destinationId,
				category_id: categoryId,
				page,
				page_size: 10,
			},
		})
	},
	getSpot(spotId: string) {
		return http.get(`${resource}/${spotId}/`)
	},
	getSpotMedias(spotId: string) {
		return http.get(`${resource}/${spotId}/medias/`)
	},
	addSpotMedias(spotId: string, form: any) {
		return http.post(`${resource}/${spotId}/medias/`, form)
	},
	getSpotExperiences(spotId: string, page: number) {
		return http.get(`${resource}/${spotId}/experiences/`, {
			params: {
				page,
				page_size: 10,
			},
		})
	},
	getSpotAssets(spotId: string) {
		return http.get(`${resource}/${spotId}/assets/`)
	},
	getSpotList(spotIds: string[]) {
		return http.get(`${resource}/list`, {
			params: {
				spot_ids: spotIds.toString(),
			},
		})
	},
	createExperience({ spotId, form }: { spotId: string; form: any }) {
		return http.post(`${resource}/${spotId}/experiences/`, form)
	},
	editExperience({ experienceId, form }: { experienceId: string; form: any }) {
		return http.patch(`${resource}/${experienceId}/`, form)
	},
	isSaved(spotId: string) {
		return http.get(`${resource}/${spotId}/save/`)
	},

	find(placeId: string) {
		return http.get(`${resource}/find`, {
			params: {
				place_id: placeId,
			},
		})
	},
})
