
































import Vue from 'vue'
import { mapGetters } from 'vuex'
import Oauth from '~/components/context/auth/oauth/index.vue'
import PButton from '~/components/PassporterUI/PButton.vue'
import PIconMail from '~/components/PassporterUI/Icon/PIconMail.vue'

export default Vue.extend({
	name: 'LoginOptions',
	components: { PIconMail, PButton, Oauth },
	props: {
		fullWidth: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
		}),
	},
})
