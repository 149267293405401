




















import Vue, { PropOptions } from 'vue'
import { Marker2 as Marker } from '~/models/Marker'
import Itinerary from '~/models/Itinerary'
import PButton from '~/components/PassporterUI/PButton.vue'
import IconFlag from '~/components/UI/Icons/IconFlag.vue'

export default Vue.extend({
	name: 'ReferenceButton',
	components: {
		PButton,
		IconFlag,
	},
	props: {
		marker: {
			type: Object,
			required: true,
		} as PropOptions<Marker>,
		isOwner: {
			type: Boolean,
			default: false,
		},
		itinerary: {
			type: Object,
			default: null,
		} as PropOptions<Itinerary>,
	},
	methods: {
		async toggleSpotReferenceStatus() {
			try {
				return await this.$store.dispatch('itinerary/addSpotToItinerary', {
					itineraryId: this.itinerary.id,
					spotId: this.marker?.data?.id,
					isReference: !this.marker.isReference,
				})
			} catch (e: any) {}
		},
	},
})
