





































import Vue from 'vue'
import { mapGetters } from 'vuex'
import Row from '~/components/UI/Row.vue'
import Spacer from '~/components/UI/Spacer.vue'
import PIconClose from '~/components/PassporterUI/Icon/PIconClose.vue'
import POverlay from '~/components/PassporterUI/POverlay.vue'

export default Vue.extend({
	name: 'Onboarding',
	components: { Row, Spacer, PIconClose, POverlay },
	props: {
		code: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		description: {
			type: String,
			required: true,
		},
		checkCondition: {
			type: Boolean,
			default: undefined,
		},
		position: {
			type: String,
			default: 'left', // left|right|rop|bottom
		},
		alignment: {
			type: String,
			default: 'start', // start|center|end
		},
		externalOverlay: {
			type: Boolean,
		},
	},
	data() {
		return {
			active: false,
		}
	},
	computed: {
		...mapGetters({
			onboards: 'auth/onboards',
		}),
		viewed(): boolean {
			if (this.checkCondition !== undefined) {
				return this.checkCondition || this.onboards.includes(this.code)
			}
			return this.onboards.includes(this.code) // todo: change for computer cookie
		},
	},
	watch: {
		active(value) {
			this.$emit('update:externalOverlay', value)
		},
	},
	methods: {
		close(): void {
			this.active = false
		},
		noShowAgain(): void {
			this.$store.dispatch('auth/onboardViewed', this.code)
		},
	},
})
