import { SpotEntityMapper } from '../spot/entityMapper'
import { PaginateSpots } from '~/models/Spot'
import { Marker2 as Marker } from '~/models/Marker'
import { SiteEntityMapper } from '~/passporter-services/site/entityMapper'
import Block, { BlockContentType } from '~/models/Block'
import { SiteInfo } from '~/models/SiteInfo'
import { BlockEntityMapper } from '~/passporter-services/block/entityMapper'
import { Site2 as Site } from '~/models/Site'

export default class SiteService {
	repository: any
	constructor(repository: any) {
		this.repository = repository
	}

	async getSpotsSite(siteId: string): Promise<PaginateSpots> {
		const res = await this.repository.getSpotsSite(siteId)
		const { data } = res
		const paginatedSpots = SpotEntityMapper.toPaginatedSpots(data)
		return paginatedSpots
	}

	async obtainBy(idOrSubdomain: string): Promise<Site> {
		const res = await this.repository.getSite(idOrSubdomain)
		const { data } = res
		return SiteEntityMapper.toSite(data)
	}

	async obtainGuidesFromSite(id: string): Promise<Block<BlockContentType>[]> {
		const { data } = await this.repository.getGuides(id)
		const results = data.results
		return BlockEntityMapper.toBlocks(results)
	}

	async obtainMarkers(id: string): Promise<Marker[]> {
		const res = await this.repository.markers(id)
		const { data } = res
		const markers = SpotEntityMapper.toMarkers(data)
		return markers
	}

	async getItineraryBlocks(id: string): Promise<Array<Block<BlockContentType>> | undefined> {
		const res = await this.repository.getItinearyBlocks(id)
		const data = res.data

		const areItems = data.results && data.results.length > 0
		if (!areItems) return

		return BlockEntityMapper.toBlocks(data.results)
	}

	async getDestinationBlocks(id: string): Promise<Block<BlockContentType>[]> {
		const res = await this.repository.getDestinationBlocks(id)
		const { data } = res
		return BlockEntityMapper.toBlocks(data.results)
	}

	async getProductBlocks(id: string): Promise<Block<BlockContentType>[]> {
		const res = await this.repository.getProductBlocks(id)
		const { data } = res
		return BlockEntityMapper.toBlocks(data.results)
	}

	async obtainInfo(id: string): Promise<SiteInfo[]> {
		const response = await this.repository.getInfo(id)
		const { data } = response
		const usefulInfo = data.map((item: any) => {
			return SiteEntityMapper.toInfo(item)
		})

		return usefulInfo
	}
}
