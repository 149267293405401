





























































































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import Chip from '~/components/PassporterUI/Chip.vue'
import PList from '~/components/PassporterUI/List/List.vue'
import PListItem from '~/components/PassporterUI/List/ListItem.vue'
import Divider from '~/components/PassporterUI/Divider.vue'
import PIconChevron from '~/components/PassporterUI/Icon/PIconChevron.vue'
import Dropdown from '~/components/UI/Dropdown.vue'
import Spacer from '~/components/UI/Spacer.vue'

export default Vue.extend({
	name: 'AuthMenu',
	components: {
		Spacer,
		Dropdown,
		PList,
		PListItem,
		PIconChevron,
		Divider,
		Chip,
	},
	computed: {
		...mapGetters({
			user: 'auth/user',
			screenSize: 'screenSize',
		}),
	},
	methods: {
		goToMyProfile() {
			try {
				this.$store.dispatch('profile/openMyProfile')
			} catch (e: any) {}
		},
		goToMyItineraries() {
			try {
				this.$store.dispatch('profile/openMyItineraries')
			} catch (e: any) {}
		},
		logout() {
			try {
				this.$store.dispatch('auth/logout').then(() => {
					this.goToPassporterExperiment()
				})
			} catch (e: any) {}
		},
		goToPassporterExperiment() {
			this.$navigation.home.openNoCodePage()
		},
		download(platform: string) {
			this.$store.dispatch('toStore', {
				platform,
			})
		},
	},
})
