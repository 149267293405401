var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Row',{staticClass:"relative z-0 overflow-hidden",attrs:{"grow":""}},[_c('client-only',[(_vm.screenSize.xs)?_c('Row',{staticClass:"horizontal-scrollbox",attrs:{"grow":""}},[_c('Row',{ref:"horizontalScroll",staticClass:"horizontal-scroll",class:( _obj = {}, _obj[("py-" + _vm.paddingY)] = _vm.paddingY, _obj[("px-" + _vm.paddingX)] = _vm.paddingX, _obj ),attrs:{"justify":_vm.justify}},[_c('Scrollbar',{staticClass:"d-flex"},[_c('Row',{staticClass:"horizontal-scroll-list",class:{ 'align-end': _vm.alignEnd, 'align-center': !_vm.alignEnd },style:({
							gap: (_vm.gapPixels + "px"),
							paddingLeft: ((_vm.spacing * 4) + "px"),
							paddingRight: ((_vm.spacing * 4) + "px"),
						}),attrs:{"cols":0,"height":"100%"}},[_vm._t("default")],2)],1)],1)],1):_c('Row',{staticClass:"horizontal-scrollbox",class:{ 'outside-buttons': _vm.outsideButtons },attrs:{"grow":_vm.grow,"justify":_vm.justify},on:{"mouseenter":function($event){_vm.hovering = true},"mouseleave":function($event){_vm.hovering = false}}},[_vm._t("leftArrow",function(){
						var _obj;
return [_c('transition',{attrs:{"name":"fade"}},[(_vm.showLeftArrow)?_c('div',{staticClass:"horizontal-scroll-btn horizontal-scroll-btn-prev",class:( _obj = {}, _obj[("py-" + _vm.paddingY)] = _vm.paddingY, _obj[("px-" + _vm.paddingX)] = _vm.paddingX, _obj )},[_c('Col',{staticClass:"horizontal-scroll-btn-inner",class:{
								'bg-neutral-25': _vm.small,
								'horizontal-scroll-btn-small': _vm.small,
							},attrs:{"justify":"center","height":"100%"},on:{"click":_vm.scrollBack}},[_c('Row',{attrs:{"justify":"center"}},[(_vm.small)?_c('PIconChevron',{staticClass:"text-neutral-900",attrs:{"previous":"","width":"14","height":"14"}}):_c('Row',{staticClass:"bg-neutral-25 rounded-full",attrs:{"justify":"center","align":"center","height":"40px","width":"40px"}},[_c('PIconChevron',{staticClass:"text-neutral-900",attrs:{"previous":""}})],1)],1)],1)],1):_vm._e()])]},null,{ showLeftArrow: _vm.showLeftArrow, scrollBack: _vm.scrollBack }),_vm._v(" "),_c('Container',{ref:"horizontalScroll",staticClass:"horizontal-scroll overflow-hidden",class:( _obj$1 = {}, _obj$1[("py-" + _vm.paddingY)] = _vm.paddingY, _obj$1[("px-" + _vm.paddingX)] = _vm.paddingX, _obj$1 ),attrs:{"height":"100%"}},[_c('Row',{staticClass:"horizontal-scroll-list",class:{ 'align-end': _vm.alignEnd, 'align-center': !_vm.alignEnd },style:({
						gap: (_vm.gapPixels + "px"),
						paddingLeft: ((_vm.spacing * 4) + "px"),
						paddingRight: ((_vm.spacing * 4) + "px"),
					}),attrs:{"height":"100%"}},[_vm._t("default")],2)],1),_vm._v(" "),_vm._t("rightArrow",function(){
					var _obj;
return [_c('transition',{attrs:{"name":"fade"}},[(_vm.showRightArrow)?_c('div',{staticClass:"horizontal-scroll-btn horizontal-scroll-btn-next",class:( _obj = {}, _obj[("py-" + _vm.paddingY)] = _vm.paddingY, _obj['horizontal-scroll-btn-small'] =  _vm.small, _obj )},[_c('Col',{staticClass:"horizontal-scroll-btn-inner",class:{
								'bg-neutral-25': _vm.small,
							},attrs:{"justify":"center","height":"100%"},on:{"click":_vm.scrollForward}},[_c('Row',{attrs:{"justify":"center"}},[(_vm.small)?_c('PIconChevron',{staticClass:"text-neutral-900",attrs:{"next":"","width":"14","height":"14"}}):_c('Row',{staticClass:"bg-neutral-25 rounded-full",attrs:{"justify":"center","align":"center","height":"40px","width":"40px"}},[_c('PIconChevron',{staticClass:"text-neutral-900",attrs:{"next":""}})],1)],1)],1)],1):_vm._e()])]},null,{ showRightArrow: _vm.showRightArrow, scrollForward: _vm.scrollForward })],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }