interface BookMarkData {
	link: string
	cover: string
	title: string
}

export default class BookMark implements BookMarkData {
	private readonly _link
	private readonly _cover
	private readonly _title

	constructor(bookMark: BookMarkData) {
		this._link = bookMark.link
		this._cover = bookMark.cover
		this._title = bookMark.title
	}

	get link(): string {
		return this._link
	}

	get domain(): string {
		try {
			const _domain = new URL(this._link)
			return _domain?.hostname
		} catch (e) {
			return e as unknown as string
		}
	}

	get cover(): string {
		return this._cover
	}

	get title(): string {
		return this._title
	}

	toJSON(): BookMarkData {
		return {
			link: this.link,
			cover: this.cover,
			title: this.title,
		}
	}
}
