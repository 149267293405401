













import Vue, { PropOptions } from 'vue'
import { SiteInfo } from '~/models/SiteInfo'

export default Vue.extend({
	name: 'MicrositeInfoDialogContent',
	props: {
		info: {
			type: Object,
			default: () => {},
		} as PropOptions<SiteInfo>,
	},
})
