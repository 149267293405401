import { RegisteredUser, UserAuthTokenApi } from './types'
import { UserAuthToken } from '~/models/AuthToken'
import TokenService from '~/passporter-services/shared/token'

export class AuthEntityMapper {
	static obtainTokenInfo(data: UserAuthTokenApi): UserAuthToken {
		const { access, refresh } = data
		const token = TokenService.getTokenInfo(access!.split('.')[1])
		return { access, refresh, id: token.id, exp: token.exp }
	}

	static obtainRefreshedToken(data: any): UserAuthTokenApi {
		const { results } = data
		const { access, refresh } = results
		return { access, refresh }
	}

	static obtainRegisteredUser(data: any): RegisteredUser {
		return { id: data.id }
	}

	static obtainAvailability(data: any): boolean {
		return data.available
	}
}
