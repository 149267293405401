















import Vue from 'vue'
import { mapGetters } from 'vuex'
import PButton from '~/components/PassporterUI/PButton.vue'
export default Vue.extend({
	name: 'ErrorMessage',
	components: { PButton },
	props: {
		title: {
			type: String,
			default: 'Error title',
		},
		message: {
			type: String,
			default: undefined,
		},
		actionText: {
			type: String,
			default: 'Submit',
		},
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
		}),
	},
})
