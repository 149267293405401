import Profile from '~/models/Profile'

export class ProfileEntityMapper {
	static toProfile = (traveler: any) => {
		return new Profile({
			id: traveler.id,
			name: traveler.name,
			username: traveler.username,
			cover: traveler.cover_url,
			avatar: traveler.picture_url,
			email: traveler.email,
			location: traveler.location_name,
		})
	}

	static toProfiles = (profiles: any[]) => {
		return profiles.map((profile: any) => this.toProfile(profile))
	}
}
