























































import Vue, { PropOptions } from 'vue'
import ResponsiveData from '~/models/Responsive'
import PIconChevron from '~/components/PassporterUI/Icon/PIconChevron.vue'
import PButton from '~/components/PassporterUI/PButton.vue'
import ProviderAvatar from '~/components/PassporterUI/ImageViewer/ProviderAvatar.vue'
import ImageDotsMobile from '~/components/PassporterUI/ImageViewer/ImageDotsMobile.vue'
import Media, { MediaProviderName } from '~/models/Media'

export default Vue.extend({
	name: 'PImageViewer',
	components: { PButton, PIconChevron, ProviderAvatar, ImageDotsMobile },
	props: {
		screenSize: {
			type: Object,
			default: undefined,
		} as PropOptions<ResponsiveData>,
	},
	data() {
		return {
			currentIndex: 0,
			touchStartX: null as number | null,
			images: [] as Media[],
		}
	},
	computed: {
		currentImage(): Media {
			return this.images[this.currentIndex]
		},
		isLastImage(): boolean {
			return this.currentIndex === this.images.length - 1
		},
		isFirstImage(): boolean {
			return this.currentIndex === 0
		},
		onlyOneImage(): boolean {
			return this.images.length === 1
		},
	},
	methods: {
		open(items: Media[]): void {
			this.images = items
		},
		close(): void {
			this.images = []
			this.currentIndex = 0
		},
		nextImage(): void {
			if (this.currentIndex < this.images.length - 1) {
				this.currentIndex++
			}
		},
		previousImage(): void {
			if (this.currentIndex > 0) {
				this.currentIndex--
			}
		},
		obtainProviderAvatar(): string {
			const provider = this.currentImage.provider?.name as MediaProviderName
			const result = {
				pinterest: require('~/components/PassporterUI/Logos/pinterest.svg'),
				places: require('~/components/PassporterUI/Logos/googleMaps.svg'),
				passporter: this.currentImage.provider?.authorPicture || '',
			}
			const see = result[provider]
			return see
		},
		obtainProviderName(): string {
			const provider = this.currentImage.provider?.name as MediaProviderName

			const result = {
				pinterest: 'Pinterest',
				places: 'Google Maps',
				passporter: this.currentImage.provider?.authorName || '',
			}
			return result[provider]
		},
		onTouchStart(event: TouchEvent) {
			this.touchStartX = event.touches[0].clientX
		},
		onTouchMove(event: TouchEvent) {
			if (this.touchStartX === null) return

			const touchMoveX = event.touches[0].clientX
			const diff = touchMoveX - this.touchStartX

			const quantityToConsiderMovement = 50
			const move = Math.abs(diff) > quantityToConsiderMovement
			if (move) {
				if (diff > 0) {
					this.previousImage()
				} else {
					this.nextImage()
				}
				this.touchStartX = null
			}
		},
		onTouchEnd() {
			this.touchStartX = null
		},
	},
})
