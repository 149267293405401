export interface AgencyData {
	id: string
	logo: string
	name: string
}
export default class Agency implements AgencyData {
	private readonly _id
	private readonly _logo
	private readonly _name
	constructor(agency: AgencyData) {
		this._id = agency.id
		this._logo = agency.logo
		this._name = agency.name
	}

	get id(): string {
		return this._id
	}

	get logo(): string {
		return this._logo
	}

	get name(): string {
		return this._name
	}

	toJSON(): AgencyData {
		return {
			id: this.id,
			logo: this.logo,
			name: this.name,
		}
	}
}
