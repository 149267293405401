export interface MediaData {
	id: string
	created_at: string
	metadata: {
		file_name: string
		id?: number
		mime_type: string
		promoted?: boolean
	}
	name: string
	ownerId: string
	size: number
	state: string
	type: string
	url: string
	pending: boolean
	provider?: MediaProvider
}

export interface MediaProvider {
	name: MediaProviderName
	authorProfileUrl: string
	authorName?: string
	authorPicture?: string
}

export type MediaProviderName = 'pinterest' | 'places' | 'passporter'

export default class Media implements MediaData {
	id
	created_at
	metadata
	name
	ownerId
	size
	state
	type
	url
	pending
	provider

	constructor(media: MediaData) {
		this.created_at = media.created_at
		this.id = media.id
		this.metadata = media.metadata
		this.name = media.name
		this.ownerId = media.ownerId
		this.size = media.size
		this.state = media.state
		this.type = media.type
		this.url = media.url
		this.pending = media.pending
		this.provider = media.provider
	}

	toJSON() {
		return {
			id: this.id,
			created_at: this.created_at,
			metadata: this.metadata,
			name: this.name,
			ownerId: this.ownerId,
			size: this.size,
			state: this.state,
			type: this.type,
			url: this.url,
			pending: this.pending,
			provider: this.provider,
		}
	}
}
