import Traveler from '~/models/Traveler'
import BaseService from '~/services/BaseService'

export default class TravelerService extends BaseService {
	async obtainBy(id: string): Promise<Traveler | undefined> {
		const res = await this.repositories.profile.user(id)
		const { status, data } = res
		if (status === 200) {
			return this.apiModel.traveler.parseTraveler(data)
		}
	}
}
