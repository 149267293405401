import BaseService from './BaseService'
import Guide from '~/models/Guide'
import { Category } from '~/models/Category'

export default class CategoryService extends BaseService {
	async obtainCategories(siteId: string): Promise<Category[]> {
		const res = await this.repositories.site.getGuideCategories(siteId)
		const { data } = res

		return this.apiModel.category.parseCategories(data, this.apiModel)
	}

	async obtainGuidesBy(siteId: string, categoryId?: string): Promise<Guide[]> {
		const res = await this.repositories.site.getGuideBlocksByCategory(siteId, categoryId)
		const { data } = res
		return this.apiModel.guide.parseGuides(data.results, this.apiModel)
	}
}
