// @ts-ignore
window.fbAsyncInit = function () {
	// eslint-disable-next-line no-undef
	FB.init({
		appId: process.env.OAUTH_FACEBOOK_APP_ID,
		autoLogAppEvents: true,
		xfbml: true,
		version: 'v10.0',
	})
}
