


























import Vue from 'vue'

export default Vue.extend({
	name: 'CircleProgressBar',
	props: {
		percent: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			fullDuration: 1000,
			fromPercent: 0,
		}
	},
	computed: {
		fromDegrees(): number {
			return this.percentToDegrees(this.fromPercent)
		},
		degrees(): number {
			return this.percentToDegrees(this.percent)
		},
		forwardRight(): string {
			const sec = this.fromDegrees < 180 ? 0 : parseInt(this.progressDurationLeft)
			return `${sec}ms`
		},
		fromDegreesRight(): string {
			const deg = this.fromDegrees < 180 ? this.fromDegrees : 180
			return `${deg}deg`
		},
		degreesRight(): string {
			const deg = this.degrees < 180 ? this.degrees : 180
			return `${deg}deg`
		},
		forwardLeft(): string {
			const sec = this.fromDegrees < 180 ? parseInt(this.progressDurationRight) : 0
			return `${sec}ms`
		},
		fromDegreesLeft(): string {
			const deg = this.fromDegrees < 180 ? 180 : this.fromDegrees
			return `${deg}deg`
		},
		degreesLeft(): string {
			const deg = this.degrees < 180 ? 180 : this.degrees
			return `${deg}deg`
		},
		forwardEndLeft(): string {
			const sec = parseInt(this.forwardLeft) + parseInt(this.progressDurationLeft)
			return `${sec}ms`
		},
		layerStartLeft(): number {
			return this.fromDegrees > 180 || this.degrees > 180 ? 3 : 0
		},
		layerEndLeft(): number {
			return this.degrees > 180 ? 3 : 0
		},
		progressDurationLeft(): string {
			const startDegree = parseInt(this.fromDegreesLeft)
			const endDegree = parseInt(this.degreesLeft)
			return this.degreesToDuration(startDegree, endDegree)
		},
		progressDurationRight(): string {
			const startDegree = parseInt(this.fromDegreesRight)
			const endDegree = parseInt(this.degreesRight)
			return this.degreesToDuration(startDegree, endDegree)
		},
	},
	watch: {
		percent({}, oldValue) {
			this.fromPercent = oldValue
		},
	},
	methods: {
		percentToDegrees(percent: number) {
			return (percent * 360) / 100
		},
		degreesToDuration(startDegree: number, endDegree: number): string {
			const halfDuration: number = this.fullDuration / 2
			const degrees: number = Math.abs(endDegree - startDegree)
			const ms = (degrees * halfDuration) / 100
			return `${ms}ms`
		},
	},
})
