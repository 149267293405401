interface DestinationCategoryData {
	id?: string | null
	name: string
}

export default class DestinationCategory {
	private readonly _id
	private readonly _name
	constructor(
		destinationCategory: DestinationCategoryData | DestinationCategory
	) {
		this._id = destinationCategory.id || null
		this._name = destinationCategory.name
	}

	get id(): string | null {
		return this._id
	}

	get name(): string {
		return this._name
	}

	toJSON(): DestinationCategoryData {
		return {
			id: this._id,
			name: this._name,
		}
	}
}
