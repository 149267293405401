




































import Vue, { PropOptions } from 'vue'
import { mapGetters } from 'vuex'
import Chip from '~/components/PassporterUI/Chip.vue'
import HorizontalScroll from '~/components/UI/HorizontalScroll.vue'

export interface ChipData {
	id: string
	name: string
	icon?: string
	fullscreen?: boolean
	color?: string
	textColor?: string
	outlinedColor?: string
	selectedColor?: string
	selectedOutlined?: boolean
	selectedOutlinedColor?: string
	overHoverColor?: string
	selectedTextColor?: string
}

export default Vue.extend({
	name: 'ChipGroup',
	components: { Chip, HorizontalScroll },
	props: {
		value: {
			type: String,
			default: undefined,
		},
		selectedBold: {
			type: Boolean,
			default: false,
		},
		mandatory: {
			type: Boolean,
			default: false,
		},
		maxContent: {
			type: Boolean,
			default: false,
		},
		isHoverBackground: {
			type: Boolean,
			default: false,
		},
		isHoverBorder: {
			type: Boolean,
			default: false,
		},
		isActiveBackground: {
			type: Boolean,
			default: false,
		},
		isActive: {
			type: Boolean,
			default: false,
		},
		roundedLg: {
			type: Boolean,
			default: false,
		},
		options: {
			type: Array,
			default: () => [],
		} as PropOptions<ChipData[]>,
		color: {
			type: String,
			default: undefined,
		},
		textColor: {
			type: String,
			default: undefined,
		},
		selectedColor: {
			type: String,
			default: undefined,
		},
		selectedTextColor: {
			type: String,
			default: undefined,
		},
		borderHoverColor: {
			type: String,
			default: undefined,
		},
		outlined: {
			type: Boolean,
			default: false,
		},
		outlinedColor: {
			type: String,
			default: undefined,
		},
		selectedOutlined: {
			type: Boolean,
			default: false,
		},
		selectedOutlinedColor: {
			type: String,
			default: undefined,
		},
		small: {
			type: Boolean,
			default: false,
		},
		large: {
			type: Boolean,
			default: false,
		},
		xLarge: {
			type: Boolean,
			default: false,
		},
		justify: {
			type: String,
			default: undefined,
		},
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
		}),
		localValue: {
			get(): string | null {
				return this.value
			},
			set(newValue: string | null) {
				this.$emit('input', newValue)
			},
		},
	},
	created() {
		if (this.mandatory) {
			this.localValue = this.localValue || this.options?.at(0)?.id || null
		}
	},
	methods: {
		searchOption(id: string | null, index: number) {
			this.localValue = id
			this.$emit('change', id, index)
			this.$emit('changeSendIndex', index)
		},
	},
})
