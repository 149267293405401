

































import Vue from 'vue'
import Row from '~/components/UI/Row.vue'

export default Vue.extend({
	name: 'IconCamera',
	components: { Row },
	props: {
		width: {
			type: [Number, String],
			default: 24,
		},
		height: {
			type: [Number, String],
			default: 24,
		},
		strokeWidth: {
			type: Number,
			default: 2,
		},
	},
})
