import Traveler from './Traveler'
import Marker, { Marker2 } from './Marker'
import { serialize } from './helpers/serialize'
import Spot, { Spot2 } from './Spot'

export interface InspirationSource {
	name: string
	url: string
	logo?: string
}

export interface Guide2 {
	id: string
	title: string
	subtitle: string
	description: string
	state: StateData
	spots?: Spot2[]
	spotsNextPage?: number
	spotsNumber?: number
	markers?: Marker2[]
	destinations: number
	travelers: number
	notes: number
	views: number
	covers: string[]
	creatorId: string
	creator?: Traveler | null
	travelersPictures: string[]
	inspirationSources?: InspirationSource[]
}

export interface GuideData {
	id: string
	title: string
	subtitle: string
	description: string
	state: StateData
	spots?: Spot[]
	spotsNextPage?: number
	spotsNumber?: number
	markers?: Marker[]
	destinations: number
	travelers: number
	notes: number
	views: number
	covers: string[]
	creatorId: string
	creator?: Traveler | null
	travelersPictures: string[]
	inspirationSources?: InspirationSource[]
}
export enum StateData {
	public = 'public',
	private = 'private',
}
export default class Guide implements GuideData {
	private readonly _id
	private _title
	private _subtitle
	private _description
	private _state
	private _spots
	private _spotsNextPage
	private _spotsNumber
	private _markers
	private _destinations
	private _travelers
	private _notes
	private _views
	private _covers
	private _creatorId
	private _creator
	private _travelersPictures
	private _inspirationSources

	constructor(guide: GuideData) {
		this._id = guide.id
		this._title = guide.title
		this._subtitle = guide.subtitle
		this._description = guide.description
		this._state = guide.state
		this._spots = serialize(Spot, guide.spots)
		this._spotsNextPage = guide.spotsNextPage
		this._spotsNumber = guide.spotsNumber
		this._markers = serialize(Marker, guide?.markers)
		this._destinations = guide.destinations
		this._travelers = guide.travelers
		this._notes = guide.notes
		this._views = guide.views
		this._covers = guide.covers
		this._creatorId = guide.creatorId
		this._creator = serialize(Traveler, guide.creator) || null
		this._travelersPictures = guide.travelersPictures
		this._inspirationSources = guide.inspirationSources
	}

	get id(): string {
		return this._id
	}

	get title(): string {
		return this._title
	}

	get subtitle(): string {
		return this._subtitle
	}

	get description(): string {
		return this._description
	}

	get state(): StateData {
		return this._state
	}

	get spots(): Spot[] | undefined {
		return this._spots
	}
	set spots(spots: Spot[] | undefined) {
		this._spots = spots
	}

	get spotsNextPage(): number | undefined {
		return this._spotsNextPage
	}

	set spotsNextPage(next: number | undefined) {
		this._spotsNextPage = next
	}

	get spotsNumber(): number | undefined {
		return this._spotsNumber
	}

	get markers(): Marker[] {
		return this._markers
	}

	set markers(markers: Marker[]) {
		this._markers = markers
	}

	get destinations(): number {
		return this._destinations
	}

	get travelers(): number {
		return this._travelers
	}

	get notes(): number {
		return this._notes
	}

	get views(): number {
		return this._views
	}

	get covers(): string[] {
		return this._covers
	}

	get creatorId(): string {
		return this._creatorId
	}

	get creator(): Traveler | null {
		return this._creator
	}

	set creator(creator: Traveler | null) {
		this._creator = creator
	}

	get travelersPictures(): string[] {
		return this._travelersPictures
	}

	get inspirationSources(): InspirationSource[] | undefined {
		return this._inspirationSources
	}

	toJSON(): GuideData {
		return {
			id: this.id,
			title: this.title,
			subtitle: this.subtitle,
			description: this.description,
			state: this.state,
			spots: this.spots,
			spotsNextPage: this.spotsNextPage,
			spotsNumber: this.spotsNumber,
			markers: this.markers,
			destinations: this.destinations,
			travelers: this.travelers,
			notes: this.notes,
			views: this.views,
			covers: this.covers,
			creatorId: this.creatorId,
			creator: this.creator,
			travelersPictures: this.travelersPictures,
			inspirationSources: this.inspirationSources,
		}
	}
}
