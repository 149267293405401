import { Plugin } from '@nuxt/types'
import createRepository from '~/repositories/Repository'

declare module 'vue/types/vue' {
	interface Vue {
		$repositories: ReturnType<typeof createRepository>
	}
}
declare module '@nuxt/types' {
	interface NuxtAppOptions {
		$repositories: ReturnType<typeof createRepository>
	}
	interface Context {
		$repositories: ReturnType<typeof createRepository>
	}
}
declare module 'vuex/types/index' {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	interface Store<S> {
		$repositories: ReturnType<typeof createRepository>
	}
}

const myPlugin: Plugin = (context, inject) => {
	inject('repositories', createRepository(context.$axios))
}

export default myPlugin
