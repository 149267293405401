const resource = 'v1/discovery'
export default (http: any) => ({
	getDestinationBlocks(page = 0) {
		return http.get(`${resource}/`, {
			params: {
				page,
				page_size: 10,
				type: 'destination',
			},
		})
	},
	getHomeBlocks(page = 0) {
		return http.get(`${resource}/`, {
			params: {
				page,
				page_size: 10,
			},
		})
	},
	getBlock(id: string) {
		return http.get(`${resource}/${id}/info/`)
	},
	getBlockContent(id: string, page?: number) {
		return http.get(`${resource}/${id}/`, {
			params: {
				page,
				page_size: 10,
			},
		})
	},
	getTopSpots(page?: number) {
		return http.get(`${resource}/spots/`, {
			params: {
				page,
				page_size: 10,
			},
		})
	},
	getCustomSpots(page?: number) {
		return http.get(`${resource}/spots/custom/`, {
			params: {
				page,
				page_size: 10,
			},
		})
	},
})
