import BaseService from '~/services/BaseService'

export default class LeadService extends BaseService {
	async createLead(destinationId: string, title: string, email: string): Promise<string> {
		const res = await this.repositories.lead.createLead({
			destination_id: destinationId,
			title,
			email,
			spots_ids: [],
			source: 'blog',
		})
		const { data } = res

		return data.id
	}
}
