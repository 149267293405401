import { Context } from '@nuxt/types'

export default (context: Context) => ({
	getItineraryDestination(itineraryId: string, destinationId: string): string {
		return `/${context.i18n.locale}/itineraries/${itineraryId}/destinations/${destinationId}`
	},
	getDestination(destinationId: string): string {
		return `/${context.i18n.locale}/destinations/${destinationId}`
	},
	getLandmark(destinationId: string, landmarkId: string): string {
		return `/${context.i18n.locale}/destinations/${destinationId}/landmark/${landmarkId}`
	},
	getDestinationMap(destinationId: string): string {
		return `/${context.i18n.locale}/destinations/${destinationId}/map`
	},
	getItineraryDestinationMap(itineraryId: string, destinationId: string): string {
		return `/${context.i18n.locale}/itineraries/${itineraryId}/destinations/${destinationId}/map`
	},
})
