import Spot, { Spot2 } from './Spot'
import PlannerSpot from './PlannerSpot'
import CivitatisActivity from './CivitatisActivity'
import { serialize } from './helpers/serialize'

interface MarkerData {
	id?: string | null
	color?: string
	icon?: string
	isReference?: boolean
	location: {
		latitude: number
		longitude: number
	}
	data?: Spot | PlannerSpot | CivitatisActivity
	isChecked?: boolean
}

export type Marker2 = {
	id?: string | null
	color?: string
	icon?: string
	isReference?: boolean
	location: {
		latitude: number
		longitude: number
	}
	data?: Spot2 | PlannerSpot | CivitatisActivity
	isChecked?: boolean
}

export default class Marker implements MarkerData {
	private readonly _id
	private _icon
	private _color
	private readonly _isReference
	private readonly _location
	private _data
	private _isChecked

	constructor(marker: MarkerData) {
		this._id = marker.id
		this._color = marker.color
		this._icon = marker.icon
		this._location = marker.location
		this._data = this._getSerialize(marker.data)
		this._isReference = marker.isReference || false
		this._isChecked = marker.isChecked || false
	}

	get id(): string | undefined | null {
		return this._id
	}

	get icon(): string | undefined {
		return this._icon
	}

	set icon(icon: string | undefined) {
		this._icon = icon
	}

	get isChecked(): boolean {
		return this._isChecked
	}

	set isChecked(isChecked: boolean) {
		this._isChecked = isChecked || false
	}

	get isReference(): boolean {
		return this._isReference
	}

	get color(): string | undefined {
		return this._color
	}

	get location() {
		return this._location
	}

	get data(): Spot | PlannerSpot | CivitatisActivity | undefined {
		return this._data
	}

	set data(data) {
		this._data = data
	}

	private _getSerialize(data?: Spot | PlannerSpot | CivitatisActivity) {
		if (!data) return
		let result
		if (data.referenceType === 'spot') {
			result = serialize(Spot, data)
		}
		if (data.referenceType === 'travel-spot') {
			result = serialize(PlannerSpot, data)
		}
		if (data.referenceType === 'activity') {
			result = serialize(CivitatisActivity, data)
		}
		return result
	}

	toJSON(): MarkerData {
		return {
			id: this.id,
			icon: this.icon,
			isReference: this.isReference,
			isChecked: this.isChecked,
			color: this.color,
			location: this.location,
			data: this.data,
		}
	}
}
