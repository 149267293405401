import { Destination2 as Destination } from '~/models/Destination'
import BaseService from '~/services/BaseService'
import { SpotEntityMapper } from '~/passporter-services/spot/entityMapper'
import { DestinationMapperEntity } from '~/passporter-services/destination/toEntity'
import { Marker2 as Marker } from '~/models/Marker'

export default class DestinationService extends BaseService {
	async obtainDestination(id: string): Promise<Destination | undefined> {
		try {
			const res = await this.repositories.destination.getDestination(id)
			const { data } = res
			const user = DestinationMapperEntity.toDestination(data)
			return user
		} catch {
			return undefined
		}
	}

	async getDestinationMarkers(id: string): Promise<Marker[]> {
		const res = await this.repositories.destination.markers(id)
		const { data } = res
		const markers = SpotEntityMapper.toMarkers(data)
		return markers
	}
}
