import Currency from './Currency'

export default class Price {
	value: number
	currency: Currency
	constructor(price: Price) {
		this.value = price.value
		this.currency = price.currency
	}

	toLocaleString(locale: string, opts?: Intl.NumberFormatOptions) {
		return new Intl.NumberFormat(locale, {
			style: 'currency',
			currency: this.currency.code,
			...opts,
		}).format(this.value)
	}
}
