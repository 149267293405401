

































import Vue from 'vue'
import Row from '~/components/UI/Row.vue'

export default Vue.extend({
	name: 'IconBookmark',
	components: { Row },
	props: {
		outlined: {
			type: Boolean,
			default: false,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		width: {
			type: [Number, String],
			default: 14,
		},
		height: {
			type: [Number, String],
			default: 17,
		},
		bgColor: {
			type: String,
			default: undefined,
		},
		strokeColor: {
			type: String,
			default: undefined,
		},
		strokeWidth: {
			type: Number,
			default: 2,
		},
	},
	computed: {
		color(): string {
			if (this.bgColor?.charAt(0) === '#') {
				return this.bgColor
			}
			return 'currentColor'
		},
		stroke(): string {
			if (this.strokeColor?.charAt(0) === '#') {
				return this.strokeColor
			}
			return 'currentColor'
		},
	},
})
