import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { RootState } from '~/store/index'

const initState = () => ({
	activeExperiments: [] as string[],
})
export const state = initState
export type ExperimentsModuleState = ReturnType<typeof state>

export const mutations: MutationTree<ExperimentsModuleState> = {
	setExperiments(state, experiments: string[]) {
		state.activeExperiments = experiments
	},
}

export const actions: ActionTree<ExperimentsModuleState, RootState> = {
	setActiveExperiments({ commit }, experiments: string[]) {
		commit(
			'setExperiments',
			experiments?.map((exp) => exp.slice(4))
		)
	},
}
export const getters: GetterTree<ExperimentsModuleState, RootState> = {
	experiments(state) {
		return state.activeExperiments
	},
}
