




















































































import Vue from 'vue'

export default Vue.extend({
	name: 'ItineraryMarkers',
	props: {
		check: {
			type: Boolean,
			default: false,
		},
		reference: {
			type: Boolean,
			default: false,
		},
		bgColor: {
			type: String,
			default: undefined,
		},
	},
	computed: {
		color(): string {
			if (this.bgColor?.charAt(0) === '#') {
				return this.bgColor
			}
			return '#19855F'
		},
	},
})
