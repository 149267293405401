import Block, { BlockContentType } from '~/models/Block'
import { ApiSpot } from '~/passporter-services/spot/apiTypes'

const resource = 'v1/sites'

export default (http: any) => ({
	async getSite(id: string) {
		return await http.get(`${resource}/${id}/`)
	},

	async getMedias(id: string): Promise<any> {
		const endpoint = `${resource}/${id}/medias/`
		return await http.get(`${endpoint}`, {
			params: {
				page: 0,
			},
		})
	},

	async getGuides(id: string): Promise<any> {
		const endpoint = `${resource}/${id}/blocks/`
		return await http.get(`${endpoint}`, {
			params: {
				page: 0,
				page_size: 10,
				type: 'guide',
			},
		})
	},

	async getGuideCategories(destinationId: string) {
		return await http.get(`${resource}/${destinationId}/categories/`, {
			params: {
				entity: 'guide',
			},
		})
	},
	async getGuideBlocksByCategory(siteId: string, categoryId?: string): Promise<any> {
		const endpoint = `${resource}/${siteId}/guides/`
		const params: Record<string, string | number> = {
			page: 0,
			page_size: 10,
		}
		if (categoryId) params.category_id = categoryId

		return await http.get(`${endpoint}`, {
			params,
		})
	},

	async getItinearyBlocks(id: string): Promise<any> {
		const endpoint = `${resource}/${id}/blocks/`
		return await http.get(`${endpoint}`, {
			params: {
				page: 0,
				page_size: 10,
				type: 'itinerary',
			},
		})
	},

	async getProductBlocks(id: string): Promise<any> {
		const endpoint = `${resource}/${id}/blocks/`
		return await http.get(`${endpoint}`, {
			params: {
				page: 0,
				page_size: 10,
				type: 'product',
			},
		})
	},
	getDestinationBlocks(id: string): Promise<Block<BlockContentType>[]> {
		const endpoint = `${resource}/${id}/blocks/`

		return http.get(`${endpoint}`, {
			params: {
				page: 0,
				page_size: 10,
				type: 'destination',
			},
		})
	},
	async getInfo(id: string): Promise<any> {
		const endpoint = `${resource}/${id}/info/`
		const result = await http.get(`${endpoint}`)
		return result
	},

	async getSpotsSite(siteId: string): Promise<ApiSpot> {
		const endpoint = `${resource}/${siteId}/spots/`
		return await http.get(`${endpoint}`, {
			params: {
				page: 0,
				page_size: 10,
			},
		})
	},
	markers(siteId: string): Promise<any> {
		return http.get(`${resource}/${siteId}/markers/`)
	},
})
