






































import Vue from 'vue'
import DatePicker from 'vue2-datepicker'
import InputText from '~/components/PassporterUI/InputText.vue'
import IconCalendar from '~/components/UI/Icons/IconCalendar.vue'
import IconClock from '~/components/UI/Icons/IconClock.vue'
import Calendar from '~/passporter-services/shared/calendar'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/es'
import 'vue2-datepicker/locale/de'
import 'vue2-datepicker/locale/en'
import 'vue2-datepicker/locale/fr'
import 'vue2-datepicker/locale/it'

export default Vue.extend({
	name: 'InputDateTime',
	components: { DatePicker, InputText, IconCalendar, IconClock },
	props: {
		name: {
			type: String,
			default: undefined,
		},
		value: {
			type: [String, Array, Date],
			default: undefined,
		},
		hasIcon: {
			type: Boolean,
			default: false,
		},
		label: {
			type: String,
			default: undefined,
		},
		type: {
			type: String,
			default: 'datetime',
		},
		placeholder: {
			type: String,
			default: undefined,
		},
		error: {
			type: String,
			default: undefined,
		},
		min: {
			type: [String, Date],
			default: undefined,
		},
		max: {
			type: [String, Date],
			default: undefined,
		},
		typeValue: {
			type: String,
			default: undefined,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		range: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		dateTimeFormatted: {
			get(): string | undefined {
				if (!this.datetime) return this.datetime
				let result = this.formatDateTime(this.datetime as string, this.value as string)
				if (this.range) {
					result = this.formatRangeDateTime(this.datetime as string[], this.value as string[])
				}
				return result
			},
			set(newValue: string) {
				this.datetime = newValue
			},
		},
		datetime: {
			get(): string | undefined | string[] {
				if (!this.value) return undefined
				let _response
				if (this.range) {
					_response = [
						this.localeISOtoValue((this.value as string[])[0]),
						this.localeISOtoValue((this.value as string[])[1]),
					] as string[]
				} else {
					_response = this.localeISOtoValue(this.value as string)
				}
				return _response
			},
			set(newValue: string | undefined | string[]) {
				if (!newValue) {
					this.$emit('input', undefined)
					return
				}
				let _formattedDate
				if (this.range) {
					_formattedDate = [
						this.valueToLocaleISO((newValue as string[])[0], (this.value as string[])?.[0]),
						this.valueToLocaleISO((newValue as string[])[1], (this.value as string[])?.[1]),
					]
				} else {
					_formattedDate = this.valueToLocaleISO(newValue as string, this.value as string)
				}
				this.$emit('input', _formattedDate)
			},
		},
	},
	methods: {
		open(): void {
			const picker = this.$refs.datepicker as any
			if (picker) {
				picker.$el.firstChild.click()
			}
		},
		formatDateTime(datetime: string, value: string): string {
			const calendar = new Calendar(this.$i18n.locale)
			let result = calendar.formatDateOnly(datetime)
			if (this.type === 'datetime') {
				result = calendar.formatDateTime(datetime)
			} else if (this.type === 'time') {
				result = calendar.formatTime(datetime, value)
			}
			return result
		},
		formatRangeDateTime(datetime: string[], value: string[]): string {
			const startDateTime = this.formatDateTime(datetime[0], value[0])
			const endDateTime = this.formatDateTime(datetime[1], value[1])
			return `${startDateTime} ~ ${endDateTime}`
		},
		notInRange(selectedDate: Date): boolean {
			const start = new Date(this.min)
			const end = new Date(this.max)
			return selectedDate < start || selectedDate > end
		},
		localeIsoString(date: Date | string): string {
			const dateObj = new Date(date)
			if (isNaN(dateObj.getTime())) {
				throw new TypeError('Invalid date')
			}
			const localOffset = dateObj.getTimezoneOffset()
			const sign = localOffset > 0 ? '-' : '+'
			const absOffset = Math.abs(localOffset)
			const offsetHours = String(Math.floor(absOffset / 60)).padStart(2, '0')
			const offsetMinutes = String(absOffset % 60).padStart(2, '0')
			const offsetString = `${sign}${offsetHours}:${offsetMinutes}`
			return new Date(dateObj.getTime() - localOffset * 60000)
				.toISOString()
				.replace('Z', offsetString)
		},
		valueToLocaleISO(newValue: string, value?: string): string | undefined {
			let _formattedDate
			let _date = value ? new Date(value) : new Date()
			if (this.type === 'datetime') {
				_date = new Date(newValue)
				_formattedDate = this.localeIsoString(this.localeIsoString(_date.toISOString()))
			}
			if (this.type === 'time') {
				const _newDate = new Date(`${new Date().toDateString()} ${newValue}`)
				_date.setHours(_newDate.getHours())
				_date.setMinutes(_newDate.getMinutes())
				_formattedDate = this.localeIsoString(_date.toISOString())
			}
			if (this.type === 'date') {
				const _newDate = new Date(newValue)
				_date.setDate(_newDate.getDate())
				_date.setMonth(_newDate.getMonth())
				_date.setFullYear(_newDate.getFullYear())
				if (this.typeValue === 'date') {
					_formattedDate = `${_date.getFullYear()}-${(_date.getMonth() + 1)
						.toString()
						.padStart(2, '0')}-${_date.getDate().toString().padStart(2, '0')}`
				} else {
					_formattedDate = this.localeIsoString(_date.toISOString())
				}
			}
			return _formattedDate
		},
		localeISOtoValue(value: string): string | undefined {
			let _response
			if (this.value) {
				if (this.type === 'date') {
					const _date = new Date(value)
					_response = `${_date.getFullYear()}-${(_date.getMonth() + 1)
						.toString()
						.padStart(2, '0')}-${_date.getDate().toString().padStart(2, '0')}`
				}
				if (this.type === 'time') {
					_response = new Date(value).toTimeString()
				}
				if (this.type === 'datetime') {
					_response = new Date(value).toISOString()
				}
			}
			return _response
		},
	},
})
