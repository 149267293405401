import createRepository from '~/repositories/Repository'
import apiModel from '~/apiModels/ApiModel'
import TravelerService from '~/services/Traveler'
import ItineraryService from '~/services/Itinerary'
import SiteService from '~/services/Site'
import MediaService from '~/services/Media'
import ProfileService from '~/services/Profile'
import LeadService from '~/services/Lead'
import DestinationService from '~/services/Destination'
import DiscoveryService from '~/services/Discovery'
import NotesService from '~/services/Notes'
import CategoryService from '~/services/Category'
import BusinessService from '~/services/Business'

export default (
	repositories: Partial<typeof createRepository>,
	model: Partial<typeof apiModel>
) => ({
	traveler: new TravelerService(repositories, model),
	itinerary: new ItineraryService(repositories, model),
	site: new SiteService(repositories, model),
	profile: new ProfileService(repositories, model),
	media: new MediaService(repositories, model),
	lead: new LeadService(repositories, model),
	business: new BusinessService(repositories, model),
	destination: new DestinationService(repositories, model),
	discovery: new DiscoveryService(repositories, model),
	notes: new NotesService(repositories, model),
	category: new CategoryService(repositories, model),
})
