import { ActionTree, GetterTree, MutationTree } from 'vuex'
import Vue from 'vue'
import CivitatisActivity from '~/models/CivitatisActivity'
import DestinationCategory from '~/models/DestinationCategory'
import { Marker2 as Marker } from '~/models/Marker'
import { Spot2 as Spot, PaginateSpots } from '~/models/Spot'
import { RootState } from '~/store/index'
import { DestinationMapperEntity } from '~/passporter-services/destination/toEntity'
import { DestinationService } from '~/passporter-services/destination/service'
import { Destination2 as Destination, WeatherData } from '~/models/Destination'

interface DestinationInitState {
	searchResults: Destination[]
	history: Destination[]
	lastSearch: string
	destination?: Destination
	categories: DestinationCategory[]
	trendyDestinations?: Destination[]
}
const initState = (): DestinationInitState => ({
	searchResults: [],
	history: [],
	lastSearch: '',
	categories: [],
	destination: undefined,
	trendyDestinations: undefined,
})
export const state = initState
export type DestinationModuleState = ReturnType<() => DestinationInitState>

export const mutations: MutationTree<DestinationModuleState> = {
	setSearchResults(state, searchResults) {
		state.searchResults = searchResults
	},
	setSearch(state, lastSearch) {
		state.lastSearch = lastSearch
		state.searchResults = []
	},
	pushHistory(state, lastHistory) {
		if (lastHistory) {
			state.history = state.history.slice(0, 8)
			state.history.unshift(lastHistory)
		}
	},
	setDestination(state, destination) {
		state.destination = destination
	},
	setCategories(state, categories) {
		state.categories = categories
	},
	setDestinationSpots(state, { nextPage, spots }: PaginateSpots) {
		if (state.destination) {
			Vue.set(state.destination, 'spots', spots as Spot[])
			Vue.set(state.destination, 'spotsNextPage', nextPage)
		}
	},
	setDestinationSpotsCategory(state, categoryId) {
		if (state.destination) {
			Vue.set(state.destination, 'spotsCategoryId', categoryId)
		}
	},
	pushDestinationSpots(state, { nextPage, spots }) {
		if (state.destination) {
			const newDestination = { ...state.destination }
			const destinationService = new DestinationService()
			destinationService.pushSpots(newDestination, spots, nextPage)
			state.destination = newDestination
		}
	},
	setDestinationMarkers(state, markers: Marker[]) {
		if (state.destination) {
			Vue.set(state.destination, 'markers', markers)
		}
	},
	setDestinationWeather(
		state,
		{ weather, destination }: { weather: WeatherData[]; destination?: Destination }
	) {
		destination ??= state.destination
		if (destination) {
			Vue.set(destination, 'weather', weather)
		}
	},
	setTrendyDestinations(state, destinations: Destination[]) {
		state.trendyDestinations = destinations
	},
	pushDestinationCivitatis(
		state,
		{
			nextPage,
			destination,
			civitatis,
		}: {
			nextPage: number
			destination: Destination
			civitatis: CivitatisActivity[]
		}
	) {
		const destinationService = new DestinationService()
		if (destination) {
			destinationService.pushCivitatis(destination, civitatis, nextPage)
		} else {
			if (!state.destination) return
			const newDestination = { ...state.destination }
			destinationService.pushCivitatis(newDestination, civitatis, nextPage)
			state.destination = newDestination
		}
	},
	reset(state) {
		Object.assign(state, initState())
	},
}

export const actions: ActionTree<DestinationModuleState, RootState> = {
	async getDestination({ commit, dispatch }, id) {
		try {
			const destinationService = new DestinationService(this.$repositories.destination)
			const parsedResult = await destinationService.obtainDestination(id)
			commit('setDestination', parsedResult)
		} catch (e) {
			commit('setDestination', null)
			await dispatch('error', e, { root: true })
		}
	},
	async getMoreDestinationSpots({ commit, state, dispatch }, { categoryId }) {
		try {
			if (state.destination && state.destination.spotsNextPage) {
				const res = await this.$repositories.spot.getSpots({
					destinationId: state.destination.id,
					categoryId,
					page: state.destination.spotsNextPage,
				})
				const { data } = res
				const { next, results } = data
				const parsedResults = this.$apiModel.spot.parseSpots(results)
				commit('pushDestinationSpots', {
					nextPage: next,
					spots: parsedResults,
				})
			}
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async getDestinationWeather({ dispatch, state, commit }, destination: Destination) {
		try {
			const _destination = destination || state.destination
			const _destinationId = _destination.id
			if (_destinationId) {
				const { data: resCurrentWeather } = await this.$repositories.destination.getCurrentWeather(
					_destinationId
				)
				const { data: resNextWeather } = await this.$repositories.destination.getNextWeather(
					_destinationId
				)
				const weather = this.$apiModel.destination.parseDestinationWeather([
					resCurrentWeather,
					...resNextWeather,
				])
				commit('setDestinationWeather', {
					weather,
					destination,
				})
			}
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async search({ commit, state, dispatch }, search) {
		try {
			if (search !== state.lastSearch) {
				commit('setSearch', search)
			}
			if (state.lastSearch && state.lastSearch.length > 2) {
				const res = await this.$repositories.destination.search(state.lastSearch, 0)
				const { data } = res
				const { results } = data
				const parsedResults = DestinationMapperEntity.toDestinations(results)
				if (parsedResults.length) {
					commit('setSearchResults', parsedResults)
				} else {
					commit('setSearchResults', [
						{
							disabled: true,
							name: this.$i18n.t('Common_Web_Emptycase_Search_Destination_Google'),
						},
					])
				}
				return parsedResults
			} else {
				commit('setSearchResults', [
					{ disabled: true, name: this.$i18n.t('Search_Common_Emptycase') },
				])
			}
		} catch (e) {
			commit('setSearchResults', [])
			await dispatch('error', e, { root: true })
		}
	},
	async openDestination(
		{ dispatch },
		{
			destination,
			destinationId,
			itineraryId,
			section,
		}: {
			destination?: Destination
			destinationId?: string
			itineraryId?: string
			section?: 'map'
		}
	) {
		try {
			await this.$router.push(
				`/${this.$i18n.locale}/${
					itineraryId ? 'itineraries/' + itineraryId + '/' : ''
				}destinations/${destination?.id || destinationId}${section ? '/' + section : ''}`
			)
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	selected({ commit, state }, destination: Destination) {
		if (!state.destination || destination.id !== state.destination.id) {
			commit('pushHistory', destination)
		}
	},
	async getCategories({ commit, dispatch }, destinationId) {
		try {
			const res = await this.$repositories.destination.categories(destinationId, 0)
			const { data } = res
			const { results } = data
			const categories = this.$apiModel.destination.parseDestinationCategories(results)
			commit('setCategories', categories)
		} catch (e) {
			commit('setCategories', [])
			await dispatch('error', e, { root: true })
		}
	},
	async getTrendyDestinations({ commit, dispatch }) {
		try {
			const res = await this.$repositories.destination.trendy()
			const { data } = res
			const destinations = DestinationMapperEntity.toDestinations(data)
			commit('setTrendyDestinations', destinations)
		} catch (e) {
			commit('setTrendyDestinations', [])
			await dispatch('error', e, { root: true })
		}
	},
	async checkGDestination({ dispatch }, item) {
		try {
			if (item?.referenceType === 'gplace') {
				const destinationService = new DestinationService(this.$repositories.destination)
				const parsedItem: Destination | undefined = await destinationService.find(item.placeId)
				if (parsedItem?.placeId) {
					parsedItem.name = item.name
				}
				return parsedItem
			}
			return item
		} catch (e: any) {
			if (e.response?.status === 400) {
				throw new Error('400')
			} else {
				await dispatch('error', e, { root: true })
				throw new Error('unknown')
			}
		}
	},
	async reset({ commit }) {
		await commit('reset')
	},
}

export const getters: GetterTree<DestinationModuleState, RootState> = {
	searchResults: (state): Destination[] => {
		return state.searchResults
	},
	history: (state): Destination[] => {
		return state.history
	},
	destination: (state): Destination | undefined => {
		return state.destination
	},
	categories: (state): DestinationCategory[] => {
		return state.categories
	},
	trendyDestinations: (state): Destination[] | undefined => {
		return state.trendyDestinations
	},
}
