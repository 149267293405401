











import Vue from 'vue'
import { mapGetters } from 'vuex'
export default Vue.extend({
	name: 'BlogWizardLayout',
	computed: {
		...mapGetters({
			theme: 'theme',
			screenSize: 'screenSize',
		}),
	},
})
