import AuthRepository from '~/repositories/AuthRepository'
import ProfileRepository from '~/repositories/ProfileRepository'
import SpotRepository from '~/repositories/SpotRepository'
import DestinationRepository from '~/repositories/DestinationRepository'
import itineraryRepository from '~/repositories/ItineraryRepository'
import mediaRepository from '~/repositories/MediaRepository'
import discoveryRepository from '~/repositories/DiscoveryRepository'
import agencyRepository from '~/repositories/AgencyRepository'
import businessRepository from '~/repositories/BusinessRepository'
import SiteRepository from '~/repositories/SiteRepository'
import LeadRepository from '~/repositories/LeadRepository'
import NotesRepository from '~/repositories/NotesRepository'
import AbTestRepository from '~/repositories/AbTestRepository'

export default (http: any) => ({
	auth: AuthRepository(http),
	profile: ProfileRepository(http),
	destination: DestinationRepository(http),
	site: SiteRepository(http),
	spot: SpotRepository(http),
	itinerary: itineraryRepository(http),
	media: mediaRepository(http),
	discovery: discoveryRepository(http),
	agency: agencyRepository(http),
	business: businessRepository(http),
	lead: LeadRepository(http),
	notes: NotesRepository(http),
	abtest: AbTestRepository(http),
})
