import { IncomingMessage, ServerResponse } from 'http'
import { Middleware } from '@nuxt/types'

type Dictionary<T> = { [key: string]: T }

export default (function ({ req, res, route }) {
	if (![process.server, req, res].every(Boolean)) return

	const query = route.query

	if (hasMetricsParams(query)) {
		const cookies = parseCookies(req)
		if (!cookies.metrics_url) {
			const path = `https://${req.headers.host}${req.url}`
			setCookie(res, 'metrics_url', path, 30)
		}
	}

	const leadId = query.lead_id as string | null
	if (leadId) {
		setCookie(res, 'lead_id', leadId, 30)
		removeQueryParam(req, res, 'lead_id')
	}
} as Middleware)

function parseCookies(req: IncomingMessage): { [key: string]: string } {
	const list: { [key: string]: string } = {}
	const cookieHeader = req.headers?.cookie

	if (cookieHeader) {
		cookieHeader.split(';').forEach((cookie) => {
			const parts = cookie.split('=')
			list[parts.shift()!.trim()] = decodeURIComponent(parts.join('='))
		})
	}

	return list
}

function hasMetricsParams(query: Dictionary<String | (String | null)[]>) {
	return [
		query.ref,
		Object.keys(query).some((key) => key.startsWith('utm_')),
		Object.keys(query).some((key) => key in ['url_source', 'channel']),
	].some(Boolean)
}

function setCookie(res: ServerResponse, name: string, value: string, days: number) {
	const expires = new Date()
	expires.setDate(expires.getDate() + days)

	const cookie = `${name}=${value}; Expires=${expires.toUTCString()}; Path=/;`

	const existingCookies = res.getHeader('Set-Cookie')

	let cookiesArray: any[] = []
	if (existingCookies) {
		if (Array.isArray(existingCookies)) {
			cookiesArray = existingCookies
		} else {
			cookiesArray = [existingCookies as string]
		}
	}

	cookiesArray.push(cookie)

	res.setHeader('Set-Cookie', cookiesArray)
}

function removeQueryParam(req: IncomingMessage, res: ServerResponse, paramName: string) {
	const protocol = 'https'
	const url = new URL(req.url || '', `${protocol}://${req.headers.host}`)

	url.searchParams.delete(paramName)

	res.writeHead(302, { Location: url.toString() })
	res.end()
}
