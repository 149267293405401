import { ItineraryEntityMapper } from './entityMapper'
import { Itinerary2 as Itinerary } from '~/models/Itinerary'

export class ItineraryService {
	repository: any
	constructor(repository: any) {
		this.repository = repository
	}

	async getItinerary(itineraryId: string, invitationCode?: string): Promise<Itinerary> {
		const options: Record<string, string> = { itineraryId }
		if (invitationCode) {
			options.code = invitationCode
		}
		const res = await this.repository.getItinerary(options)
		const { data } = res
		return ItineraryEntityMapper.toItinerary(data)
	}

	async getSpots(
		itineraryId: string,
		itineraryGroupId?: string | null,
		page?: number,
		pageSize?: number,
		code?: string,
		categoryIds?: string[]
	): Promise<any> {
		const response = await this.repository.getItinerarySpots({
			itineraryId,
			page,
			pageSize,
			code,
			itineraryGroupId,
			categoryIds,
		})
		const results = response?.data?.results
		return ItineraryEntityMapper.parseItinerarySpots(results)
	}

	async setSpotGroup(
		itineraryId: string,
		spotId: string,
		groupId: string,
		isSpotReference?: boolean
	) {
		await this.repository.aggregateSpots(itineraryId, [
			{
				spotId,
				groupsIds: [groupId],
				isReference: isSpotReference,
			},
		])
	}

	async removeSpotFromGroup(itineraryId: string, spotId: string, isSpotReference?: boolean) {
		await this.repository.aggregateSpots(itineraryId, [
			{
				spotId,
				groupsIds: [],
				isReference: isSpotReference,
			},
		])
	}

	async deleteGroup(itineraryId: string, groupId: string) {
		await this.repository.deleteGroup({ itineraryId, id: groupId })
	}
}
