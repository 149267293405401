import Guide, { InspirationSource } from '~/models/Guide'

const parseGuide = (content: any) => {
	return new Guide({
		id: content.id,
		title: content.title,
		subtitle: content.subtitle,
		description: content.description,
		state: content.state,
		spotsNumber: content.num_spots,
		destinations: content.num_destinations,
		travelers: content.num_travelers,
		notes: content.num_notes,
		views: content.num_views,
		covers: content.covers,
		creatorId: content.creator_id,
		travelersPictures: content.travelers_pictures,
		inspirationSources: content.sources ? parseInspirationSources(content.sources) : undefined,
	})
}

const parseGuides = (contents: Record<string, any>[]): Guide[] => {
	return contents.map((content) => {
		return parseGuide(content)
	})
}

const parseInspirationSources = (sources: Record<string, any>[]): InspirationSource[] => {
	const result = sources.map((source) => {
		const item: InspirationSource = {
			name: source.name,
			url: source.url,
		}
		if (source.logo) {
			item.logo = source.logo
		}
		return item
	})
	return result
}

export default () => ({
	parseGuide,
	parseGuides,
})
