



























import Vue from 'vue'
import { mapGetters } from 'vuex'
import Dialog, { DialogType } from '~/components/UI/Dialog.vue'
import Col from '~/components/UI/Col.vue'
import PIconArrow from '~/components/PassporterUI/Icon/PIconArrow.vue'
import BasicScreen from '~/components/context/auth/BasicScreen.vue'
import AuthSections from '~/components/context/auth/AuthSections.vue'
import { LoginSection } from '~/components/context/auth/AuthView.vue'

export default Vue.extend({
	name: 'ItineraryLoginDialog',
	components: {
		AuthSections,
		BasicScreen,
		Col,
		PIconArrow,
		Dialog,
	},
	data() {
		const isRegister: boolean = false
		return {
			basicSection: 'basic' as LoginSection,
			section: undefined as LoginSection | undefined,
			destination: undefined as string | undefined,
			host: undefined as string | undefined,
			loginError: null as string | null,
			forgotError: null as string | null,
			registerError: null as string | null,
			showErrorToast: false,
			isRegister,
			dialog: null as DialogType | null,
		}
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
			isAuthenticated: 'auth/isAuthenticated',
		}),
		isBasicSection(): boolean {
			return this.section === 'basic'
		},
	},
	created() {
		this.section = (this.$route.query.s as LoginSection) ?? this.basicSection
	},
	methods: {
		open(opts: { destination?: string; host?: string; isRegister?: boolean }) {
			this.dialog = this.$refs.dialog as DialogType
			this.destination = opts?.destination
			this.host = opts?.host
			this.isRegister = Boolean(opts?.isRegister)
			this.dialog?.open()
		},
		onSectionClicked(section: LoginSection) {
			this.section = section
		},
		onBack() {
			if (this.section === 'forgotPassword') {
				this.onSectionClicked('login')
			} else {
				this.onSectionClicked(this.basicSection)
			}
		},
		checkAuth() {
			if (this.isAuthenticated) {
				this.$nuxt.$emit('authenticated')
			}
			this.dialog?.close()
		},
	},
})
