var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"p-button transition-all",class:( _obj = {
		'px-3': _vm.slots.default && ['sm', 'md'].includes(_vm.buttonSize),
		'px-6': _vm.slots.default && _vm.buttonSize === 'lg'
	}, _obj[_vm.bgColor] = _vm.bgColor, _obj[_vm.borderColor] = _vm.borderColor, _obj['border-xs'] =  Boolean(_vm.borderColor), _obj['border-none'] =  !Boolean(_vm.borderColor), _obj['d-flex justify-center'] =  _vm.slots.icon && !_vm.slots.default, _obj['d-flex width-12'] =  _vm.block, _obj['pbutton-focusable'] =  _vm.focusable, _obj['rounded-2'] =  !_vm.isRounded, _obj['rounded-6'] =  _vm.isRounded, _obj ),style:({
		'height': _vm.height,
		'width': _vm.slots.icon && !_vm.slots.default && _vm.height,
		'min-width': _vm.slots.icon && !_vm.slots.default && _vm.height,
		'color': _vm.textColor,
		'--btn-bg-hover': _vm.hoverBgColor,
		'--btn-border-hover': _vm.hoverBorderColor,
		'--btn-bg-focus': _vm.focusBgColor,
		'--btn-border-focus': _vm.focusBorderColor,
		'--btn-bg-disabled': _vm.disabledBgColor,
		'--btn-text-disabled': _vm.disabledTextColor,
		'cursor': 'pointer',
	}),attrs:{"disabled":_vm.disabled,"type":_vm.type},on:{"click":function($event){return _vm.click($event)}}},[_vm._t("icon",null,{"color":_vm.textColor}),_vm._v(" "),(_vm.slots.default && _vm.sizeSm)?_c('span',{staticClass:"text-link-xs",class:{ 'd-flex flex-grow-1 justify-center': _vm.block },staticStyle:{"white-space":"nowrap"}},[_vm._t("default")],2):(_vm.slots.default)?_c('span',{staticClass:"text-link",class:{ 'd-flex flex-grow-1 justify-center': _vm.block },staticStyle:{"white-space":"nowrap"}},[_vm._t("default")],2):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }