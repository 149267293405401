const middleware = {}

middleware['download'] = require('../src/middleware/download.ts')
middleware['download'] = middleware['download'].default || middleware['download']

middleware['home'] = require('../src/middleware/home.js')
middleware['home'] = middleware['home'].default || middleware['home']

middleware['locales-redirects'] = require('../src/middleware/locales-redirects.ts')
middleware['locales-redirects'] = middleware['locales-redirects'].default || middleware['locales-redirects']

middleware['url-storage'] = require('../src/middleware/url-storage.ts')
middleware['url-storage'] = middleware['url-storage'].default || middleware['url-storage']

export default middleware
