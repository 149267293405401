import { Category } from '~/models/Category'

const parseCategory = (content: any) => {
	return new Category({
		id: content.id,
		name: content.name,
		logo: content.logo,
	})
}

const parseCategories = (content: any[]) => {
	return content.map((category) => parseCategory(category))
}

export default () => ({
	parseCategories,
})
