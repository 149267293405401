var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Col',[(_vm.overlay && _vm.closable && _vm.modelValue)?_c('POverlay',{on:{"closed":_vm.close}}):_vm._e(),_vm._v(" "),_c('transition',{attrs:{"name":"slide-to-top"}},[(_vm.modelValue)?_c('div',{ref:"bottomSheet",staticClass:"bottom-sheet left-0",class:{
				'transition-all ': !_vm.isMoving,
				'overflow-hidden': _vm.isMoving,
				'elevation-6 draggable': _vm.dragEnabled || !_vm.lockOnOpen,
				'rounded-t-2': _vm.state !== 'open' || !_vm.lockOnOpen,
			},style:({
				top: _vm.half && !_vm.draggable ? '50%' : ((_vm.calcY()) + "px"),
				height: _vm.half && !_vm.draggable ? '50vh' : ("calc(100vh - " + _vm.offsetY + "px)"),
			})},[_c('div',{ref:"closedContent"},[(_vm.dragEnabled)?_c('div',{staticClass:"d-flex justify-center slide-area",class:{ moving: _vm.isMoving },on:{"click":function($event){$event.stopPropagation();},"touchstart":function($event){$event.stopPropagation();return _vm.onMoved.apply(null, arguments)},"touchend":function($event){$event.stopPropagation();return _vm.onEnd.apply(null, arguments)},"touchmove":function($event){$event.stopPropagation();return _vm.onMoving.apply(null, arguments)}}},[_c('div',{staticClass:"bar"}),_vm._v(" "),(_vm.closable)?_c('div',{staticClass:"d-flex align-center button-title-container px-5 py-4",class:{ 'image-background': !!_vm.$slots.image }},[(_vm.closeText)?_c('span',{staticClass:"text-heading-m",class:!!_vm.$slots.image ? 'text-neutral-50' : 'text-neutral-900'},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.closeText)+"\n\t\t\t\t\t\t")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"px-2 py-2",on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_c('PIconClose',{staticClass:"close-button",attrs:{"stroke-color":!!_vm.$slots.image ? 'text-neutral-25' : 'text-neutral-900'}})],1)]):_vm._e()]):_vm._e()]),_vm._v(" "),_vm._t("image"),_vm._v(" "),(_vm.$slots.header)?_c('div',{staticClass:"bottom-sheet--header",class:{ 'mt-12': !_vm.$slots.image && _vm.dragEnabled }},[_c('div',[_vm._t("header")],2)]):_vm._e(),_vm._v(" "),(_vm.$slots.default)?_c('div',{ref:"bottomSheetContent",staticClass:"bottom-sheet--content",class:Object.assign({}, _vm.contentClasses,
					{'mt-20':
						((!_vm.$slots.header && !_vm.lockOnOpen) || !(_vm.state === 'open' && _vm.lockOnOpen)) &&
						_vm.contentMargin}),style:(_vm.contentStyles)},[_vm._t("default")],2):_vm._e(),_vm._v(" "),(_vm.$slots.footer)?_c('div',{staticClass:"bottom-sheet--footer"},[_c('div',[_vm._t("footer")],2)]):_vm._e()],2):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }