import Spot, { Spot2, SpotData } from './Spot'
import ItineraryGroup from './ItineraryGroup'
import ItineraryCategory from './ItineraryCategory'
import { serialize } from './helpers/serialize'

interface ItinerarySpotData extends SpotData {
	isReference: boolean
	groups?: ItineraryGroup[] | null
	categories: ItineraryCategory[]
}

export type ItinerarySpot2 = Spot2 & {
	isReference: boolean
	groups?: ItineraryGroup[] | null
	categories: ItineraryCategory[]
}

export default class ItinerarySpot extends Spot implements ItinerarySpotData {
	isReference
	groups
	categories

	constructor(itinerarySpot: ItinerarySpotData) {
		super(itinerarySpot)
		this.isReference = itinerarySpot?.isReference || false
		this.groups = serialize(ItineraryGroup, itinerarySpot?.groups) || null
		this.categories =
			serialize(ItineraryCategory, itinerarySpot?.categories) || []
	}

	isInGroup(groupId: string | null): boolean {
		return this.hasGroupId(groupId) || !groupId
	}

	private hasGroupId(groupId: string | null): boolean {
		return (
			this.groups?.some(
				(spotGroup: ItineraryGroup) => groupId === spotGroup.id
			) || false
		)
	}
}
