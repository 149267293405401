import Link from '~/models/Link'
import { ApiLink } from '~/passporter-services/link/apiTypes'

export class LinkEntityMapper {
	static toLink = (link: ApiLink) => {
		return new Link({
			id: link.id,
			url: link.url,
			title: link.title,
			description: link.description,
			coverUrl: link.cover,
		})
	}

	static toLinks = (links: ApiLink[]) => {
		return links.map((link: ApiLink) => this.toLink(link))
	}
}
