


















import Vue from 'vue'
import { mapGetters } from 'vuex'
import Itinerary from '~/models/Itinerary'
import { Spot2 as Spot } from '~/models/Spot'
import Media from '~/models/Media'
import { SiteInfo } from '~/models/SiteInfo'
import { DialogType } from '~/components/UI/Dialog.vue'
import ItineraryLoginDialog from '~/components/context/auth/dialog/ItineraryLoginDialog.vue'
import WelcomeLightbox from '~/components/context/auth/dialog/WelcomeLightbox.vue'
import AuthDialog from '~/components/context/auth/dialog/index.vue'
import ItineraryAdministratorDialog from '~/components/context/items/itineraries/ItineraryAdministratorDialog.vue'
import SpotDialog from '~/components/context/guides/spots/SpotDialog.vue'
import ImageViewer from '~/components/PassporterUI/ImageViewer/ImageViewer.vue'
import PdfViewer from '~/components/context/shared/PdfViewer.vue'
import DeleteDialog from '~/components/UI/DeleteDialog.vue'
import GuideDialog from '~/components/context/guides/GuideDialog.vue'
import MicrositeInfoDialog from '~/components/context/microsites/MicrositeInfoDialog.vue'
import SpotInfoDialog from '~/components/context/items/spots/SpotInfoDialog.vue'

export default Vue.extend({
	name: 'RootDialogs',
	components: {
		ItineraryLoginDialog,
		ItineraryAdministratorDialog,
		DeleteDialog,
		AuthDialog,
		WelcomeLightbox,
		SpotDialog,
		ImageViewer,
		PdfViewer,
		GuideDialog,
		MicrositeInfoDialog,
		SpotInfoDialog,
	},
	data() {
		return {
			isWidget: false,
		}
	},
	computed: {
		...mapGetters({
			isAuthenticated: 'auth/isAuthenticated',
			itinerary: 'itinerary/itinerary',
			screenSize: 'screenSize',
		}),
	},
	mounted() {
		this.$nuxt.$on('image-viewer', this.openImageViewer)
		this.$nuxt.$on('open-guide-dialog', this.openGuideDialog)
		this.$nuxt.$on('pdf-viewer', this.openPdfViewer)
		this.$nuxt.$on('open-welcome-lightbox', () => {
			this.openWelcomeLightbox()
		})
		this.$nuxt.$on('open-auth', this.openLoginModal)
		this.$nuxt.$on('open-register', this.goToRegister)
		this.$nuxt.$on('open-itineraryCreate', this.createItinerary)
		this.$nuxt.$on('open-itineraryEdit', this.editItinerary)
		this.$nuxt.$on('open-itineraryDelete', this.deleteItinerary)
		this.$nuxt.$on('open-profileEdit', this.editProfile)
		this.$nuxt.$on('open-spotDialog', this.saveSpotDialog)
		this.$nuxt.$on('open-spotInfoDialog', this.openSpotInfoDialog)
		this.$nuxt.$on('open-delete', this.showDeleteDialog)
		this.$nuxt.$on('addMicrositeInfoDialog', this.openMicrositeInfo)
	},
	beforeDestroy() {
		this.$nuxt.$off('image-viewer')
		this.$nuxt.$off('save-guide')
		this.$nuxt.$off('pdf-viewer')
		this.$nuxt.$off('open-welcome-lightbox')
		this.$nuxt.$off('open-auth', this.openLoginModal)
		this.$nuxt.$off('open-register', this.goToRegister)
		this.$nuxt.$off('open-itineraryCreate', this.createItinerary)
		this.$nuxt.$off('open-itineraryEdit', this.editItinerary)
		this.$nuxt.$off('open-itineraryDelete', this.deleteItinerary)
		this.$nuxt.$off('open-profileEdit', this.editProfile)
		this.$nuxt.$off('open-spotDialog')
		this.$nuxt.$off('open-spotInfoDialog')
		this.$nuxt.$off('open-delete')
		this.$nuxt.$off('addMicrositeInfoDialog')
	},
	methods: {
		openLoginModal(opts?: { destination?: string; host?: string }) {
			const loginDialog = this.$refs.loginDialog as DialogType
			loginDialog?.open(opts)
		},
		openImageViewer(items: Media[]) {
			const mediaViewerDialog = this.$refs.imageViewer as DialogType
			mediaViewerDialog?.open(items)
		},
		openGuideDialog(items: Media[]) {
			const mediaViewerDialog = this.$refs.addGuideDialog as DialogType
			mediaViewerDialog?.open(items)
		},
		openPdfViewer(src: string) {
			const pdfViewerDialog = this.$refs.pdfViewer as DialogType
			pdfViewerDialog?.open(src)
		},
		openWelcomeLightbox() {
			const welcomeLightbox = this.$refs.welcomeLightbox as DialogType
			welcomeLightbox?.open()
		},
		openMicrositeInfo(info: SiteInfo) {
			const micrositeInfo = this.$refs.addMicrositeInfoDialog as DialogType
			micrositeInfo.open(info)
		},
		goToRegister() {
			if (!this.isAuthenticated) {
				this.$router.push(`/${this.$i18n.locale}/auth/signup`)
			}
		},
		editProfile() {
			const authModal: any = this.$refs.authDialog
			authModal?.openProfileEdit()
		},
		createItinerary(callback: Function | undefined = undefined) {
			if (this.isAuthenticated) {
				this.$mixpanel?.track('Click create new itinerary')
				const createItinerary: any = this.$refs.itineraryAdministratorDialog
				createItinerary?.open(undefined, callback)
			} else {
				this.openLoginModal()
			}
		},
		editItinerary(itinerary: Itinerary) {
			if (this.isAuthenticated) {
				const itineraryAdministratorDialog: any = this.$refs.itineraryAdministratorDialog
				itineraryAdministratorDialog?.open(itinerary)
			} else {
				this.openLoginModal()
			}
		},
		deleteItinerary(itinerary: Itinerary) {
			if (this.isAuthenticated) {
				const itineraryAdministratorDialog: any = this.$refs.itineraryAdministratorDialog
				itineraryAdministratorDialog?.deleteItinerary(itinerary)
			} else {
				this.openLoginModal()
			}
		},

		saveSpotDialog(spot?: Spot) {
			const spotDialog = this.$refs.spotDialog as DialogType
			spotDialog?.open(spot)
		},
		async showDeleteDialog(confirmFuntion?: () => void) {
			await (this.$refs.deleteItinerarySpotDialog as any)?.open(confirmFuntion)
		},

		openSpotInfoDialog(spot?: Spot) {
			const spotInfoDialog = this.$refs.spotInfoDialog as DialogType
			spotInfoDialog?.open(spot)
		},
	},
})
