













import Vue from 'vue'
import { mapGetters } from 'vuex'
import LoginOptions from '~/components/context/auth/LoginOptions.vue'

export default Vue.extend({
	name: 'BasicScreen',
	components: { LoginOptions },
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
		}),
	},
})
