













































import Vue, { PropOptions } from 'vue'
import { mapGetters } from 'vuex'
import DescriptionToggle from '~/components/PassporterUI/DescriptionToggle.vue'
import SpotChips from '~/components/context/guides/spots/SpotChips.vue'
import SpotDetails from '~/components/context/guides/spots/SpotDetails.vue'
import HorizontalScroll from '~/components/UI/HorizontalScroll.vue'
import Cover from '~/components/PassporterUI/Cover.vue'
import SaveSpotButton from '~/components/context/items/spots/SaveSpotButton.vue'
import { Spot2 as Spot } from '~/models/Spot'

export default Vue.extend({
	name: 'SpotDescription',
	components: {
		Cover,
		HorizontalScroll,
		DescriptionToggle,
		SpotChips,
		SpotDetails,
		SaveSpotButton,
	},
	props: {
		spot: {
			type: Object,
			required: true,
		} as PropOptions<Spot>,
		inPlanner: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
		}),
	},
	methods: {
		addTracker(buttonName: string): void {
			this.$mixpanel?.track('Click', {
				page: 'spot?',
				context: 'more/less information',
				value: buttonName,
				component: 'button',
			})
		},
		openGallery(): void {
			this.$nuxt.$emit('image-viewer', this.spot.medias)
		},
	},
})
