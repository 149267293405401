import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { RootState } from '~/store/index'

const initState = () => ({
	userGroup: '',
	featureFlags: {} as Record<string, boolean>,
})
export const state = initState
export type ABTestModuleState = ReturnType<typeof state>

export const mutations: MutationTree<ABTestModuleState> = {
	setUserGroup(state, userGroup: string) {
		state.userGroup = userGroup
	},
	setFlags(state, flags: Record<string, boolean>) {
		state.featureFlags = flags
	},
}

export const actions: ActionTree<ABTestModuleState, RootState> = {
	setUserGroup({ commit }, userGroup: string) {
		commit('setUserGroup', userGroup)
	},
	setFlags({ commit }, flags: Record<string, boolean>) {
		commit('setFlags', flags)
	},
}

export const getters: GetterTree<ABTestModuleState, RootState> = {
	userGroup(state) {
		return state.userGroup
	},
	featureFlags(state) {
		return state.featureFlags
	},
}
