














import Vue from 'vue'
import Cover from '~/components/PassporterUI/Cover.vue'
import Col from '~/components/UI/Col.vue'

export default Vue.extend({
	name: 'CoverSpot',
	components: {
		Cover,
		Col,
	},
	props: {
		images: {
			type: Array,
			required: true,
		},
	},
})
