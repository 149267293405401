import { AuthInfo } from '~/models/AuthToken'

export default class TokenService {
	static getTokenInfo(token: any): AuthInfo {
		const user = JSON.parse(atob(token))
		let id = null
		let exp = null

		if (user) {
			const aSecond = 1000
			id = user.user_id
			exp = user.exp * aSecond
		}
		return { id, exp }
	}
}
