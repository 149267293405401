import { ActionTree, GetterTree, MutationTree } from 'vuex'
import Vue from 'vue'
import { RootState } from '~/store/index'
import Traveler from '~/models/Traveler'
import { Spot2 as Spot } from '~/models/Spot'
import { Marker2 as Marker } from '~/models/Marker'
import { SpotEntityMapper } from '~/passporter-services/spot/entityMapper'
import GuideService from '~/passporter-services/guide/service'
import { Guide2 as Guide } from '~/models/Guide'

interface GuideInitState {
	guide?: Guide
}
const initState = (): GuideInitState => ({
	guide: undefined,
})
export const state = initState
export type GuideModuleState = ReturnType<() => GuideInitState>

export const mutations: MutationTree<GuideModuleState> = {
	setGuide(state, guide: Guide) {
		state.guide = guide
	},

	setGuideCreator(state, traveler: Traveler) {
		if (state.guide) {
			Vue.set(state.guide, 'creator', traveler)
		}
	},
	setSpots(state, { next, spots }: { next: number; spots: Spot[] }) {
		if (state.guide) {
			Vue.set(state.guide, 'spotsNextPage', next)
			Vue.set(state.guide, 'spots', spots)
		}
	},
	setMoreSpots(state, { next, spots }: { next: number; spots: Spot[] }) {
		if (state.guide) {
			const newGuide = { ...state.guide }
			newGuide.spotsNextPage = next
			spots.forEach((spot: Spot) => {
				if (!newGuide?.spots?.some((savedSpot) => savedSpot.id === spot.id)) {
					newGuide?.spots?.push(spot)
				}
			})
			state.guide = newGuide
		}
	},
	setGuideMarkers(state, markers: Marker[]) {
		if (state.guide) {
			Vue.set(state.guide, 'markers', markers)
		}
	},
	reset(state) {
		Object.assign(state, initState())
	},
}
export const actions: ActionTree<GuideModuleState, RootState> = {
	async getGuide({ commit, dispatch, state }, { guideId }) {
		try {
			guideId = guideId || state.guide?.id || undefined

			if (guideId) {
				const guideService = new GuideService(this.$repositories.itinerary)
				const guide = await guideService.getGuide(guideId)
				commit('setGuide', guide)
			}
		} catch (e: any) {
			commit('setGuide', null)
			await dispatch('error', e, { root: true })
		}
	},
	async getGuideCreator({ commit, dispatch, state }) {
		try {
			const travelerId = state.guide?.creatorId
			if (travelerId) {
				const traveler = await this.$services.traveler.obtainBy(travelerId)
				commit('setGuideCreator', traveler)
			}
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async getSpots({ commit, dispatch }, guideId) {
		try {
			if (guideId) {
				const guideService = new GuideService(this.$repositories.itinerary)
				const { next, results } = await guideService.getSpots(guideId)
				commit('setSpots', { next, spots: results })
			}
		} catch (e: any) {
			commit('setSpots', { next: 0, spots: [] })
			await dispatch('error', e, { root: true })
		}
	},
	async getMoreSpots({ commit, state, dispatch }) {
		try {
			if (state.guide?.spots) {
				const nextPage = state.guide.spotsNextPage
				const guideService = new GuideService(this.$repositories.itinerary)
				const { results, next } = await guideService.getSpots(state.guide.id, nextPage)

				commit('setMoreSpots', {
					next,
					spots: results,
				})
			}
		} catch (e: any) {
			commit('setMoreSpots', { next: 0, spots: [] })
			await dispatch('error', e, { root: true })
		}
	},
	async getGuideMarkers({ commit, dispatch, state }) {
		try {
			if (state.guide?.id) {
				const res = await this.$repositories.itinerary.getItineraryMarkers({
					itineraryId: state.guide?.id,
				})
				const { data } = res
				const parsedResults = SpotEntityMapper.toMarkers(data, ({}) => {
					return false
				})
				await commit('setGuideMarkers', parsedResults)
			}
		} catch (e) {
			commit('setGuideMarkers', [])
			await dispatch('error', e, { root: true })
		}
	},
	async nestItinerary({ dispatch, state }, itineraryId) {
		const nestedId = state.guide?.id
		if (nestedId) {
			try {
				await this.$services.itinerary.ensureNoDuplicateItinerary(itineraryId, nestedId)
				return true
			} catch (e: any) {
				const duplicateNestedItinerary = e.response?.data.code === 'already_exists'

				if (duplicateNestedItinerary) {
					const errorText = this.$i18n.t('travelGuide_toastMessages_savingPlaces_duplicated')
					await dispatch('alerts/setError', errorText, { root: true })
				} else {
					await dispatch('error', e, { root: true })
				}
			}
		}
	},
}

export const getters: GetterTree<GuideModuleState, RootState> = {
	guide: (state): Guide | undefined => {
		return state.guide
	},
	spots: (state): Spot[] | undefined => {
		return state.guide?.spots
	},
}
