



































import Vue from 'vue'
import { mapGetters } from 'vuex'
import Dialog, { DialogType } from '~/components/UI/Dialog.vue'
import PButton from '~/components/PassporterUI/PButton.vue'

export default Vue.extend({
	name: 'DeleteDialog',
	components: { Dialog, PButton },
	props: {
		title: {
			type: String,
			default: null,
		},
		subtitle: {
			type: String,
			default: null,
		},
		confirmText: {
			type: String,
			default: null,
		},
		cancelText: {
			type: String,
			default: null,
		},
		value: {
			type: Boolean,
			default: null,
		},
		titleClose: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			confirmFunction: () => {},
		}
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
		}),
		show: {
			get(): boolean {
				if (this.value !== undefined && this.value !== null) {
					return this.value
				} else {
					return this.localShow
				}
			},
			set(newValue: boolean): void {
				this.localShow = newValue
				this.$emit('input', newValue)
			},
		} as any,
	},
	methods: {
		open(confirmCallback: () => void = () => {}) {
			const dialog: DialogType = this.$refs.dialog as DialogType
			this.confirmFunction = confirmCallback
			dialog.open()
		},
		close() {
			const dialog: DialogType = this.$refs.dialog as DialogType
			dialog.close()
		},
		cancel() {
			this.$emit('cancel')
			this.close()
		},
		confirmDelete() {
			this.confirmFunction()
			this.$emit('confirmDelete')
			this.close()
		},
	},
})
