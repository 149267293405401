

































































































import Vue from 'vue'
import Col from '~/components/UI/Col.vue'
import Row from '~/components/UI/Row.vue'
import PIconClose from '~/components/PassporterUI/Icon/PIconClose.vue'

export type RuleFunction = (value?: string) => boolean | string

export default Vue.extend({
	name: 'InputText',
	components: { Col, Row, PIconClose },
	inject: {
		form: {
			from: 'form',
			default: null,
		},
	},
	props: {
		maxlength: {
			type: Number,
			default: undefined,
		},
		type: {
			type: String,
			default: 'text',
		},
		value: {
			type: String,
			default: undefined,
		},
		name: {
			type: String,
			default: undefined,
		},
		prefix: {
			type: String,
			default: undefined,
		},
		maxWidth: {
			type: String,
			default: undefined,
		},
		minWidth: {
			type: String,
			default: undefined,
		},
		label: {
			type: String,
			default: undefined,
		},
		placeholder: {
			type: String,
			default: undefined,
		},
		hidePlaceholder: {
			type: Boolean,
			default: false,
		},
		hideMessages: {
			type: Boolean,
			default: false,
		},
		error: {
			type: String,
			default: undefined,
		},
		success: {
			type: String,
			default: undefined,
		},
		message: {
			type: String,
			default: undefined,
		},
		bgColor: {
			type: String,
			default: undefined,
		},
		autocomplete: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		clearable: {
			type: Boolean,
			default: false,
		},
		expandible: {
			type: Boolean,
			default: false,
		},
		form: {
			type: Boolean,
			default: true,
		},
		rules: {
			type: Array,
			default: () => [],
		},
		dynamicValidate: {
			type: Boolean,
			default: false,
		},
		small: {
			type: Boolean,
			default: false,
		},
		large: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			focused: false,
			// @ts-ignore
			formParent: this.form as null | InputForm,
			inputValue: undefined as string | undefined,
			hasErrors: false,
			localError: undefined as string | undefined,
			showPassword: false as boolean,
		}
	},
	computed: {
		localValue: {
			get(): any {
				return this.value ?? this.inputValue
			},
			set(newValue: string) {
				if (typeof this.value === 'undefined') {
					this.inputValue = newValue
				} else {
					this.inputValue = undefined
					this.$emit('input', newValue)
				}
				if (this.formParent?.dynamicValidate) {
					this.formParent.validate()
				} else if (this.dynamicValidate) {
					this.validate()
				}
			},
		},
		textColor(): string | undefined {
			let color
			if (!this.error && !this.hasErrors && !this.success) {
				color = 'text-neutral-500'
			} else if (this.error || this.hasErrors) {
				color = 'text-error-500'
			} else if (this.success) {
				color = 'text-primary-500'
			}
			return color
		},
		localType(): string {
			if (this.type === 'password') {
				return this.showPassword ? 'text' : 'password'
			}
			return this.type
		},
		localBgColor(): string | undefined {
			return this.bgColor || 'neutral-25'
		},
	},
	created() {
		if (this.formParent) {
			this.formParent.register(this)
		}
	},
	methods: {
		defineHeight(): string {
			const heights = {
				small: '44px',
				medium: '64px',
				large: '130px',
			}

			if (this.small) return heights.small
			if (this.large) return heights.large
			return heights.medium
		},
		async validate(): Promise<boolean> {
			await this.$nextTick()
			const rules = this.rules as RuleFunction[]
			for (let i = 0; i < rules.length; i++) {
				const validator = rules[i]
				const valid = validator(this.localValue)
				if (typeof valid === 'string') {
					this.hasErrors = true
					this.localError = valid
					return false
				} else if (valid) {
					this.clearErrors()
				} else {
					this.hasErrors = true
					return false
				}
			}
			return true
		},
		async softValidate(): Promise<boolean> {
			await this.$nextTick()
			const rules = this.rules as RuleFunction[]
			for (let i = 0; i < rules.length; i++) {
				const validator = rules[i]
				const valid = validator(this.localValue)
				if (typeof valid === 'string') {
					return false
				} else if (!valid) {
					return false
				}
			}
			return true
		},
		getValue(): Record<string, string | number> | undefined {
			if (this.name) {
				return { [this.name]: this.localValue }
			}
			return undefined
		},
		focus(event: Event) {
			this.focused = true
			this.$nextTick(() => {
				this.clearErrors()
				const input = this.$refs.input as HTMLInputElement
				input?.focus()
				this.$emit('click', event)
				this.$emit('focus')
			})
		},
		focusout() {
			this.focused = false
			this.$emit('focusout')
		},
		clearErrors() {
			this.hasErrors = false
			this.localError = undefined
		},
		clearField() {
			if (this.$listeners.clear) {
				;(this.$listeners as any).clear()
			} else {
				this.localValue = undefined
			}
		},
	},
})
