












































import Vue, { PropOptions } from 'vue'
import Col from '~/components/UI/Col.vue'
import Row from '~/components/UI/Row.vue'
import Divider from '~/components/PassporterUI/Divider.vue'
import Cover from '~/components/PassporterUI/Cover.vue'
import ResponsiveData from '~/models/Responsive'

export default Vue.extend({
	name: 'MosaicImages',
	components: { Cover, Divider, Row, Col },
	props: {
		images: {
			type: Array,
			default: () => [],
		},
		height: {
			type: [Number, String],
			default: undefined,
		},
		width: {
			type: [Number, String],
			default: undefined,
		},
		scale: {
			type: Number,
			default: undefined,
		},
		rounded: {
			type: Boolean,
			default: false,
		},
		roundedLg: {
			type: Boolean,
			default: false,
		},
		lazy: {
			type: Boolean,
			default: true,
		},
		mainImageShadow: {
			type: Boolean,
			default: false,
		},
		screenSize: {
			type: Object,
			default: undefined,
		} as PropOptions<ResponsiveData>,
	},
	data() {
		return {
			rendered: false,
			intersected: false,
		}
	},
	computed: {
		colStyle(): String {
			let style = ''
			if (this.height) {
				if (/^\d+$/.test(this.height as string)) {
					style += `height: ${this.height}px; `
					style += `min-height: ${this.height}px; `
				} else {
					style += `height: ${this.height}; `
					style += `min-height: ${this.height}; `
				}
			}
			if (this.width) {
				if (/^\d+$/.test(this.width as string)) {
					style += `width: ${this.width}px; `
				} else {
					style += `width: ${this.width}; `
				}
			}
			return style
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.rendered = true
		})
	},
})
