export function serialize(
	Constructor: object,
	item?: Record<string, any> | Record<string, any>[] | null
) {
	let result
	if (Array.isArray(item)) {
		result = _serializeMultiple(Constructor, item)
	} else {
		result = _serializeSingle(Constructor, item)
	}
	return result
}
function _serializeSingle(Constructor: any, item?: any) {
	if (item) {
		let result
		if (item instanceof Constructor) {
			result = item
		} else {
			result = new Constructor(item)
		}
		return result
	}
}
function _serializeMultiple(Constructor: any, items: any[]) {
	let result
	if (items?.[0] instanceof Constructor) {
		result = items
	} else {
		result = items?.map((item: any) => new Constructor(item))
	}
	return result
}
