import Media from '~/models/Media'
import BaseService from '~/services/BaseService'

export default class MediaService extends BaseService {
	async obtainBy(id: string): Promise<Media[]> {
		const res = await this.repositories.site.getMedias(id)
		const { data } = res
		return this.apiModel.media.parseMedias(data.results, this.apiModel)
	}

	async upload(file: File): Promise<Media | void> {
		const res = await this.repositories.media.upload(file)
		const { data } = res
		return this.apiModel.media.parseMedia(data)
	}

	async delete(mediaId: string): Promise<void> {
		await this.repositories.media.deleteMedia(mediaId)
	}
}
