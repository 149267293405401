export class Category {
	id: string
	name: string
	logo: string

	constructor(item: any) {
		this.id = item.id
		this.name = item.name
		this.logo = item.logo
	}
}
