import Block, { BlockContentType } from '~/models/Block'
import BaseService from '~/services/BaseService'
import PassporterSiteService from '~/passporter-services/site/service'

export default class SiteService extends BaseService {
	async getItineraryBlocks(id: string): Promise<Array<Block<BlockContentType>> | undefined> {
		const siteService = new PassporterSiteService(this.repositories.site)
		const itinerariesBlock = (await siteService.getItineraryBlocks(id)) as unknown as Array<
			Block<BlockContentType>
		>

		return itinerariesBlock
	}
}
