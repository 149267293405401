import Spot from '~/models/Spot'

const parseExperiences = (experiences: any) => {
	return {
		experiences: experiences.results.map((experience: any) => parseExperience(experience)),
	}
}
const parseExperience = (experience: any) => {
	return new Spot({
		address: experience.address,
		cover: experience.cover,
		description: experience.description,
		id: experience.id,
		location: experience.location,
		name: experience.name,
		numTravelers: experience.num_travelers,
		ownerId: experience.owner_id,
		parentSpotId: experience.parent_spot_id,
		isPrivate: experience.state === 'private',
		travelersPictures: experience.travelers_pictures,
		type: experience.type,
		visited: experience.visited,
	})
}
const parseOutExperience = (experience: any) => {
	return {
		id: experience.id,
		name: experience.name,
		description: experience.description,
		location: experience.location,
		visited: experience.visited,
		state: experience.isPrivate ? 'private' : 'public',
		assets: parseOutAssets(experience.assets),
		media_ids: experience.media_ids,
	}
}
const parseOutAssets = (assets: any) => {
	const apiAssets = []
	if (assets.phone) {
		apiAssets.push({ type: 'phone', value: assets.phone })
	}
	if (assets.link) {
		apiAssets.push({ type: 'url', value: assets.link })
	}
	if (assets.open_hours) {
		apiAssets.push({ type: 'open_hours', value: assets.open_hours })
	}
	if (assets.price) {
		apiAssets.push({ type: 'price', value: { price: assets.price } })
	}
	return apiAssets
}

export default () => ({
	parseExperiences,
	parseOutExperience,
})
