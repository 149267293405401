





















import Vue from 'vue'
import { mapGetters } from 'vuex'
import Itinerary, { ItineraryForm } from '~/models/Itinerary'
import ItineraryDialog from '~/components/context/guides/spots/ItineraryDialog.vue'
import { DialogType } from '~/components/UI/Dialog.vue'
import { Destination2 as Destination } from '~/models/Destination'

const initForm = () => ({
	itineraryId: undefined as string | undefined,
	saved: false,
})

export default Vue.extend({
	name: 'GuideDialog',
	components: {
		ItineraryDialog,
	},
	data() {
		return {
			form: initForm(),
			step: undefined as undefined | number,
			showDialog: false,
			dialog: undefined as undefined | DialogType,
			firstStep: 0,
			secondStep: 1,
			lastStep: 2,
		}
	},
	computed: {
		...mapGetters({
			guide: 'guide/guide',
			screenSize: 'screenSize',
			ownItineraries: 'profile/ownItineraries',
			moreOwnItineraries: 'profile/moreOwnItineraries',
			destination: 'destination/destination',
		}),
	},
	methods: {
		open() {
			this.form = initForm()
			this.dialog = this.$refs.itineryDialog as DialogType
			this.dialog.open()
		},
		setChoosenItinerary(id: string) {
			this.form.itineraryId = id
		},
		goPreviousStep() {
			this.dialog!.goToStep(this.secondStep)
		},
		async handleSaveGuide() {
			if (await this.saveIfUniqueGuide()) {
				this.dialog!.goToStep(this.lastStep)
				this.form.saved = true
			} else {
				this.goPreviousStep()
			}
		},
		async whenCreateItineraryGoLastStep(destination: Destination): Promise<void> {
			this.dialog!.goToStep(this.lastStep)
			this.addButtonTracker('create_itinerary_cta')
			const itinerary = await this.createItinerary()
			if (!itinerary) return this.dialog!.goToStep(this.firstStep)
			this.addCreateTracker(destination)
			await this.obtainUserItineraries()
			this.form.itineraryId = itinerary.id!
			await this.handleSaveGuide()
		},
		addCreateTracker(destination: Destination) {
			const referrer = this.$tracker.getContext(this.$route?.name)
			if (!referrer) return
			const parameters = {
				action: 'create trip',
				platform: 'web',
				element: 'trip',
				destination: destination.name,
				id: destination.id ?? '',
				referrer,
			}
			this.$tracker.event('create', parameters)
		},
		async createItinerary(): Promise<Itinerary | undefined> {
			const _form: ItineraryForm = this.obtainForm()
			return await this.$store.dispatch('itinerary/create', _form)
		},
		async saveIfUniqueGuide(): Promise<Boolean> {
			const response = await this.$store.dispatch('guide/nestItinerary', this.form.itineraryId)
			this.addGuideTracker()
			return response
		},
		addGuideTracker() {
			const context = this.$tracker.getContext(this.$route?.name)
			if (!context) return
			const parameters = {
				action: 'add guide',
				destination: this.destination?.name ?? '',
				element: 'guide',
				platform: 'web',
				context,
				title: this.guide.title,
				id: this.guide.id,
				client: 'passporter',
			}
			this.$tracker.event('add', parameters)
		},
		addButtonTracker(value: string) {
			this.$mixpanel.track('Click', {
				page: 'guide',
				context: 'modal',
				value,
				component: 'button',
			})
		},
		async obtainUserItineraries() {
			await this.$store.dispatch('profile/getOwnItineraries')
		},
		obtainForm(): ItineraryForm {
			return {
				id: null,
				destination: this.destination,
				isPrivate: true,
				startDate: undefined,
				endDate: undefined,
				showDate: false,
				option: 'next',
				coverId: null,
				recommendedSpots: false,
			}
		},
	},
})
