













import Vue from 'vue'
import { mapGetters } from 'vuex'
import Cover from '~/components/PassporterUI/Cover.vue'

export default Vue.extend({
	name: 'Avatar',
	components: { Cover },
	props: {
		src: {
			type: String,
			default: null,
		},
		size: {
			type: Number,
			default: 30,
		},
		width: {
			type: [Number, String],
			default: null,
		},
		height: {
			type: [Number, String],
			default: null,
		},
		outlined: {
			type: Boolean,
			default: true,
		},
		cropped: {
			type: Boolean,
			default: false,
		},
		chip: {
			type: Boolean,
			default: false,
		},
		alt: {
			type: String,
			default: '',
		},
		border: {
			type: Boolean,
			default: false,
		},
		borderSize: {
			type: [Number, String],
			default: 2,
		},
		lazy: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters({
			theme: 'theme',
		}),
		styles(): Record<string, string> {
			const width = this.width || this.size
			const height = this.height || this.size
			const style = {
				border: this.border
					? // @ts-ignore
					  ` ${this.borderSize}px solid ${this.theme.current.colors.neutral['25']} !important`
					: '',
				width: `${width}px`,
				height: `${height}px`,
				marginLeft: this.cropped && typeof width === 'number' ? `-${width / 2}px` : '',
			}
			return style
		},
		url(): string {
			const defaultLogo = 'avatar-person-map.svg'
			return this.isUrl(this.src) ? this.src : require(`~/assets/avatars/${defaultLogo}`)
		},
	},
	methods: {
		initials(originalName: string): string {
			let result = ''
			if (originalName) {
				const fullName = originalName.split(' ')
				const name = fullName.shift()
				const lastname = fullName.pop()
				const initials = (name ? name.charAt(0) : '') + (lastname ? lastname.charAt(0) : '')
				result = initials.toUpperCase()
			}
			return result
		},
		isUrl(url: string): Boolean {
			try {
				return Boolean(new URL(url))
			} catch (e: any) {
				return false
			}
		},
		getRandomIndex(seed?: string, max: number = 11): number {
			if (seed) {
				const o = []
				let a, c
				for (c = 0; c < 256; c++) {
					a = c
					for (let f = 0; f < 8; f++) a = 1 & a ? 3988292384 ^ (a >>> 1) : a >>> 1
					o[c] = a
				}
				let n, t
				for (n = -1, t = 0; t < seed.length; t++) n = (n >>> 8) ^ o[255 & (n ^ seed.charCodeAt(t))]
				return ((-1 ^ n) >>> 0) % max
			}
			return Math.floor(Math.random() * 11)
		},
	},
})
