
































import Vue from 'vue'
import { mapGetters } from 'vuex'

import Col from '~/components/UI/Col.vue'

export default Vue.extend({
	name: 'CardItinerary',
	components: {
		Col,
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
		}),
	},
})
