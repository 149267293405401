




































import Vue from 'vue'
import { mapGetters } from 'vuex'
import Onboarding from '~/components/UI/Onboarding.vue'
import AuthMenu from '~/components/context/core/AppHeader/AuthMenu.vue'
import PlanYourTripButton from '~/components/context/microsites/PlanYourTripButton.vue'

export default Vue.extend({
	name: 'MainHeader',
	components: {
		AuthMenu,
		PlanYourTripButton,
		Onboarding,
	},
	props: {
		logo: {
			type: String,
			default: 'logo.svg',
		},
		showCreate: {
			type: Boolean,
			default: true,
		},
		buttonColor: {
			type: String,
			default: 'primary',
		},
	},
	data() {
		return {
			baseURl: 'passporterapp.com',
		}
	},
	computed: {
		...mapGetters({
			user: 'auth/user',
			screenSize: 'screenSize',
			itineraries: 'profile/itineraries',
			site: 'site/site',
		}),
		mainHeaderSize(): number {
			const mainHeaderSize = this.screenSize.xs ? 14 : 20
			this.$store.dispatch('setMainHeaderSize', mainHeaderSize)
			return mainHeaderSize
		},
	},
	methods: {
		goToHome() {
			this.$store.dispatch('openHome')
		},
		track() {
			this.$mixpanel?.track('Click menu Create')
			this.$mixpanel?.track('Itinerary Creation Clicked')
		},
	},
})
