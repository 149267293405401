import Media from '~/models/Media'
import BaseService from '~/services/BaseService'

export default class NotesService extends BaseService {
	async obtainMedias(itineraryId: string, noteId: string): Promise<Media[]> {
		try {
			const res = await this.repositories.notes.getMedias(itineraryId, noteId)
			const { data } = res
			return this.apiModel.media.parseMedias(data)
		} catch {
			return []
		}
	}
}
