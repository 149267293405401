export function removeUndefinedValues<T>(object: T): T {
	const newObject: T = {} as T
	for (const key in object) {
		if (object[key] !== undefined) {
			newObject[key] = object[key]
		}
	}

	return newObject
}

export function removeEmptyValues<T>(object: T): T {
	const newObject: T = {} as T
	for (const key in object) {
		const value = object[key]
		if (hasValue(value)) {
			newObject[key] = object[key]
		}
	}
	return newObject
}

function hasValue(value: unknown): boolean {
	const isNotUndefined = typeof value !== 'undefined'
	const isNotNull = value !== null
	const isNotEmptyString = value !== ''

	return isNotUndefined && isNotNull && isNotEmptyString
}
