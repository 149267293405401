


























































import Vue from 'vue'
import Col from '~/components/UI/Col.vue'

export default Vue.extend({
	name: 'EmptyState',
	components: {
		Col,
	},
	props: {
		lines: {
			type: Boolean,
			default: true,
		},
		line: {
			type: Boolean,
			default: false,
		},
		item: {
			type: String,
			default: undefined,
		},
		height: {
			type: String,
			default: undefined,
		},
		width: {
			type: String,
			default: undefined,
		},
		list: {
			type: Boolean,
			default: false,
		},
		nearby: {
			type: Boolean,
			default: false,
		},
		outline: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		emptyClass() {
			let emptyClass = 'relative '
			if (this.outline) {
				emptyClass += 'border-xxs border-secondary-500 bg-secondary-25 rounded'
			} else {
				emptyClass += 'bg-secondary-25 '
				if (this.list) {
					emptyClass += 'itinerary-spot-empty-state-content'
				}
				if (this.nearby) {
					emptyClass += 'NearbySpots__list-item-img'
				}
			}
			return emptyClass
		},
		style() {
			let style = ''
			style += `height: ${this.height}; `
			style += `width: ${this.width}; `
			return style
		},
	},
})
