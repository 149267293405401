import { Plugin } from '@nuxt/types'
const mixpanel = require('mixpanel-browser')

if (process.env.MIXPANEL_ID) {
	mixpanel.init(process.env.MIXPANEL_ID)
}
if (process.env.MIXPANEL_NEWSLETTER_ID) {
	mixpanel.init(process.env.MIXPANEL_NEWSLETTER_ID, {}, 'newsletter')
}

declare module 'vue/types/vue' {
	interface Vue {
		$mixpanel: ReturnType<typeof mixpanel>
	}
}
declare module '@nuxt/types' {
	interface NuxtAppOptions {
		$mixpanel: ReturnType<typeof mixpanel>
	}
	interface Context {
		$mixpanel: ReturnType<typeof mixpanel>
	}
}
declare module 'vuex/types/index' {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	interface Store<S> {
		$mixpanel: ReturnType<typeof mixpanel>
	}
}

const myPlugin: Plugin = ({ query, store }, inject) => {
	const injectedProps: { tag?: string; blogpost?: string } = {
		tag: (query.tag || query.ref) as string,
		blogpost: query.blogpost as string,
	}
	if (process.env.MIXPANEL_ID) {
		const _track = (
			event: string,
			options: object = {},
			force: boolean | undefined = undefined
		) => {
			if (force || store.getters['auth/user']) {
				mixpanel.track(event, { ...options, ...injectedProps })
			}
		}
		const _reset = () => {
			mixpanel.reset()
		}
		const _identify = (id: string) => {
			mixpanel.identify(id)
		}
		const _setUserProperties = (properties: Record<string, string>) => {
			mixpanel.people.set(properties)
		}
		_track('App Opened', injectedProps)
		inject('mixpanel', {
			track: _track,
			reset: _reset,
			identify: _identify,
			setUserProperties: _setUserProperties,
		})
	}
}

export default myPlugin
