import Itinerary from './Itinerary'
import { serialize } from './helpers/serialize'

interface TravelerData {
	id: string
	name?: string
	username: string
	email: string
	location?: string
	language: string
	referenceType?: string
	picture?: string
	role?: string
	itineraries?: Itinerary[]
	previousItineraries?: Itinerary[]
	ongoingItineraries?: Itinerary[]
	followingItineraries?: Itinerary[]
	itinerariesNextPage?: number
	previousItinerariesNextPage?: number
	ongoingItinerariesNextPage?: number
	followingItinerariesNextPage?: number
	subscribed?: boolean
}

export default class Traveler implements TravelerData {
	private readonly _id
	private readonly _referenceType = 'traveler'
	private readonly _name
	private readonly _email
	private readonly _language
	private readonly _picture
	private readonly _role
	private readonly _username
	private readonly _location
	private _itineraries
	private _previousItineraries
	private _ongoingItineraries
	private _followingItineraries
	private _itinerariesNextPage
	private _previousItinerariesNextPage
	private _ongoingItinerariesNextPage
	private _followingItinerariesNextPage
	private _subscribed

	constructor(traveler: TravelerData) {
		this._id = traveler.id
		this._name = traveler.name
		this._email = traveler.email
		this._language = traveler.language
		this._username = traveler.username
		this._picture = traveler.picture
		this._role = traveler.role
		this._location = traveler.location
		this._subscribed = traveler.subscribed ?? false
		this._itineraries = serialize(Itinerary, traveler.itineraries)
		this._previousItineraries = serialize(
			Itinerary,
			traveler.previousItineraries
		)
		this._ongoingItineraries = serialize(Itinerary, traveler.ongoingItineraries)
		this._followingItineraries = serialize(
			Itinerary,
			traveler.followingItineraries
		)
		this._itinerariesNextPage = traveler.itinerariesNextPage
		this._previousItinerariesNextPage = traveler.previousItinerariesNextPage
		this._ongoingItinerariesNextPage = traveler.previousItinerariesNextPage
		this._followingItinerariesNextPage = traveler.followingItinerariesNextPage
	}

	get id(): string {
		return this._id
	}

	get referenceType(): 'traveler' {
		return this._referenceType
	}

	get name(): string | undefined {
		return this._name
	}

	get username(): string {
		return this._username
	}

	get email(): string {
		return this._email
	}

	get language(): string {
		return this._language
	}

	get picture(): string | undefined {
		return this._picture
	}

	get role(): string | undefined {
		return this._role
	}

	get location(): string | undefined {
		return this._location
	}

	get subscribed(): boolean {
		return this._subscribed
	}

	set subscribed(subscribed: boolean) {
		this._subscribed = subscribed
	}

	get itineraries(): Itinerary[] | undefined {
		return this._itineraries
	}

	set itineraries(itineraries: Itinerary[] | undefined) {
		this._itineraries = itineraries
	}

	get previousItineraries(): Itinerary[] | undefined {
		return this._previousItineraries
	}

	set previousItineraries(itineraries: Itinerary[] | undefined) {
		this._previousItineraries = itineraries
	}

	get ongoingItineraries(): Itinerary[] | undefined {
		return this._ongoingItineraries
	}

	set ongoingItineraries(itineraries: Itinerary[] | undefined) {
		this._ongoingItineraries = itineraries
	}

	get followingItineraries(): Itinerary[] | undefined {
		return this._followingItineraries
	}

	set followingItineraries(itineraries: Itinerary[] | undefined) {
		this._followingItineraries = itineraries
	}

	get itinerariesNextPage(): number | undefined {
		return this._itinerariesNextPage
	}

	set itinerariesNextPage(nextPage: number | undefined) {
		this._itinerariesNextPage = nextPage
	}

	get previousItinerariesNextPage(): number | undefined {
		return this._previousItinerariesNextPage
	}

	set previousItinerariesNextPage(nextPage: number | undefined) {
		this._previousItinerariesNextPage = nextPage
	}

	get ongoingItinerariesNextPage(): number | undefined {
		return this._ongoingItinerariesNextPage
	}

	set ongoingItinerariesNextPage(nextPage: number | undefined) {
		this._ongoingItinerariesNextPage = nextPage
	}

	get followingItinerariesNextPage(): number | undefined {
		return this._followingItinerariesNextPage
	}

	set followingItinerariesNextPage(nextPage: number | undefined) {
		this._followingItinerariesNextPage = nextPage
	}

	pushItineraries(
		itineraries?: Itinerary[] | undefined,
		nextPage?: number
	): void {
		itineraries?.forEach((itinerary: Itinerary) => {
			const indexed = this._itineraries?.find(
				(indexedItinerary: Itinerary) => indexedItinerary.id === itinerary.id
			)
			if (!indexed) {
				this._itineraries?.push(itinerary)
			}
		})
		this._itinerariesNextPage = nextPage
	}

	toJSON(): TravelerData {
		return {
			id: this.id,
			referenceType: this.referenceType,
			name: this.name,
			email: this.email,
			language: this.language,
			picture: this.picture,
			role: this.role,
			username: this.username,
			location: this.location,
			itineraries: this.itineraries,
			previousItineraries: this.previousItineraries,
			ongoingItineraries: this.ongoingItineraries,
			followingItineraries: this.followingItineraries,
			itinerariesNextPage: this.itinerariesNextPage,
			previousItinerariesNextPage: this.previousItinerariesNextPage,
			ongoingItinerariesNextPage: this.ongoingItinerariesNextPage,
			followingItinerariesNextPage: this.followingItinerariesNextPage,
			subscribed: this.subscribed,
		}
	}
}
