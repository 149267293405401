import Price from './Price'
import { serialize } from './helpers/serialize'

interface CivitatisActivityData {
	id: string
	title: string
	description: string
	cover: string
	score: number
	reviewsCount: number
	link: string
	price?: Price
	referenceType?: 'activity'
	location?: {
		latitude: number
		longitude: number
	}
}

export default class CivitatisActivity implements CivitatisActivityData {
	private readonly _id
	private readonly _title
	private readonly _description
	private readonly _cover
	private readonly _score
	private readonly _reviewsCount
	private readonly _link
	private readonly _price
	private readonly _referenceType = 'activity'
	private readonly _location

	constructor(civitatisActivity: CivitatisActivityData) {
		this._id = civitatisActivity.id
		this._title = civitatisActivity.title
		this._description = civitatisActivity.description
		this._cover = civitatisActivity.cover
		this._score = civitatisActivity.score
		this._reviewsCount = civitatisActivity.reviewsCount
		this._link = civitatisActivity.link
		this._location = civitatisActivity.location
		this._price = serialize(Price, civitatisActivity.price)
	}

	get id(): string {
		return this._id
	}

	get title(): string {
		return this._title
	}

	get description(): string {
		return this._description
	}

	get cover(): string {
		return this._cover
	}

	get score(): number {
		return this._score
	}

	get reviewsCount(): number {
		return this._reviewsCount
	}

	get link(): string {
		return this._link
	}

	get price(): Price | undefined {
		return this._price
	}

	get referenceType(): 'activity' {
		return this._referenceType
	}

	get location() {
		return this._location
	}

	toJSON(): CivitatisActivityData {
		return {
			id: this.id,
			title: this.title,
			description: this.description,
			cover: this.cover,
			score: this.score,
			reviewsCount: this.reviewsCount,
			link: this.link,
			price: this._price,
			location: this._location,
		}
	}
}
