




























import Vue from 'vue'
import { mapGetters } from 'vuex'
import ImagesCarousel from '~/components/UI/ImagesCarousel.vue'

export default Vue.extend({
	name: 'AuthCarousel',
	components: { ImagesCarousel },

	computed: {
		...mapGetters({
			screenSize: 'screenSize',
		}),
		images() {
			return [
				'/img/login-bg/login-bg-1.webp',
				'/img/login-bg/login-bg-2.webp',
				'/img/login-bg/login-bg-3.webp',
				'/img/login-bg/login-bg-4.webp',
				'/img/login-bg/login-bg-5.webp',
				'/img/login-bg/login-bg-6.webp',
			]
		},
	},
})
