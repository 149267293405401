






import Vue from 'vue'

export default Vue.extend({
	name: 'Scrollbar',
	methods: {
		onScroll() {
			this.$emit('on-scroll')
		},
	},
})
