






import Vue from 'vue'
export default Vue.extend({
	name: 'Spinner',
	props: {
		size: {
			type: [String, Number],
			default: 40,
		},
		color: {
			type: String,
			default: 'neutral-900',
		},
		borderWidth: {
			type: [String, Number],
			default: '5px',
		},
	},
	computed: {
		styles() {
			const size = typeof this.size === 'string' ? this.size : `${this.size}px`
			const borderWidth =
				typeof this.borderWidth === 'string' ? this.borderWidth : `${this.borderWidth}px`
			return {
				'--spinner-size': size,
				'--spinner-color': 'var(--color-' + this.color + ')',
				'---spinner-border-width': borderWidth,
			}
		},
	},
})
