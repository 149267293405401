






































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import Searcher from '~/components/context/discovery/Searcher.vue'
import { Destination2 as Destination } from '~/models/Destination'
import { Spot2 as Spot } from '~/models/Spot'
import Cover from '~/components/PassporterUI/Cover.vue'
import EmptyState from '~/components/PassporterUI/EmptyState.vue'
import SaveSpotButton from '~/components/context/items/spots/SaveSpotButton.vue'
import { Marker2 as Marker } from '~/models/Marker'

export default Vue.extend({
	name: 'ItineraryBrowser',
	components: { Searcher, Cover, EmptyState, SaveSpotButton },
	data() {
		const selected = undefined as 'spot' | 'destination' | undefined
		const item = undefined as Spot | Destination | undefined
		return {
			selected,
			item,
		}
	},
	computed: {
		...mapGetters({
			itinerary: 'itinerary/itinerary',
		}),
		itineraryDestination(): Destination {
			return this.itinerary.destinations?.[0]
		},
	},
	created() {
		this.$store.dispatch('maps/setBrowserMarkers', [])
	},
	methods: {
		async openItem(): Promise<void> {
			if (!this.item) return
			if (this.selected === 'spot') return this.openSpot(this.item as Spot)
			if (this.selected === 'destination') await this.openDestination(this.item.id!)
		},
		selectPlace(place: Spot | Destination): void {
			if (!place) return this.reset()

			const itemType = place?.referenceType
			this.selected = itemType
			this.item = place

			if (itemType === 'spot') this.setMarker(place)
		},
		reset(): void {
			this.selected = undefined
			this.item = undefined
			this.$store.dispatch('maps/setBrowserMarkers', [])
		},
		openSpot(spot: Spot): void {
			this.$nuxt.$emit('open-spotInfoDialog', spot)
		},
		async openDestination(destinationId: string): Promise<void> {
			const route = this.$navigation.itinerary.getDestination(this.itinerary.id, destinationId)
			await this.$router.push(route)
		},
		setMarker(spot: Spot) {
			const marker = {
				location: spot.location,
				color: '#44423E',
				id: spot.id,
			} as Marker
			this.$store.dispatch('maps/setBrowserMarkers', [marker])
		},
	},
})
