




















































































import Vue, { PropOptions } from 'vue'
import { mapGetters } from 'vuex'
import Cookies from 'js-cookie'
import PasswordlessLoginForm, {
	MailFormData,
} from '~/components/context/auth/forms/PasswordlessLoginForm.vue'
import { LoginSection } from '~/components/context/auth/AuthView.vue'
import ConfirmMagicLinkScreen from '~/components/context/auth/ConfirmMagicLinkScreen.vue'
import LoginForm, { LoginFormData } from '~/components/context/auth/forms/LoginForm.vue'
import SignupForm from '~/components/context/auth/forms/SignupForm.vue'
import Toast from '~/components/UI/Toast.vue'
import ForgotPasswordForm from '~/components/context/auth/forms/ForgotPasswordForm.vue'
import { AuthService } from '~/services/auth/service'

export default Vue.extend({
	name: 'AuthSections',
	components: {
		ForgotPasswordForm,
		Toast,
		SignupForm,
		LoginForm,
		ConfirmMagicLinkScreen,
		PasswordlessLoginForm,
	},
	props: {
		section: {
			type: String,
			required: true,
		} as PropOptions<LoginSection>,
		signup: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			loginError: null as string | null,
			showErrorToast: false,
			online: true as boolean,
			signupError: null as string | null,
			email: '',
			forgotError: null as string | null,
		}
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
		}),
		isMagicLinkSection(): boolean {
			return this.section === 'magicLink'
		},
		isConfirmMagicLinkSection(): boolean {
			return this.section === 'confirmMagicLink'
		},
		isLoginSection(): boolean {
			return this.section === 'login'
		},
		isSignupSection(): boolean {
			return this.section === 'signup'
		},
		isForgotPasswordSection(): boolean {
			return this.section === 'forgotPassword'
		},
		loginFormError: {
			get(): string | null {
				return this.connectionError || this.loginError
			},
			set(value: string | null) {
				if (value) {
					this.loginError = value
					this.showErrorToast = true
				} else {
					this.loginError = null
					this.showErrorToast = false
				}
			},
		},
		signupFormError: {
			get(): string | null {
				return this.connectionError || this.signupError
			},
			set(value: string | null) {
				if (value) {
					this.signupError = value
					this.showErrorToast = true
				} else {
					this.showErrorToast = false
					this.signupError = null
				}
			},
		},
		connectionError(): string | undefined {
			return this.online ? undefined : (this.$t('E_Noconnection') as string)
		},
		forgotFormError: {
			get(): string | null {
				return this.connectionError || this.forgotError
			},
			set(value: string | null) {
				if (value) {
					this.forgotError = value
					this.showErrorToast = true
				} else {
					this.showErrorToast = false
					this.forgotError = null
				}
			},
		},
		authService(): AuthService {
			return new AuthService(this.$repositories.auth)
		},
	},
	mounted() {
		this.connectionListener(window.navigator.onLine)
		window.addEventListener('online', () => this.connectionListener(true))
		window.addEventListener('offline', () => this.connectionListener(false))
	},
	beforeDestroy() {
		window.removeEventListener('online', () => this.connectionListener(true))
		window.removeEventListener('offline', () => this.connectionListener(false))
	},
	methods: {
		async handleLogin(form: LoginFormData) {
			this.loginFormError = null
			try {
				await this.doLogin(form)
				this.$emit('authenticated')
			} catch (e: any) {
				await this.handleLoginError(e)
				this.sendSignUpError()
				if (e.formErrors) {
					this.loginFormError = this.$t('E_error_authentication_incorrect') as string
				}
			}
		},
		async handleSignup(form: LoginFormData) {
			this.signupFormError = null
			await this.registerUser(form)

			const userId = await this.$store.getters['auth/userId']
			if (userId) {
				try {
					await this.doLogin(form)
				} catch (e: any) {
					await this.handleLoginError(e)
				}
			}
		},
		async doLogin(form: LoginFormData) {
			const response = await this.authService.login(form)
			await this.$store.dispatch('auth/login2', response)
		},
		async registerUser(form: LoginFormData) {
			try {
				await this.$store.dispatch('auth/register', form)
				this.$emit('authenticated')
			} catch (e: any) {
				this.handleSignupErrors(e)
				this.sendSignUpError()
			}
		},
		handleSignupErrors(error: any) {
			if (error.formErrors) {
				this.signupFormError =
					(this.$t(error.formErrors.email) as string) ??
					(this.$t(error.formErrors.password) as string)
			}
		},

		async handleLoginError(error: any) {
			const failed = error.response?.data?.code === 'authentication_failed'
			if (failed) {
				error.formErrors = { email: ' ', password: ' ' }
				throw error
			}
			await this.$store.dispatch('error', error, { root: true })
		},
		sendSignUpError() {
			this.$mixpanel?.track('signup error impression')
		},
		async handlePasswordlessLogin(form: MailFormData) {
			try {
				const metricsUrl = Cookies.get('metrics_url')
				const response = await this.authService.passwordlessLogin(form.email, metricsUrl)
				if (!response) return
				this.email = form.email
				this.$emit('section', 'confirmMagicLink')
			} catch (e: any) {}
		},
		async handlePasswordForgot(form: { email: string }) {
			try {
				await this.$store.dispatch('auth/sendResetPasswordEmail', form)
				await this.$store.dispatch('alerts/setSuccessI18n', {
					index: 'Web_recovery_screen_success_message',
					data: form,
				})
				this.$emit('section', 'login')
			} catch (e: any) {
				this.sendSignUpError()
				if (e.formErrors) {
					this.forgotFormError = e.formErrors.email
				}
			}
		},
		connectionListener(online: boolean) {
			this.online = online
			this.showErrorToast = !online
		},
	},
})
