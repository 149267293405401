import Profile from '~/models/Profile'

const resource = 'v1/profiles'
export default (http: any) => ({
	user(id: string | null) {
		return http.get(`${resource}/${id}/`)
	},
	updateProfile(id: string | null, profile: Profile) {
		return http.patch(`${resource}/${id}/`, {
			name: profile.name,
			username: profile.username,
		})
	},
	itineraries({
		id,
		page,
		status = 'all',
	}: {
		id: string
		page: number
		status?: 'previous' | 'ongoing' | 'following' | 'all' | 'own' | 'next'
	}) {
		return http.get(`${resource}/${id}/itineraries/`, {
			params: {
				travel_status: status,
				page,
				page_size: 16,
			},
		})
	},
	spots({ id, page, visited }: { id: string; page: number; visited?: boolean }) {
		return http.get(`${resource}/${id}/spots/`, {
			params: {
				page,
				page_size: 16,
				visited,
			},
		})
	},
	subscription(id: string) {
		return http.get(`${resource}/${id}/subscription/`)
	},
	subscribeToUser(userId: string) {
		return http.post(`${resource}/${userId}/subscription/`)
	},
	unsubscribeToUser(userId: string) {
		return http.delete(`${resource}/${userId}/subscription/`)
	},
})
