






import Vue from 'vue'

export default Vue.extend({
	name: 'LazyLoad',
	props: {
		tag: {
			type: String,
			default: 'div',
		},
		value: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		observer: null as IntersectionObserver | null,
		intersected: false,
	}),
	mounted() {
		this.observer = new IntersectionObserver((entries) => {
			const element = entries[0]
			if (element.isIntersecting) {
				this.intersected = true
				this.observer?.disconnect()
				this.$emit('input', this.intersected)
			}
		})

		this.observer.observe(this.$el)
	},
	beforeDestroy() {
		this.observer?.disconnect()
	},
})
