import { ActionTree, GetterTree, MutationTree } from 'vuex'
import Vue from 'vue'
import { RootState } from '~/store/index'
import { Spot2 as Spot } from '~/models/Spot'
import { Destination2 as Destination } from '~/models/Destination'
import Media from '~/models/Media'
import { DestinationMapperEntity } from '~/passporter-services/destination/toEntity'
import { DestinationService } from '~/passporter-services/destination/service'
import { SpotService } from '~/passporter-services/spot/service'
import { Itinerary2 as Itinerary } from '~/models/Itinerary'

interface SpotInitState {
	searchResults: Spot[]
	history: Spot[]
	lastSearch: string
	spot?: Spot
	recommendedItineraries: Itinerary[]
}
const initState = (): SpotInitState => ({
	searchResults: [] as Spot[],
	history: [] as Spot[],
	lastSearch: '' as string,
	spot: undefined as Spot | undefined,
	recommendedItineraries: [] as Itinerary[],
})
export const state = initState

export type SpotModuleState = ReturnType<() => SpotInitState>
export const mutations: MutationTree<SpotModuleState> = {
	setSearchResults(state, searchResults) {
		state.searchResults = searchResults
	},
	setRecommendedItineraries(state, recommendedItineraries) {
		if (state.spot) {
			state.recommendedItineraries = recommendedItineraries
		}
	},
	setSearch(state, lastSearch) {
		state.lastSearch = lastSearch
		state.searchResults = []
	},
	pushHistory(state, lastHistory) {
		if (lastHistory) {
			let newHistory = { ...state.history }
			newHistory = newHistory.slice(0, 8)
			newHistory.unshift(lastHistory)
			state.history = newHistory
		}
	},
	setSpot(state, spot) {
		state.spot = spot
	},
	setSpots(state, { nextPage, spots }) {
		if (state.spot) {
			Vue.set(state.spot, 'experiences', spots)
			Vue.set(state.spot, 'experiencesNextPage', nextPage)
		}
	},
	setSpotName(state, { spot, name }: { spot?: Spot; name: string }) {
		spot ??= state.spot
		if (spot) {
			Vue.set(spot, 'name', name)
		}
	},
	setSpotMedias(state, { spot, medias }: { spot?: Spot; medias?: Media[] }) {
		spot ??= state.spot
		if (spot) {
			Vue.set(spot, 'medias', medias)
		}
	},
	deleteSpotMedia(state, mediaId) {
		if (!state.spot) return
		const newSpot = { ...state.spot }
		const index = newSpot.medias?.findIndex((spotMedia) => spotMedia.id === mediaId) || -1
		if (index >= 0) {
			newSpot.medias?.splice(index, 1)
		}
		state.spot = newSpot
	},
	setSpotExperiences(state, { experiences, count, nextPage }) {
		if (state.spot) {
			const newSpot = { ...state.spot }
			const spotService = new SpotService()
			spotService.setExperiences(newSpot, experiences, count, nextPage)
			state.spot = newSpot
		}
	},
	pushSpotExperiences(state, { experiences, nextPage }) {
		if (state.spot) {
			const newSpot = { ...state.spot }
			const spotService = new SpotService()
			spotService.pushSpotExperiences(newSpot, experiences, nextPage)
			state.spot = newSpot
		}
	},
	setSpotAssets(state, assets) {
		if (state.spot) {
			Vue.set(state.spot, 'assets', assets)
		}
	},
	setSpotSpotParent({}, { spot, parentSpot }: { spot: Spot; parentSpot: Spot }) {
		Vue.set(spot, 'spotParent', parentSpot)
	},
	setSpotParent({}, { spot, parent }: { spot: Spot; parent: Destination }) {
		Vue.set(spot, 'parent', parent)
	},
	setSpotSaved({}, { spot, isSaved }: { spot: Spot; isSaved: boolean | undefined }) {
		if (spot) {
			Vue.set(spot, 'isSaved', isSaved)
		}
	},
	setSpotNearbySpots(state, { nearbySpots, nextPage }: { nearbySpots: Spot[]; nextPage: number }) {
		if (!state.spot) return
		const newSpot = { ...state.spot }
		const spotService = new SpotService()
		spotService.setNearbySpots(newSpot, nearbySpots, nextPage)
		state.spot = newSpot
	},
	pushSpotNearbySpots(state, { nearbySpots, nextPage }: { nearbySpots: Spot[]; nextPage: number }) {
		if (!state.spot) return
		const newSpot = { ...state.spot }
		const spotService = new SpotService()
		spotService.pushNearbySpots(newSpot, nearbySpots, nextPage)
		state.spot = newSpot
	},
	reset(state) {
		Object.assign(state, initState())
	},
}

export const actions: ActionTree<SpotModuleState, RootState> = {
	async search({ commit, state, dispatch }, { search }) {
		try {
			if (search !== state.lastSearch) {
				commit('setSearch', search)
			}
			if (state.lastSearch && state.lastSearch.length > 2) {
				let parsedResults = []
				const res = await this.$repositories.spot.search(state.lastSearch, 0)
				const { data } = res
				const { results } = data
				parsedResults = this.$apiModel.spot.parseSpots(results)
				if (parsedResults.length) {
					commit('setSearchResults', parsedResults)
				} else {
					commit('setSearchResults', [
						{
							disabled: true,
							name: this.$i18n.t('Common_Web_Emptycase_Search_Spots_Google'),
						},
					])
				}
				return parsedResults
			} else {
				commit('setSearchResults', [])
			}
		} catch (e) {
			commit('setSearchResults', [])
			await dispatch('error', e, { root: true })
		}
	},
	async searchSpotItineraries(
		{ commit, dispatch },
		{
			spotId,
		}: {
			spotId?: string
		}
	) {
		try {
			if (spotId) {
				const searchData = {
					spot_id: spotId,
				}
				const res = await this.$repositories.itinerary.search(searchData)
				const { status, data } = res
				if (status === 200) {
					const { results } = data
					const parsedResults = this.$apiModel.itinerary.parseItineraries(results)
					commit('setRecommendedItineraries', parsedResults)
					return parsedResults
				}
			} else {
				commit('setRecommendedItineraries', [])
			}
		} catch (e) {
			commit('setRecommendedItineraries', [])
			await dispatch('error', e, { root: true })
		}
	},
	pushHistory({ commit, state }, spot) {
		if (!state.spot || spot.id !== state.spot.id) {
			commit('pushHistory', spot)
		}
	},
	async checkGPlace({}, item) {
		try {
			if (item?.referenceType === 'gplace') {
				let parsedItem: Spot | Destination | undefined
				const result = await this.$repositories.destination.googleFind({
					placeId: item.placeId,
				})
				const { data } = result
				if (data.spot) {
					parsedItem = this.$apiModel.spot.parseSpot(data.spot)
				} else if (data.destination) {
					parsedItem = DestinationMapperEntity.toDestination(data.destination)
				}
				if (parsedItem?.placeId) {
					parsedItem.name = item.name
				}
				return parsedItem
			}
			return item
		} catch (e) {}
	},
	async getSpotNearbySpots({ state, dispatch, commit }) {
		try {
			if (state.spot) {
				const res = await this.$repositories.spot.searchNearby({
					location: state.spot.location,
					page: 0,
				})
				const { data } = res
				const { next, results } = data
				const parsedResults = this.$apiModel.spot.parseSpots(results)
				commit('setSpotNearbySpots', {
					nearbySpots: parsedResults,
					nextPage: next,
				})
			}
		} catch (e) {
			commit('setSpotNearbySpots', { nextPage: 0, spots: [] })
			await dispatch('error', e, { root: true })
		}
	},
	async getMoreSpotNearbySpots({ commit, state, dispatch }) {
		try {
			if (state.spot && state.spot.nearbySpotsNextPage) {
				const res = await this.$repositories.spot.searchNearby({
					location: state.spot.location,
					page: state.spot.nearbySpotsNextPage,
				})
				const { data } = res
				const { next, results } = data
				const parsedResults = this.$apiModel.spot.parseSpots(results)
				commit('pushSpotNearbySpots', {
					nextPage: next,
					nearbySpots: parsedResults,
				})
			}
		} catch (e) {
			commit('setSpotNearbySpots', { nextPage: 0, spots: [] })
			await dispatch('error', e, { root: true })
		}
	},
	async openSpot(
		{ dispatch },
		{
			spot,
			spotId,
			itinerary,
			destination,
			itineraryId,
			destinationId,
		}: {
			spot?: Spot
			spotId?: string
			itinerary?: Itinerary
			destination?: Destination
			itineraryId?: string
			destinationId?: string
		}
	) {
		try {
			const _spotId = spot?.id || spotId
			const _itineraryId = itinerary?.id || itineraryId
			const _destinationId = destination?.id || destinationId

			if (_spotId) {
				const route = this.$navigation.spot.getSpotRoute(_spotId, {
					itineraryId: _itineraryId,
					destinationId: _destinationId,
				})

				await this.$router.push(route)
			}
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async openSpotGuide({ dispatch }, { destinationId, guideId, spotId }) {
		try {
			const _destinationId = destinationId
			const _guideId = guideId
			const _spotId = spotId

			const route = this.$navigation.spot.getSpotRoute(_spotId, {
				destinationId: _destinationId,
				guideId: _guideId,
			})

			await this.$router.push(route)
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	openNavigator({ state }, spot: Spot | undefined = undefined) {
		const _spot = spot || state.spot
		if (_spot) {
			window.open(
				`https://www.google.com/maps/dir//${_spot.location.latitude},${_spot.location.longitude}`,
				'_blank'
			)
		}
	},
	async shareSpot({}, spotId) {
		const link = `${document.location.protocol}//${document.location.host}/${this.$i18n.locale}/spots/${spotId}`
		await navigator.clipboard.writeText(link)
		this.$mixpanel?.track('Spot Shared')
		// TODO: siempre se hace un alert depues de compartir esto, poner aqui el alert y quitar de donde se use
	},
	async getSpot({ commit, dispatch }, spotId) {
		try {
			const spotService = new SpotService(this.$repositories.spot)
			const parsedResults = await spotService.getSpot(spotId)
			commit('setSpot', parsedResults)
		} catch (e) {
			commit('setSpot', null)
			await dispatch('error', e, { root: true })
		}
	},
	async getSpots({ commit, dispatch, state }, spotId: string) {
		try {
			const res = await this.$repositories.profile.spots({
				id: spotId ?? state.spot?.id,
				page: 0,
			})
			const { data } = res
			const { next, results } = data
			const parsedResults = this.$apiModel.spot.parseSpots(results)
			commit('setSpots', { nextPage: next, spots: parsedResults })
		} catch (e) {
			commit('setSpots', { nextPage: 0, spots: [] })
			await dispatch('error', e, { root: true })
		}
	},
	async getSpotMedias({ commit, dispatch, state }, spot: Spot | undefined = undefined) {
		try {
			const _spot = spot || state.spot
			if (_spot?.id) {
				const res = await this.$repositories.spot.getSpotMedias(_spot.id)
				const { data } = res
				const { results } = data
				const parsedResults = this.$apiModel.media.parseMedias(results)
				commit('setSpotMedias', {
					spot: _spot,
					medias: parsedResults,
				})
			}
		} catch (e) {
			commit('setSpotMedias', [])
			await dispatch('error', e, { root: true })
		}
	},
	deleteLocalSpotMedia({ commit }, media) {
		try {
			commit('deleteSpotMedia', media.id)
		} catch (e) {}
	},
	async getSpotExperiences({ commit, state, dispatch }) {
		try {
			if (state.spot?.id) {
				const res = await this.$repositories.spot.getSpotExperiences(state.spot.id, 0)
				const { data } = res
				const { next, count } = data
				const parsedResults = this.$apiModel.experience.parseExperiences(data)
				commit('setSpotExperiences', {
					nextPage: next,
					count,
					experiences: parsedResults.experiences,
				})
			}
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async getMoreSpotExperiences({ commit, state, dispatch }) {
		try {
			if (state.spot?.id && state.spot?.experiencesNextPage) {
				const res = await this.$repositories.spot.getSpotExperiences(
					state.spot.id,
					state.spot.experiencesNextPage
				)
				const { data } = res
				const { next } = data
				const parsedResults = this.$apiModel.experience.parseExperiences(data)
				commit('pushSpotExperiences', {
					nextPage: next,
					experiences: parsedResults.experiences,
				})
			}
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async getSpotAssets({ commit, state }) {
		try {
			if (state.spot?.id) {
				const res = await this.$repositories.spot.getSpotAssets(state.spot.id)
				const { data } = res
				const parsedResults = this.$apiModel.spot.parseAssets(data)
				commit('setSpotAssets', parsedResults)
			}
		} catch (e) {
			commit('setSpotAssets', [])
		}
	},
	async getSpotParent({ commit, state }, spot: Spot | undefined = undefined) {
		try {
			const _spot = spot || state.spot
			if (_spot?.parentSpotId) {
				const { data } = await this.$repositories.spot.getSpot(_spot.parentSpotId)
				const _parentSpot = this.$apiModel.spot.parseSpot(data)
				commit('setSpotSpotParent', {
					spot: _spot,
					parentSpot: _parentSpot,
				})
			}
		} catch (e) {}
	},
	async getParent({ commit, state }, spot: Spot | undefined = undefined) {
		try {
			const _spot = spot || state.spot
			if (_spot?.parentId) {
				const destinationService = new DestinationService(this.$repositories.destination)
				const _parent = await destinationService.obtainDestination(_spot?.parentId)
				commit('setSpotParent', {
					spot: _spot,
					parent: _parent,
				})
			}
		} catch (e) {}
	},
	async getSaved({ state, commit }, spot: Spot) {
		try {
			const _spot = spot || state.spot
			if (_spot?.id) {
				const { data } = await this.$repositories.spot.isSaved(_spot.id)
				const _isSaved = this.$apiModel.spot.parseSaved(data)
				commit('setSpotSaved', { spot: _spot, isSaved: _isSaved })
			} else {
				throw new Error('invalid spot id')
			}
		} catch (e) {}
	},
	async createExperience({ dispatch }, { experience }) {
		try {
			const apiExperience = this.$apiModel.experience.parseOutExperience(experience)
			const response = await this.$repositories.spot.createExperience({
				spotId: experience.spotParent.id,
				form: apiExperience,
			})
			return response.data
		} catch (e: any) {
			if (e.response?.data?.errors) {
				e.formErrors = {}
				const invalidName = e.response.data.errors.find((error: any) => {
					return error.code === 'invalid_name'
				})
				if (invalidName) {
					e.formErrors.name = invalidName.detail
				}
				const invaliDescription = e.response.data.errors.find((error: any) => {
					return error.code === 'invalid_description'
				})
				if (invaliDescription) {
					e.formErrors.description = invaliDescription.detail
				}
			}
			await dispatch('error', e, { root: true })
		}
	},
	async editExperience({ dispatch }, { experience }) {
		try {
			if (experience.media_ids?.length) {
				await this.$repositories.spot.addSpotMedias(experience.id, {
					media_ids: experience.media_ids,
				})
			}
			const apiExperience = this.$apiModel.experience.parseOutExperience(experience)
			await this.$repositories.spot.editExperience({
				experienceId: experience.id,
				form: apiExperience,
			})
			await dispatch('getSpot', experience.id)
		} catch (e: any) {
			if (e.response?.data?.errors) {
				e.formErrors = {}
				const invalidName = e.response.data.errors.find((error: any) => {
					return error.code === 'invalid_name'
				})
				if (invalidName) {
					e.formErrors.name = invalidName.detail
				}
				const invaliDescription = e.response.data.errors.find((error: any) => {
					return error.code === 'invalid_description'
				})
				if (invaliDescription) {
					e.formErrors.description = invaliDescription.detail
				}
			}
			await dispatch('error', e, { root: true })
		}
	},
	reset({ commit }) {
		commit('reset')
	},
}

export const getters: GetterTree<SpotModuleState, RootState> = {
	searchResults: (state) => {
		return state.searchResults
	},
	recommendedItineraries: (state) => {
		return state.recommendedItineraries
	},
	history: (state) => {
		return state.history
	},
	spot: (state): Spot | undefined => {
		return state.spot
	},
	spots: (state) => {
		return state.spot?.experiences
	},
	moreSpots: (state) => {
		return state.spot?.experiencesNextPage
	},
}
