import { removeEmptyValues } from '~/models/helpers/removeUndefined'
import Itinerary from '~/models/Itinerary'
import ItineraryGroup from '~/models/ItineraryGroup'
import { BookTrip } from '~/services/Itinerary'

const resource = 'v1/itineraries'

export type SpotsRequest = {
	itineraryId: Itinerary['id']
	page?: number
	pageSize?: number
	code?: string
	itineraryGroupId?: string | null
	categoryIds?: string[]
}

export default (http: any) => ({
	search(data: { q?: string; page?: number; page_size?: number; spot_id?: string }) {
		return http.get(`${resource}/search/`, {
			params: data,
		})
	},
	getCategories() {
		return http.get(`${resource}/categories/`)
	},
	getItinerary({ itineraryId, code }: { itineraryId: string; code?: string }) {
		return http.get(`${resource}/${itineraryId}/`, {
			params: {
				code,
			},
		})
	},
	getItineraryOptions(itineraryId: string) {
		return http.options(`${resource}/${itineraryId}/`)
	},

	getItineraryMarkers({
		itineraryId,
		code,
		groupId,
		categoryIds,
	}: {
		itineraryId: string
		code?: string
		groupId?: string
		categoryIds?: string[]
	}) {
		return http.get(`${resource}/${itineraryId}/markers/`, {
			params: {
				code,
				group_id: groupId,
				category_ids: categoryIds?.join(','),
			},
		})
	},
	createGroup(itineraryId: Itinerary['id'], itineraryGroup: ItineraryGroup) {
		return http.post(`${resource}/${itineraryId}/groups/`, {
			text: itineraryGroup.name,
			color: itineraryGroup.color,
		})
	},
	editGroup(itineraryGroup: ItineraryGroup) {
		return http.patch(`${resource}/${itineraryGroup.itineraryId}/groups/${itineraryGroup.id}`, {
			text: itineraryGroup.name,
			color: itineraryGroup.color,
		})
	},
	editGroupPosition(itineraryGroup: ItineraryGroup, position: number) {
		return http.patch(`${resource}/${itineraryGroup.itineraryId}/groups/${itineraryGroup.id}`, {
			text: itineraryGroup.name,
			order: position,
		})
	},
	deleteGroup(itineraryGroup: ItineraryGroup) {
		return http.delete(`${resource}/${itineraryGroup.itineraryId}/groups/${itineraryGroup.id}/`)
	},
	getItineraryGroups({ itineraryId, code }: { itineraryId: Itinerary['id']; code?: string }) {
		return http.get(`${resource}/${itineraryId}/groups/`, {
			params: {
				code,
			},
		})
	},
	getItineraryDestinations({ itineraryId, code }: { itineraryId: Itinerary['id']; code?: string }) {
		return http.get(`${resource}/${itineraryId}/destinations/`, {
			params: {
				code,
			},
		})
	},
	getItinerarySpots(item: SpotsRequest) {
		return http.get(`${resource}/${item.itineraryId}/spots/`, {
			params: {
				page: item.page,
				page_size: item.pageSize || 10,
				code: item.code,
				group_id: item.itineraryGroupId,
				category_ids: item.categoryIds?.join(','),
			},
		})
	},
	aggregateSpots(
		itineraryId: string,
		spots: {
			spotId: string
			groupsIds?: string[]
			isReference?: boolean
		}[]
	) {
		return http.post(`${resource}/${itineraryId}/spots/`, [
			...spots.map((spot: { spotId: string; groupsIds?: string[]; isReference?: boolean }) => ({
				spot_id: spot.spotId,
				groups_ids: spot.groupsIds,
				is_reference: spot.isReference,
			})),
		])
	},
	deleteSpot(itineraryId: string, spotId: string) {
		return http.delete(`${resource}/${itineraryId}/spots/${spotId}/`)
	},
	getItineraryTravelers({
		itineraryId,
		page,
		code,
	}: {
		itineraryId: string
		page: number
		code: string
	}) {
		return http.get(`${resource}/${itineraryId}/travelers/`, {
			params: {
				page,
				page_size: 10,
				code,
			},
		})
	},
	deleteItineraryTraveler(itineraryId: string, travelerId: string) {
		return http.delete(`${resource}/${itineraryId}/travelers/${travelerId}/`)
	},
	create(form: any) {
		const result = removeEmptyValues(form)
		return http.post(`${resource}/`, result)
	},
	edit(itineraryId: Itinerary['id'], form: any) {
		return http.patch(`${resource}/${itineraryId}/`, form)
	},
	cloneItinerary(itineraryId: string) {
		return http.post(`${resource}/${itineraryId}/clone/`)
	},
	setSpotOrder({
		itineraryId,
		spotId,
		index,
		groupId,
	}: {
		itineraryId: string
		spotId: string
		index: number
		groupId: string | undefined
	}) {
		return http.post(`${resource}/${itineraryId}/spots/${spotId}/order/`, {
			order: index,
			group_id: groupId,
		})
	},
	deleteItinerary(itineraryId: string) {
		return http.delete(`${resource}/${itineraryId}/`)
	},

	invite(itineraryId: string) {
		return http.post(`${resource}/${itineraryId}/travelers/invite/`, {
			role: 'manager',
		})
	},

	acceptInvitation({ itineraryId, code }: { itineraryId: string; code: string }) {
		return http.post(`${resource}/${itineraryId}/travelers/accept_invitation/`, {
			code,
		})
	},
	import({ itineraryId, file }: { itineraryId: string; file: File }) {
		const formData = new FormData()
		formData.append('file', file)
		return http.post(`${resource}/${itineraryId}/spots/import/`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
	},
	getRecomendedSpots({
		itineraryId: _itineraryId,
		page: _page,
		code,
	}: {
		itineraryId: string
		page: number
		code?: string
	}) {
		return http.get(`${resource}/${_itineraryId}/spots/recommendations/`, {
			params: { page_size: 10, _page, code },
		})
	},
	getTrip({
		itineraryId: _itineraryId,
		page: _page,
		code,
	}: {
		itineraryId: string
		page: number
		code?: string
	}) {
		return http.get(`${resource}/${_itineraryId}/trip/`, {
			params: {
				page_size: 10,
				_page,
				code,
			},
		})
	},
	createLeadItinerary(leadId: string) {
		return http.post(`business/lead/${leadId}/itinerary/`)
	},
	getItineraryCategories({ itineraryId, code }: { itineraryId: string; code?: string }) {
		return http.get(`${resource}/${itineraryId}/categories/`, {
			params: {
				code,
			},
		})
	},
	async nestItinerary({ itineraryId, nestedId }: { itineraryId: string; nestedId: string }) {
		return await http.post(`${resource}/${itineraryId}/nest/`, {
			nested_id: nestedId,
		})
	},
	async getBookTripUrl(itineraryId: string, options: BookTrip): Promise<Response> {
		return await http.get(`${resource}/${itineraryId}/book/trip/`, {
			params: {
				start_date: options.startDate,
				end_date: options.endDate,
				origin_id: options.origin,
				destination_id: options.destination,
				engine: options.engine,
			},
		})
	},

	async getItineraryProducts(id: string) {
		return await http.get(`/v1/itineraries/${id}/products/`)
	},
})
