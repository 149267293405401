var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:( _obj = {}, _obj[("width-" + _vm.cols)] = _vm.cols, _obj )},[_c('Col',{staticClass:"width-12",attrs:{"align":_vm.screenSize.xs ? 'center' : undefined}},[_c('div',{staticClass:"width-12",class:{ 'mb-4': !_vm.noMargin, 'box-shadow': _vm.footerShadow }},[_c('a',{staticClass:"overflow-hidden d-flex flex-column border-background-darken-1 relative bg-transparent",class:_vm.itineraryClass,style:({
					width: _vm.cols ? undefined : _vm.screenSize.xs ? _vm.mobileWidth : _vm.desktopWidth,
					aspectRatio: _vm.aspectRatio,
				}),attrs:{"href":_vm.itineraryRoute},on:{"click":function($event){$event.preventDefault();return _vm.goToItinerary(_vm.itinerary)}}},[(_vm.$slots.default)?[_vm._t("default")]:[(_vm.$slots.cover)?[_vm._t("cover")]:[(_vm.itinerary && _vm.covers.length)?_c('MosaicImages',{staticClass:"bg-secondary-25 height-100-per",class:{
								'rounded-2': _vm.screenSize.xs,
							},attrs:{"screen-size":_vm.screenSize,"images":_vm.covers,"scale":2,"main-image-shadow":""}},[(_vm.avatar && _vm.avatar.picture)?_c('div',{staticClass:"absolute d-flex align-center gap-1",style:({
									top: _vm.screenSize.xs ? '120px' : '165px',
									left: '16px',
								})},[_c('Avatar',{attrs:{"screen-size":_vm.screenSize,"src":_vm.avatar.picture,"height":32,"width":32,"border":""}}),_vm._v(" "),_c('div',{staticClass:"text-neutral-25 text-link-xs",class:{ 'detail-mobile': _vm.screenSize.xs }},[_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.avatar.name)+"\n\t\t\t\t\t\t\t\t")])],1):_vm._e()]):(_vm.itinerary && !_vm.covers.length)?_c('EmptyCardItinerary'):_c('PSkeleton')],_vm._v(" "),(_vm.itinerary && _vm.showPrivacity)?[(_vm.itinerary.isPrivate)?_c('PrivateTag',{staticClass:"absolute top-0 left-0 ml-2 mt-2",attrs:{"is-private":_vm.itinerary.isPrivate}}):_vm._e()]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column justify-center",class:{
							'pa-4 pt-3 bg-neutral-25': !_vm.screenSize.xs && !_vm.footerShadow,
							'px-4 py-4 bg-neutral-25': !_vm.screenSize.xs && _vm.footerShadow,
							'px-4 py-4': _vm.screenSize.xs && _vm.footerShadow,
						}},[_c('div',[(_vm.itinerary)?_c('h3',{staticClass:"text-heading-s truncated-1",class:("text-" + _vm.textColor)},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm.itinerary.name)+"\n\t\t\t\t\t\t\t")]):_c('Row',{attrs:{"height":"20px","justify":"center"}},[(!_vm.$slots.cover)?_c('PSkeleton',{staticClass:"rounded-pill",attrs:{"height":16}}):_vm._e()],1)],1)])]],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }