






import Vue from 'vue'

export type InputForm = {
	validate: () => Promise<boolean>
	softValidate: () => Promise<boolean>
	dynamicValidate: boolean
	getValue: () => Record<string, string | number> | undefined
}

export default Vue.extend({
	name: 'Form',
	provide(): {
		form: {
			register: (input: any) => void
			dynamicValidate: boolean
			validate: () => Promise<boolean>
		}
	} {
		return { form: this }
	},
	props: {
		value: {
			type: Boolean,
			default: undefined,
		},
		dynamicValidate: {
			type: Boolean,
			default: false,
		},
		error: {
			type: String,
			default: undefined,
		},
	},
	data() {
		return {
			inputs: [] as InputForm[],
			isValid: undefined as boolean | undefined,
		}
	},
	computed: {
		localValue: {
			get(): boolean {
				return this.value ?? this.isValid
			},
			set(isValid: boolean) {
				if (typeof this.value === 'undefined') {
					this.isValid = isValid
				} else {
					this.isValid = undefined
					this.$emit('input', isValid)
				}
			},
		},
	},
	mounted() {
		this.softValidate()
	},
	methods: {
		async onSubmit() {
			await this.validate()

			if (this.localValue && !this.error) {
				const form = this.getValues()
				this.$emit('submit', form)
			}
		},
		register(input: any) {
			this.inputs.push(input)
		},
		unregister() {
			this.inputs = []
		},
		async validate() {
			let isValid = true
			for (let i = 0; i < this.inputs.length; i++) {
				if (!(await this.inputs[i].validate())) {
					isValid = false
				}
			}
			this.localValue = isValid
			return isValid
		},
		async softValidate() {
			let isValid = true
			for (let i = 0; i < this.inputs.length; i++) {
				if (this.inputs[i].softValidate && !(await this.inputs[i].softValidate())) {
					isValid = false
				}
			}
			this.localValue = isValid
			return isValid
		},
		getValues() {
			let form = {}
			this.inputs.forEach((input) => {
				form = { ...input.getValue(), ...form }
			})
			return form
		},
	},
})
