

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"ca","name":"Català","file":"ca/translations.json"},{"code":"de","name":"Deutsch","file":"de/translations.json"},{"code":"el","name":"Ελληνικά","file":"el-GR/translations.json"},{"code":"en","name":"English (United Kingdom)","file":"en/translations.json"},{"code":"es","name":"Español (España)","file":"es/translations.json"},{"code":"fr","name":"Français","file":"fr/translations.json"},{"code":"it","name":"Italiano","file":"it/translations.json"},{"code":"ja","name":"日本語","file":"ja/translations.json"},{"code":"ko","name":"한국어","file":"ko/translations.json"},{"code":"nl","name":"Dutch","file":"nl-NL/translations.json"},{"code":"pl","name":"Polski","file":"pl/translations.json"},{"code":"pt","name":"Português","file":"pt/translations.json"},{"code":"pt-br","name":"Português (Brasil)","file":"pt-BR/translations.json"},{"code":"ru","name":"Русский","file":"ru/translations.json"},{"code":"tr","name":"Türkçe","file":"tr-TR/translations.json"},{"code":"zh","name":"中文","file":"zh/translations.json"}],
  defaultLocale: "en-gb",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: true,
  langDir: "/srv/www/app/src/locales",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"lang","cookieSecure":false,"fallbackLocale":"en-gb","onlyOnNoPrefix":true,"onlyOnRoot":false,"useCookie":true},
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: false,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"ca","name":"Català","file":"ca/translations.json"},{"code":"de","name":"Deutsch","file":"de/translations.json"},{"code":"el","name":"Ελληνικά","file":"el-GR/translations.json"},{"code":"en","name":"English (United Kingdom)","file":"en/translations.json"},{"code":"es","name":"Español (España)","file":"es/translations.json"},{"code":"fr","name":"Français","file":"fr/translations.json"},{"code":"it","name":"Italiano","file":"it/translations.json"},{"code":"ja","name":"日本語","file":"ja/translations.json"},{"code":"ko","name":"한국어","file":"ko/translations.json"},{"code":"nl","name":"Dutch","file":"nl-NL/translations.json"},{"code":"pl","name":"Polski","file":"pl/translations.json"},{"code":"pt","name":"Português","file":"pt/translations.json"},{"code":"pt-br","name":"Português (Brasil)","file":"pt-BR/translations.json"},{"code":"ru","name":"Русский","file":"ru/translations.json"},{"code":"tr","name":"Türkçe","file":"tr-TR/translations.json"},{"code":"zh","name":"中文","file":"zh/translations.json"}],
  localeCodes: ["ca","de","el","en","es","fr","it","ja","ko","nl","pl","pt","pt-br","ru","tr","zh"],
}

export const localeMessages = {
  'ca/translations.json': () => import('../../src/locales/ca/translations.json' /* webpackChunkName: "lang-ca/translations.json" */),
  'de/translations.json': () => import('../../src/locales/de/translations.json' /* webpackChunkName: "lang-de/translations.json" */),
  'el-GR/translations.json': () => import('../../src/locales/el-GR/translations.json' /* webpackChunkName: "lang-el-GR/translations.json" */),
  'en/translations.json': () => import('../../src/locales/en/translations.json' /* webpackChunkName: "lang-en/translations.json" */),
  'es/translations.json': () => import('../../src/locales/es/translations.json' /* webpackChunkName: "lang-es/translations.json" */),
  'fr/translations.json': () => import('../../src/locales/fr/translations.json' /* webpackChunkName: "lang-fr/translations.json" */),
  'it/translations.json': () => import('../../src/locales/it/translations.json' /* webpackChunkName: "lang-it/translations.json" */),
  'ja/translations.json': () => import('../../src/locales/ja/translations.json' /* webpackChunkName: "lang-ja/translations.json" */),
  'ko/translations.json': () => import('../../src/locales/ko/translations.json' /* webpackChunkName: "lang-ko/translations.json" */),
  'nl-NL/translations.json': () => import('../../src/locales/nl-NL/translations.json' /* webpackChunkName: "lang-nl-NL/translations.json" */),
  'pl/translations.json': () => import('../../src/locales/pl/translations.json' /* webpackChunkName: "lang-pl/translations.json" */),
  'pt/translations.json': () => import('../../src/locales/pt/translations.json' /* webpackChunkName: "lang-pt/translations.json" */),
  'pt-BR/translations.json': () => import('../../src/locales/pt-BR/translations.json' /* webpackChunkName: "lang-pt-BR/translations.json" */),
  'ru/translations.json': () => import('../../src/locales/ru/translations.json' /* webpackChunkName: "lang-ru/translations.json" */),
  'tr-TR/translations.json': () => import('../../src/locales/tr-TR/translations.json' /* webpackChunkName: "lang-tr-TR/translations.json" */),
  'zh/translations.json': () => import('../../src/locales/zh/translations.json' /* webpackChunkName: "lang-zh/translations.json" */),
}
