import { Plugin } from '@nuxt/types'
import createService from '~/services/Service'

declare module 'vue/types/vue' {
	interface Vue {
		$services: ReturnType<typeof createService>
	}
}
declare module '@nuxt/types' {
	interface NuxtAppOptions {
		$services: ReturnType<typeof createService>
	}
	interface Context {
		$services: ReturnType<typeof createService>
	}
}
declare module 'vuex/types/index' {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	interface Store<S> {
		$services: ReturnType<typeof createService>
	}
}

const servicePlugin: Plugin = (context, inject) => {
	inject('services', createService(context.$repositories, context.$apiModel))
}

export default servicePlugin
