































import Vue from 'vue'
export default Vue.extend({
	name: 'PIconChevron',
	props: {
		next: {
			type: Boolean,
			default: false,
		},
		up: {
			type: Boolean,
			default: false,
		},
		down: {
			type: Boolean,
			default: false,
		},
		previous: {
			type: Boolean,
			default: false,
		},
		width: {
			type: [Number, String],
			default: 24,
		},
		height: {
			type: [Number, String],
			default: 24,
		},
		strokeWidth: {
			type: Number,
			default: 2,
		},
	},
})
