





























import Vue, { PropOptions } from 'vue'
import { mapGetters } from 'vuex'
import PIconClose from '~/components/PassporterUI/Icon/PIconClose.vue'

export type ToastPositions =
	| 'top'
	| 'bottom'
	| 'top-right'
	| 'top-left'
	| 'bottom-left'
	| 'bottom-right'
export type ToastIcons = 'error' | 'success' | 'delete' | 'clip' | 'broken-heart' | 'sad' | 'none'

export default Vue.extend({
	name: 'Toast',
	components: { PIconClose },
	props: {
		fixed: {
			type: Boolean,
			default: false,
		},
		position: {
			type: String,
			default: 'bottom-right',
		} as PropOptions<ToastPositions>,
		icon: {
			type: String,
			default: 'success',
		} as PropOptions<ToastIcons>,
		transition: {
			type: String,
			default: undefined,
		},
		closable: {
			type: Boolean,
			default: true,
		},
		index: {
			type: Number,
			default: 0,
		},
		stackOffset: {
			type: Number,
			default: 10,
		},
	},
	data() {
		return {
			open: true,
			height: 0,
		}
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
		}),
		toastIcon(): string | null {
			const iconOptions: { [key in ToastIcons]: string | null } = {
				'error': 'close.webp',
				'clip': '📎.webp',
				'none': null,
				'delete': '🗑.webp',
				'success': '🎉.webp',
				'broken-heart': '💔.webp',
				'sad': '😔.webp',
			}
			return iconOptions[this.icon]
		},
		classes(): string {
			const positionOptions: { [key in ToastPositions]: string } = {
				'top': 'toast-top',
				'bottom': 'toast-bottom',
				'top-right': 'toast-top-right',
				'top-left': 'toast-top-left',
				'bottom-right': 'toast-bottom-right',
				'bottom-left': 'toast-bottom-left',
			}
			return positionOptions[this.position]
		},
		positionStyles(): any {
			const tbMobileOffset = this.screenSize.xs ? 70 : 10
			const bottomLR = {
				bottom: `${38 + this.stackOffset * this.index + this.height * this.index}px`,
			}
			const topLR = {
				top: `${80 + this.stackOffset * this.index + this.height * this.index}px`,
			}
			const positionOptions: { [key in ToastPositions]: any } = {
				'top': {
					top: `${tbMobileOffset + this.stackOffset * this.index + this.height * this.index}px`,
				},
				'bottom': {
					bottom: `${tbMobileOffset + this.stackOffset * this.index + this.height * this.index}px`,
				},
				'top-right': topLR,
				'top-left': topLR,
				'bottom-right': bottomLR,
				'bottom-left': bottomLR,
			}
			return this.fixed
				? positionOptions[this.position]
				: { position: 'static', width: '100%', maxWidth: '100%' }
		},
	},
	beforeDestroy() {
		this.$nuxt.$off('force-toast-close', this.close)
	},
	mounted() {
		this.$nuxt.$on('force-toast-close', this.close)
		if (this.$refs.toast) {
			this.height = (this.$refs.toast as HTMLDivElement)?.clientHeight ?? 78
		}
	},
	methods: {
		close() {
			this.$emit('close')
		},
	},
})
