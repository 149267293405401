import DestinationCategory from '~/models/DestinationCategory'
import CivitatisActivity from '~/models/CivitatisActivity'
import Price from '~/models/Price'
import Currency from '~/models/Currency'

const parseDestinationCategory = (category: any) => {
	return new DestinationCategory({
		id: category.id,
		name: category.name,
	})
}
const parseDestinationCategories = (categories: any[]) => {
	return categories.map((category) => parseDestinationCategory(category))
}

const parseDestinationWeatherDay = (weatherDay: any) => {
	const tempFormat = (temp: number): number => {
		return Math.round(temp || 0)
	}
	return {
		date: weatherDay.date,
		type: weatherDay.weather_type,
		min: tempFormat(weatherDay.min_temp || weatherDay.temp),
		max: tempFormat(weatherDay.max_temp || weatherDay.temp),
	}
}
const parseDestinationWeather = (weather: any[]) => {
	return weather.map((weatherDay) => parseDestinationWeatherDay(weatherDay))
}

interface ApiCivitatisDestination {
	id: string
	title: string
	description: string
	url: string
	photo: string
	score: number
	reviews: number
	price: number
	currency: string
}

const parseDestinationCivitatisActivity = (
	civitatisDestination: ApiCivitatisDestination
): CivitatisActivity => {
	return new CivitatisActivity({
		id: civitatisDestination.id,
		title: civitatisDestination.title,
		description: civitatisDestination.description,
		cover: civitatisDestination.photo,
		score: civitatisDestination.score,
		reviewsCount: civitatisDestination.reviews,
		link: civitatisDestination.url,
		price:
			civitatisDestination.price && civitatisDestination.currency
				? new Price({
						value: civitatisDestination.price,
						currency: new Currency({ code: civitatisDestination.currency }),
				  })
				: undefined,
	})
}
const parseDestinationCivitatis = (civitatis: ApiCivitatisDestination[]): CivitatisActivity[] => {
	return civitatis.map((civitatisDestination) =>
		parseDestinationCivitatisActivity(civitatisDestination)
	)
}

export default () => ({
	parseDestinationCategory,
	parseDestinationCategories,
	parseDestinationWeatherDay,
	parseDestinationWeather,
	parseDestinationCivitatisActivity,
	parseDestinationCivitatis,
})
