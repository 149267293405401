import { Context } from '@nuxt/types'
import { RouteBuilder } from './RouterBuilder'

export default (context: Context) => ({
	getSpotRoute(
		spotId: string,
		options?: {
			itineraryId?: string
			destinationId?: string
			guideId?: string
		}
	): string {
		const itineraryId = options?.itineraryId
		const destinationId = options?.destinationId
		const guideId = options?.guideId

		const routeBuilder = new RouteBuilder(context.i18n.locale)

		if (itineraryId) {
			routeBuilder.addItinerary(itineraryId)
		}

		if (destinationId) {
			routeBuilder.addDestination(destinationId)
		}

		if (guideId) {
			routeBuilder.addGuide(guideId)
		}
		if (spotId) {
			routeBuilder.addSpot(spotId)
		}

		return routeBuilder.build()
	},
})
