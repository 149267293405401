import Link from './Link'

export class SiteInfo {
	id: string
	title: string
	summary: string
	description?: string
	icon?: string
	links?: Link[]

	constructor(siteInfo: SiteInfo) {
		this.id = siteInfo.id
		this.title = siteInfo.title
		this.summary = siteInfo.summary
		this.description = siteInfo.description
		this.icon = siteInfo.icon
		this.links = siteInfo.links
	}
}
