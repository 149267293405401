import Marker, { Marker2 } from './Marker'
import Traveler from './Traveler'
import Note from './Note'
import ItineraryGroup from './ItineraryGroup'
import ItinerarySpot, { ItinerarySpot2 } from './ItinerarySpot'
import Destination, { Destination2 } from './Destination'
import Spot, { Spot2 } from './Spot'
import BookMark from './BookMark'
import Agency from './Agency'
import ItineraryCategory from './ItineraryCategory'
import { serialize } from './helpers/serialize'

export type AccessType = undefined | 'code'

export type Itinerary2 = {
	id: string | null
	title?: string
	referenceType: 'itinerary'
	access?: AccessType
	travelersPictures?: string[]
	numDays?: number
	numNotes?: number
	numTravelers?: number
	startDate?: string
	endDate?: string
	name?: string
	numDestinations?: number
	destinations?: Destination2[]
	destinationsNextPage?: number
	numSpots?: number
	isPrivate?: boolean
	covers?: string[]
	invitationCode?: string
	markers?: Marker2[]
	spots?: ItinerarySpot2[]
	spotsNextPage?: number
	travelers?: Traveler[]
	notes?: Note[]
	notesNextPage?: number
	isEditable?: boolean
	isVisible?: boolean
	isRemovable?: boolean
	isTrip?: boolean
	isDiscover?: boolean
	isBookings?: boolean
	groups?: ItineraryGroup[]
	spotsGroupId?: string | null
	recommendedSpots?: Spot2[]
	recommendedSpotsNextPage?: number
	blogs?: BookMark[]
	videos?: BookMark[]
	role?: string
	agencyId?: string
	agency?: Agency
	categories?: ItineraryCategory[]
	cover?: string | null
	creatorId?: string | null
	creator?: Traveler | null
	numViews?: number
}

export default class Itinerary {
	id: string | null
	title?: string
	referenceType?: string
	access?: AccessType
	travelersPictures?: string[]
	numDays?: number
	numNotes?: number
	numTravelers?: number
	startDate?: string
	endDate?: string
	name?: string
	numDestinations?: number
	destinations?: Destination[]
	destinationsNextPage?: number
	numSpots?: number
	isPrivate?: boolean
	covers?: string[]
	invitationCode?: string
	markers?: Marker[]
	spots?: ItinerarySpot[]
	spotsNextPage?: number
	travelers?: Traveler[]
	notes?: Note[]
	notesNextPage?: number
	isEditable?: boolean
	isVisible?: boolean
	isRemovable?: boolean
	isTrip?: boolean
	isDiscover?: boolean
	isBookings?: boolean
	groups?: ItineraryGroup[]
	spotsGroupId?: string | null
	recommendedSpots?: Spot[]
	recommendedSpotsNextPage?: number
	blogs?: BookMark[]
	videos?: BookMark[]
	role?: string
	agencyId?: string
	agency?: Agency
	categories?: ItineraryCategory[]
	cover?: string | null
	creatorId?: string | null
	creator?: Traveler | null
	numViews?: number
	constructor(itinerary?: Itinerary) {
		this.id = itinerary?.id || null
		this.referenceType = 'itinerary'
		this.access = itinerary?.access
		this.travelersPictures = itinerary?.travelersPictures || []
		this.numDays = itinerary?.numDays
		this.numNotes = itinerary?.numNotes
		this.numTravelers = itinerary?.numTravelers || 1
		this.startDate = itinerary?.startDate
		this.endDate = itinerary?.endDate
		this.name = itinerary?.name || ''
		this.numDestinations = itinerary?.numDestinations || 0
		this.destinations = serialize(Destination, itinerary?.destinations)
		this.destinationsNextPage = itinerary?.destinationsNextPage
		this.numSpots = itinerary?.numSpots || 0
		this.isPrivate =
			itinerary?.isPrivate === undefined ? false : itinerary.isPrivate
		this.covers = itinerary?.covers || []
		this.invitationCode = itinerary?.invitationCode
		this.markers = serialize(Marker, itinerary?.markers)
		this.spots = serialize(ItinerarySpot, itinerary?.spots)
		this.spotsNextPage = itinerary?.spotsNextPage
		this.travelers = serialize(Traveler, itinerary?.travelers)
		this.notes = serialize(Note, itinerary?.notes)
		this.notesNextPage = itinerary?.notesNextPage
		this.isEditable = itinerary?.isEditable
		this.isVisible = itinerary?.isVisible
		this.isRemovable = itinerary?.isRemovable
		this.isTrip = itinerary?.isTrip
		this.isDiscover = itinerary?.isDiscover
		this.isBookings = itinerary?.isBookings
		this.groups = serialize(ItineraryGroup, itinerary?.groups)
		this.spotsGroupId = itinerary?.spotsGroupId || null
		this.recommendedSpots = serialize(Spot, itinerary?.recommendedSpots)
		this.recommendedSpotsNextPage = itinerary?.recommendedSpotsNextPage
		this.blogs = serialize(BookMark, itinerary?.blogs)
		this.videos = serialize(BookMark, itinerary?.videos)
		this.role = itinerary?.role
		this.agencyId = itinerary?.agencyId
		this.agency = serialize(Agency, itinerary?.agency)
		this.categories = serialize(ItineraryCategory, itinerary?.categories)
		this.cover = itinerary?.cover || null
		this.creatorId = itinerary?.creatorId || null
		this.creator = serialize(Traveler, itinerary?.creator || null)
		this.numViews = itinerary?.numViews || 0
	}
}

export interface ItineraryForm {
	id: string | null
	name?: string
	startDate?: string | null
	endDate?: string | null
	isPrivate: boolean
	showDate: boolean
	destination: Destination2
	option?: string
	numDays?: number
	categoryIds?: string[]
	coverId: string | null
	recommendedSpots?: boolean
}
