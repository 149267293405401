import BaseService from '~/services/BaseService'

export default class BusinessService extends BaseService {
	async createLeadItinerary(leadId: string): Promise<string | undefined> {
		try {
			const res = await this.repositories.business.createLeadItinerary(leadId)
			const { data } = res
			return data.id
		} catch (e) {}
	}
}
