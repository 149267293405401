interface ItineraryGroupParams {
	id?: string
	color?: string
	lightColor?: string
	itineraryId?: string
	name?: string
}

export default class ItineraryGroup {
	id: string | null
	color?: string
	lightColor?: string
	itineraryId?: string
	name: string
	constructor(itineraryGroup?: ItineraryGroupParams | ItineraryGroup) {
		this.color = itineraryGroup?.color
		this.lightColor = itineraryGroup?.lightColor
		this.id = itineraryGroup?.id ?? null
		this.itineraryId = itineraryGroup?.itineraryId
		this.name = itineraryGroup?.name ?? ''
	}
}
