

















import Vue, { PropOptions } from 'vue'
import ResponsiveData from '~/models/Responsive'
import Chip from '~/components/PassporterUI/Chip.vue'
import Cover from '~/components/PassporterUI/Cover.vue'

export default Vue.extend({
	name: 'ProviderAvatar',
	components: { Chip, Cover },
	props: {
		urlToProvider: {
			type: String,
			default: '',
		},
		name: {
			type: String,
			default: '',
		},
		avatar: {
			type: String,
			default: '',
		},
		screenSize: {
			type: Object,
			default: undefined,
		} as PropOptions<ResponsiveData>,
	},
})
