import { Context } from '@nuxt/types'

export default (context: Context) => ({
	getHomeRoute(): string {
		return `/${context.i18n.locale}/home`
	},

	loginRoute() {
		return `/${context.i18n.locale}/auth/login`
	},

	openNoCodePage(redirect?: any, query?: any) {
		let passporterNocode = '/auth/login'
		if (process.env.ENVIRONMENT === 'production') {
			passporterNocode = 'https://passporterapp.com'
		}

		let queryString = ''
		if (query) {
			queryString = new URLSearchParams(query).toString()
		}

		if (queryString) {
			passporterNocode += `?${queryString}`
		}

		if (redirect) {
			redirect(passporterNocode)
		} else {
			window.location.href = passporterNocode
		}
	},
})
