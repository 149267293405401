export class AbTestService {
	repository: any
	constructor(repository: any) {
		this.repository = repository
	}

	async getUserGroups(): Promise<any> {
		try {
			const res = await this.repository.getUserGroups()
			const { data } = res
			return data
		} catch (e) {
			console.error(e)
			return {}
		}
	}
}
