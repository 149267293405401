



























import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
	name: 'ListItem',
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		selected: {
			type: Boolean,
			default: false,
		},
		textColor: {
			type: String,
			default: 'text-neutral-900',
		},
		backgroundColor: {
			type: String,
			default: undefined,
		},
		hoverColor: {
			type: String,
			default: 'neutral-100',
		},
		minHeight: {
			type: [String, Number],
			default: 48,
		},
		gap: {
			type: [String, Number],
			default: undefined,
		},
	},
	computed: {
		...mapGetters({
			theme: 'theme',
		}),
		styles(): { color: string; minHeight: string; gap: string } {
			return {
				color: this.disabled ? this.disabledColor : this.textColor,
				gap: `${this.gap}px`,
				minHeight: `${this.minHeight}px`,
			}
		},
		hoverColorValue(): string | undefined {
			return 'var(--color-' + this.hoverColor + ')'
		},
		disabledColor(): string {
			return (
				(this.textColor.includes('error') && this.theme.current.colors.error['100']) ||
				(this.textColor.includes('primary') && this.theme.current.colors.primary['50']) ||
				(this.textColor.includes('neutral') && this.theme.current.colors.neutral['200'])
			)
		},
		isHexTextColor(): boolean {
			return this.isHexadecimal(this.textColor)
		},
		isHexBgColor(): boolean {
			return this.isHexadecimal(this.backgroundColor)
		},
	},
	methods: {
		isHexadecimal(color: string) {
			const hexadecimal = /^#(([a-fA-F0-9]{2}){2,4}|[a-fA-F0-9]{3})$/
			return hexadecimal.test(color)
		},
	},
})
