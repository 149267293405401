import Agency from '~/models/Agency'

const parseAgency = (itinerary: any) => {
	return new Agency({
		id: itinerary.id,
		logo: itinerary.logo,
		name: itinerary.name,
	})
}
export default () => ({
	parseAgency,
})
