








































































































































































































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import ChipGroup from '~/components/UI/ChipGroup.vue'
import SearcherCategory from '~/models/SearcherCategory'
import InputText from '~/components/PassporterUI/InputText.vue'
import Col from '~/components/UI/Col.vue'
import Row from '~/components/UI/Row.vue'
import Spinner from '~/components/PassporterUI/Spinner.vue'
import Divider from '~/components/PassporterUI/Divider.vue'
import Dialog from '~/components/UI/Dialog.vue'

let timeout: any = null

export default Vue.extend({
	name: 'SearchSelect',
	components: {
		Dialog,
		Divider,
		Row,
		Col,
		InputText,
		ChipGroup,
		Spinner,
	},
	props: {
		label: {
			type: String,
			default: undefined,
		},
		placeholder: {
			type: String,
			default: undefined,
		},
		items: {
			type: Array,
			required: true,
		},
		history: {
			type: Array,
			default: () => [],
		},
		itemText: {
			type: String,
			default: null,
		},
		itemValue: {
			type: String,
			default: null,
		},
		search: {
			type: Function,
			required: true,
		},
		categories: {
			type: Array,
			default: () => [],
		},
		input: {
			type: Boolean,
			default: false,
		},
		form: {
			type: Boolean,
			default: true,
		},
		showIcon: {
			type: Boolean,
			default: false,
		},
		returnObject: {
			type: Boolean,
			default: false,
		},
		value: {
			type: [Object, String],
			default: null,
		},
		error: {
			type: String,
			default: undefined,
		},
		hideMessages: {
			type: Boolean,
			default: false,
		},
		v2: {
			type: Boolean,
			default: false,
		},
		level: {
			type: Number,
			default: undefined,
		},
		small: {
			type: Boolean,
			default: undefined,
		},
	},
	data() {
		return {
			uid: '',
			searchText: undefined as string | undefined,
			loading: false,
			categoryId: undefined as string | undefined,
			localValue: null,
			showInitialNextPageBtn: true,
			focused: false,
			keepingFocused: false,
			localSelected: undefined,
		}
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
			paginationData: 'searcher/paginationData',
			isLoadingPagination: 'searcher/isLoadingPagination',
		}),
		selected: {
			get(): any {
				if (this.input) {
					return this.value || this.localSelected
				} else {
					return this.localValue
				}
			},
			set(newValue: any) {
				this.localSelected = newValue
				if (this.input) {
					this.localValue = newValue
					this.$emit('input', this.returnObject ? newValue : newValue[this.itemValue])
				} else {
					this.searchText = undefined
				}
				this.$emit('change', {
					item: this.returnObject ? newValue : newValue[this.itemValue],
					category: this.category,
				})
				this.focusout()
			},
		},
		selectedText(): any {
			if (!this.selected || typeof this.selected === 'string') {
				return this.selected
			} else {
				return (this.selected as any)[this.itemText]
			}
		},
		options(): any[] {
			let options: any = []
			if (this.searchText) {
				options = options.concat(this.items)
			} else {
				options = options.concat(this.historyList)
			}

			if (this.loading) {
				options = [{ disabled: true, loader: true }]
			} else if (!options.length && this.categories) {
				if ((this.searchText?.length ?? 0) >= 3) {
					options.push({
						disabled: true,
						name: '',
					})
				} else {
					options.push({
						disabled: true,
						name: this.$t('Search_Common_Emptycase'),
					})
				}
			}
			return options
		},
		historyList(): any[] {
			if (this.history.length) {
				return [{ header: this.$t('D_1_Search_Recentsearches') }, ...this.history]
			} else {
				return []
			}
		},
		category(): any {
			return this.categories.find((item: any) => item.id === this.categoryId)
		},
		isXs(): any {
			return this.screenSize.xs
		},
		showEmptyAction(): boolean {
			const hasItems = this.items && this.items.length > 1
			return !hasItems || !this.searchText
		},
	},
	watch: {
		categoryId(categoryId, oldValue) {
			const category: SearcherCategory | undefined = (this.categories as SearcherCategory[]).find(
				(item: SearcherCategory) => item.id === categoryId
			)
			this.launchSearch('', category)
			const autocomplete: any = this.$refs.autocomplete
			if (oldValue) {
				autocomplete.focus()
			}
			this.launchSearch(this.searchText, category)
		},
		options() {
			this.resizeOptions()
		},
	},
	mounted() {
		this.launchSearch('', this.category)
		this.showInitialNextPageBtnComputed()
	},
	methods: {
		onSearchKeyup() {
			this.showInitialNextPageBtn = true
			this.launchSearch(this.searchText, this.category)
		},
		onSelect(item: any) {
			if (!item.disabled) {
				;(this.$refs.autocomplete as any)?.focusout()
				this.selected = this.returnObject ? item : item[this.itemValue]
			}
		},
		nextPage() {
			if (this.showInitialNextPageBtn) {
				this.$store.commit('searcher/setLoadingPagination', true)
				setTimeout(() => {
					this.$store.dispatch('searcher/paginationSearch', {
						searchText: this.searchText,
					})

					this.showInitialNextPageBtn = false
				}, 300)
			} else {
				this.$store.commit('searcher/setLoadingPagination', true)
				this.paginationData.pagination.nextPage()
			}
		},
		clear() {
			this.selected = undefined
			this.searchText = undefined
			const autocomplete: any = this.$refs.autocomplete
			if (autocomplete.clearableCallback) {
				autocomplete.clearableCallback()
			}
		},
		launchSearch(searchText: string | undefined, category: SearcherCategory | undefined) {
			this.loading = true
			if (timeout) {
				clearTimeout(timeout)
			}
			timeout = setTimeout(async () => {
				await this.search(searchText, category)
				this.loading = false
			}, 400)
		},
		showInitialNextPageBtnComputed(): any {
			return (
				this.$router.currentRoute.fullPath.includes('itineraries/') &&
				this.showInitialNextPageBtn &&
				this.options.length >= 5
			)
		},
		focus() {
			this.focused = true
			this.$emit('focus')
			// @ts-ignore
			if (this.isXs) {
				this.$nextTick(() => {
					this.keepFocus()
					;(this.$refs['searcher-mobile'] as HTMLInputElement)?.focus()
				})
			} else {
				this.resizeOptions()
			}
		},
		focusout(): void {
			if (!this.keepingFocused) {
				this.focused = false
				this.$emit('focusout')
				this.searchText = undefined
			} else {
				// @ts-ignore
				if (this.isXs) {
					;(this.$refs['searcher-mobile'] as HTMLInputElement)?.focus()
				} else {
					;(this.$refs.autocomplete as any)?.focus()
				}
				this.keepingFocused = false
			}
		},
		keepFocus(): void {
			this.keepingFocused = true
		},
		resizeOptions() {
			if (this.screenSize.xs || this.v2) return

			this.$nextTick(() => {
				const searcherOptions = this.$refs['searcher-options'] as HTMLDivElement

				if (!searcherOptions) return

				searcherOptions.style.height = 'auto'

				const searcherOptionsRect = searcherOptions.getBoundingClientRect()
				const searcherOptionsPosition = searcherOptionsRect.top
				const searcherOptionsHeight = searcherOptions.offsetHeight
				const searcherOptionEndPosition = searcherOptionsPosition + searcherOptionsHeight
				const windowHeight = window.innerHeight

				const offsetBottom = 20
				const isOutOfTheWindow = searcherOptionEndPosition > windowHeight - offsetBottom
				if (isOutOfTheWindow) {
					searcherOptions.style.height = `${
						windowHeight - searcherOptionsPosition - offsetBottom
					}px`
				}
			})
		},
	},
})
