






























import Vue from 'vue'
import { mapGetters } from 'vuex'
import Col from '~/components/UI/Col.vue'
import IconPlus from '~/components/UI/Icons/IconPlus.vue'
import IconCamera from '~/components/UI/Icons/IconCamera.vue'
import Cover from '~/components/PassporterUI/Cover.vue'

export default Vue.extend({
	name: 'EditItineraryCover',
	components: {
		Col,
		IconPlus,
		IconCamera,
		Cover,
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
			pendingMedias: 'media/pendingMedias',
			itinerary: 'itinerary/itinerary',
		}),
		coverSource() {
			let source = ''
			if (this.pendingMedias[0]) {
				source = this.pendingMedias[0].url
			} else {
				source = this.itinerary.cover
			}

			return source
		},
	},

	methods: {
		callInput() {
			const input = this.$refs.file as HTMLInputElement
			input.click()
		},
		async uploadFile() {
			this.$store.dispatch('media/clearPendingMedias')
			const fileInput = this.$refs.file as HTMLFormElement
			const fileUrl = fileInput.files[0]
			await this.$store.dispatch('media/prepareFile', fileUrl)
		},
	},
})
