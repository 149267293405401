import itineraryModel from '~/apiModels/ItineraryModel'
import destinationModel from '~/apiModels/DestinationModel'
import spotModel from '~/apiModels/SpotModel'
import travelerModel from '~/apiModels/TravelerModel'
import experienceModel from '~/apiModels/ExperienceModel'
import mediaModel from '~/apiModels/MediaModel'
import NoteModel from '~/apiModels/NoteModel'
import AgencyModel from '~/apiModels/AgencyModel'
import GuideModel from '~/apiModels/GuideModel'
import ProductModel from '~/apiModels/ProductModel'
import categoryModel from '~/apiModels/CategoryModel'

const spot = spotModel()
const itinerary = itineraryModel({ spot })
const guide = GuideModel()
const destination = destinationModel()
const product = ProductModel()
const category = categoryModel()
const traveler = travelerModel()
const experience = experienceModel()
const media = mediaModel()
const note = NoteModel()
const agency = AgencyModel()

export default () => ({
	itinerary,
	destination,
	spot,
	traveler,
	experience,
	media,
	note,
	agency,
	guide,
	product,
	category,
})
