





















































import Vue from 'vue'
import InputRadio from '~/components/UI/InputRadio.vue'
import Row from '~/components/UI/Row.vue'
import Col from '~/components/UI/Col.vue'
export default Vue.extend({
	name: 'ItineraryPrivatePolicy',
	components: {
		InputRadio,
		Row,
		Col,
	},
	props: {
		value: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			private: this.value,
		}
	},
	computed: {
		updateSelection: {
			get(): boolean {
				return this.private
			},
			set(value: boolean): void {
				this.private = value
				this.$emit('update:privacy', value)
			},
		},
	},
})
