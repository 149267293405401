
















































import Vue, { PropOptions } from 'vue'
import { mapGetters } from 'vuex'
import ReferenceButton from '~/components/context/items/spots/ReferenceButton.vue'
import SaveSpotButton from '~/components/context/items/spots/SaveSpotButton.vue'
import EmptyState from '~/components/PassporterUI/EmptyState.vue'
import ImagesCarousel from '~/components/UI/ImagesCarousel.vue'
import Spinner from '~/components/PassporterUI/Spinner.vue'
import CivitatisActivity from '~/models/CivitatisActivity'
import Itinerary from '~/models/Itinerary'
import { Marker2 as Marker } from '~/models/Marker'
import Media from '~/models/Media'
import PlannerSpot from '~/models/PlannerSpot'
import { Spot2 as Spot } from '~/models/Spot'

export default Vue.extend({
	name: 'SpotMapPopup',
	components: {
		Spinner,
		EmptyState,
		ReferenceButton,
		SaveSpotButton,
		ImagesCarousel,
	},
	props: {
		spot: {
			type: Object,
			default: null,
		} as PropOptions<Spot>,
		marker: {
			type: Object,
			default: null,
		} as PropOptions<Marker>,
		itinerary: {
			type: Object,
			default: null,
		} as PropOptions<Itinerary>,
		showFlag: {
			type: Boolean,
			default: false,
		},
		guideData: {
			type: Object,
			default: null,
		},
		openPopup: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters({
			user: 'auth/user',
			screenSize: 'screenSize',
		}),
		images(): string[] | undefined {
			const images: string[] = []
			if (!(this.data instanceof CivitatisActivity) && this.data?.medias?.length) {
				this.data.medias.slice(0, 3).forEach((element: Media) => {
					images.push(element.url)
				})
				return images
			} else if (this.data?.cover) {
				return [this.data.cover]
			} else {
				return undefined
			}
		},
		referenceType(): string | undefined {
			return this.data?.referenceType
		},
		isSmall(): string {
			return this.screenSize.xs ? '110px' : '175px'
		},
		data(): Spot | PlannerSpot | CivitatisActivity | undefined {
			return this.marker ? this.marker.data : this.spot
		},
		isOwner(): boolean {
			return this.itinerary?.isEditable ?? false
		},
	},
	methods: {
		handleClick(): void {
			if (this.openPopup) {
				this.$nuxt.$emit('open-spotInfoDialog', this.data)
				return
			}
			this.guideData ? this.goToGuideSpot() : this.goToSpot()
		},
		goToGuideSpot(): void {
			if (!this.user) {
				this.$nuxt.$emit('open-register')
				return
			}

			const payload = {
				destinationId: this.guideData.destinationId,
				guideId: this.guideData.guideId,
				spotId: this.data?.id,
				detail: true,
			}
			this.$store.dispatch('spot/openSpotGuide', payload)
		},
		goToSpot(): void {
			if (!this.user) {
				this.$nuxt.$emit('open-register')
				return
			}
			const payload = {
				spot: this.data,
				itinerary: this.itinerary,
				detail: true,
			}

			this.$store.dispatch('spot/openSpot', payload)
		},
	},
})
