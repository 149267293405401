







import Vue from 'vue'

import { mapGetters } from 'vuex'
import RecoveryPassword from '~/components/context/auth/dialog/RecoveryPassword.vue'
import ProfileEdit from '~/components/context/auth/dialog/ProfileEdit.vue'

export default Vue.extend({
	name: 'AuthDialog',
	components: {
		ProfileEdit,
		RecoveryPassword,
	},
	computed: {
		...mapGetters({
			isAuthenticated: 'auth/isAuthenticated',
			user: 'auth/user',
		}),
	},
	mounted() {
		const action: string = this.$route.query.action as string
		const target: string = this.$route.query.target as string
		if (action && action === 'modal' && target && !this.$store.getters['auth/isAuthenticated']) {
			if (target === 'login') {
				this.openAuth()
			} else if (target === 'register') {
				this.openAuth()
			}
		}
	},
	methods: {
		openAuth() {
			this.$nuxt.$emit('open-auth')
		},
		openRecoveryPassword(code: string, email: string) {
			const recoveryPasswordDialog: any = this.$refs.recoveryPasswordDialog
			recoveryPasswordDialog.open({ code, email })
		},
		openProfileEdit() {
			const profileEditDialog: any = this.$refs.profileEdit
			profileEditDialog.open()
		},
	},
})
