


















import Vue from 'vue'

export default Vue.extend({
	name: 'PIconPhone',
	props: {
		width: {
			type: [Number, String],
			default: 24,
		},
		height: {
			type: [Number, String],
			default: 24,
		},
		strokeWidth: {
			type: Number,
			default: 2,
		},
	},
})
