
import Vue from 'vue'

export default Vue.extend({
	name: 'StoreInit',
	mounted() {
		window.addEventListener('scroll', this.scrollTrigger, { passive: true })
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.scrollTrigger)
	},
	methods: {
		scrollTrigger() {
			const scrollTop = document.querySelector('html')?.scrollTop
			this.$store.dispatch('setScrollPosition', scrollTop)
		},
	},
	render(): any {
		return ''
	},
})
