










import Vue from 'vue'

export default Vue.extend({
	name: 'POverlay',
	props: {
		zIndex: {
			type: String,
			default: '5',
		},
	},
	computed: {
		style() {
			return {
				zIndex: this.zIndex,
			}
		},
	},
})
