export class RouteBuilder {
	route
	constructor(locale: string) {
		this.route = `/${locale}`
	}

	addItinerary(itineraryId: string) {
		this.route += `/itineraries/${itineraryId}`
		return this
	}

	addDestination(destinationId: string) {
		this.route += `/destinations/${destinationId}`
		return this
	}

	addGuide(guideId: string) {
		this.route += `/guides/${guideId}`
		return this
	}

	addSpot(spotId: string) {
		this.route += `/spots/${spotId}`
		return this
	}

	build() {
		return this.route
	}
}
