export default class Link {
	id?: string
	url: string
	title?: string
	description?: string
	coverUrl?: string

	constructor(content: Link) {
		this.id = content.id
		this.url = content.url
		this.title = content.title
		this.description = content.description
		this.coverUrl = content.coverUrl
	}
}
