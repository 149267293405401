import Spot, { IBooking } from '~/models/Spot'
import GPlace from '~/models/GPlace'

const parseSpot = (spot: any) => {
	return new Spot({
		address: spot.address,
		cover: spot.cover,
		description: spot.description,
		id: spot.id,
		location: spot.location,
		name: spot.name,
		numTravelers: spot.num_travelers,
		isPrivate: spot.state === 'private',
		travelersPictures: spot.travelers_pictures,
		type: spot.type,
		ownerId: spot.owner_id,
		parentSpotId: spot.parent_spot_id,
		visited: spot.visited,
		placeId: spot.place_id,
		parentId: spot.parent_id,
		mapMarker: spot.map_marker,
	})
}
const parseSpots = (spots: any[]) => {
	return spots.map((spot) => parseSpot(spot))
}
const parseGooglePlacePredictions = (predictions: any[]) => {
	return predictions.map((prediction) => parseGooglePlacePrediction(prediction))
}

const parseGooglePlacePredictionsPagination = (predictions: any[]) => {
	return predictions.map((prediction) => parseGooglePlacePredictionPagination(prediction))
}
const parseGooglePlaceDetails = (place: any) => {
	return {
		name: place.name,
	}
}
const parseGooglePlacePrediction = (prediction: any) => {
	return new GPlace({
		placeId: prediction.place_id,
		location: prediction.geometry
			? {
					longitude: prediction.geometry.location.lng,
					latitude: prediction.geometry.location.lat,
			  }
			: undefined,
		name: prediction.structured_formatting.main_text,
		address: prediction.structured_formatting.secondary_text,
	})
}

const parseGooglePlacePredictionPagination = (prediction: any) => {
	return new GPlace({
		placeId: prediction.place_id,
		name: prediction.name,
		address: prediction.formatted_address,
		location: prediction.geometry
			? {
					longitude: prediction.geometry.location.lng,
					latitude: prediction.geometry.location.lat,
			  }
			: undefined,
	})
}

const parseAssets = (assets: any[]) => {
	const price = assets.find((asset) => asset.type === 'price')?.value
	const bookingData = assets.find((asset) => asset.type === 'booking')?.value
	const booking: IBooking | undefined = bookingData
		? {
				currency: bookingData.currency,
				freeCancellation: bookingData.free_cancellation,
				name: bookingData.name,
				price: bookingData.price,
				rate: bookingData.rate,
				url: bookingData.url,
		  }
		: undefined
	return {
		phone: assets.find((asset) => asset.type === 'phone')?.value,
		link: assets.find((asset) => asset.type === 'url')?.value,
		open_hours: assets.find((asset) => asset.type === 'open_hours')?.value,
		price: price?.price ? `${price.price} ${price.currency}` : null,
		booking,
	}
}

const parseSaved = ({ saved: _saved }: { saved: boolean }): boolean => {
	return _saved
}

export default () => ({
	parseSpot,
	parseSpots,
	parseGooglePlacePrediction,
	parseGooglePlacePredictionPagination,
	parseGooglePlacePredictionsPagination,
	parseGooglePlacePredictions,
	parseGooglePlaceDetails,
	parseAssets,
	parseSaved,
})
