




































































import Vue, { PropOptions } from 'vue'
import { mapGetters } from 'vuex'
import { Spot2 as Spot } from '~/models/Spot'
import PButton from '~/components/PassporterUI/PButton.vue'
import IconBookmark from '~/components/UI/Icons/IconBookmark.vue'
import DeleteDialog from '~/components/UI/DeleteDialog.vue'
import PIconSave from '~/components/PassporterUI/Icon/PIconSave.vue'

export default Vue.extend({
	name: 'SaveSpotButton',
	components: {
		DeleteDialog,
		PButton,
		IconBookmark,
		PIconSave,
	},
	props: {
		spot: {
			type: Object,
			required: true,
		} as PropOptions<Spot>,
		selected: {
			type: Boolean,
			default: undefined,
		},
		xLarge: {
			type: Boolean,
			default: undefined,
		},
		button: {
			type: Boolean,
			default: false,
		},
		icon: {
			type: Boolean,
			default: false,
		},
		ghost: {
			type: Boolean,
			default: false,
		},
		ghostOutlined: {
			type: Boolean,
			default: false,
		},
		outlined: {
			type: Boolean,
			default: false,
		},
		infoWindow: {
			type: Boolean,
			default: false,
		},
		insideGuide: {
			type: Boolean,
			default: false,
		},
		inPlanner: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {}
	},
	computed: {
		...mapGetters({
			isAuthenticated: 'auth/isAuthenticated',
			itinerary: 'itinerary/itinerary',
			screenSize: 'screenSize',
		}),
		itineraryId(): string {
			return this.$route.params.itinerary_id
		},
		thisButtonisInItinerary(): boolean {
			return (
				this.itineraryId &&
				this.itinerary &&
				this.itineraryId === this.itinerary.id &&
				this.itinerary?.isEditable
			)
		},
		isSpotinItinerary(): boolean {
			if (this.thisButtonisInItinerary) {
				return this.itinerary?.markers?.some(
					(itineraryMarkers: Spot) => itineraryMarkers.id === this.spot.id
				)
			}

			if (typeof this.selected === 'boolean') {
				return this.selected
			}

			return false
		},
	},
	methods: {
		toggleAgregate() {
			if (this.selected !== undefined) {
				this.emitClickEvent()
				return
			}

			if (!this.isAuthenticated) {
				this.emitEventToLogin()
				return
			}

			if (this.inPlanner) {
				this.handleIPlannerCase()
				return
			}
			if (this.thisButtonisInItinerary) {
				this.handleInItineraryCase()
			} else {
				this.openSpotDialog()
			}
		},
		emitClickEvent() {
			this.$emit('click')
		},
		emitEventToLogin() {
			this.$nuxt.$emit('open-auth')
		},
		openSpotDialog() {
			this.$nuxt.$emit('open-spotDialog', this.spot)
		},
		async addSpotToItinerary() {
			await this.$store.dispatch('itinerary/addSpotToItinerary', {
				itineraryId: this.itinerary.id,
				spotId: this.spot.id,
			})
			this.$nuxt.$emit('saved-spot')
			this.$store.dispatch('alerts/setSuccess', {
				text: `${this.$t('toast_addedto')} ${this.$t('organize_card_heading')}`,
			})
		},
		async handleInItineraryCase() {
			if (!this.isSpotinItinerary) {
				await this.addSpotToItinerary()
				this.addTrackOfSaveSpot()
				this.closeDialog()
			} else {
				this.openDeleteConfirmationDialog()
			}
		},
		openDeleteConfirmationDialog() {
			this.$nuxt.$emit('open-delete', this.confirmDeleteItinerarySpot)
		},
		async confirmDeleteItinerarySpot() {
			await this.$store.dispatch('itinerary/deleteSpot', {
				itineraryId: this.itinerary.id,
				id: this.spot.id,
			})
			this.$store.dispatch('alerts/setSuccess', { text: this.$t('toast_placeremoved') })
			this.closeDialog()
		},
		async handleIPlannerCase() {
			if (!this.isSpotinItinerary) {
				await this.$store.dispatch('itinerary/addSpotToItinerary', {
					itineraryId: this.itinerary.id,
					spotId: this.spot.id,
				})
			} else {
				await this.$store.dispatch('itinerary/deleteSpot', {
					itineraryId: this.itinerary.id,
					id: this.spot.id,
				})
			}
		},
		closeDialog() {
			this.$nuxt.$emit('close-window')
		},
		addTrackOfSaveSpot() {
			this.$mixpanel?.track('Spot Map Save')

			const context = this.$tracker.getContext(this.$route?.name)
			if (!context) return
			const parameters = {
				action: 'add spot',
				element: 'spot',
				platform: 'web',
				context,
				title: this.spot.name || '',
				id: this.spot.id || '',
				client: 'passporter',
			}
			this.$tracker.event('add', parameters)
		},
	},
})
