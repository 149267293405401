




import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
	name: 'GoogleOneTapSignIn',
	data() {
		return {
			CLIENT_ID: process.env.OAUTH_GOOGLE_CLIENT_ID,
		}
	},
	computed: {
		...mapGetters({
			isAuthenticated: 'auth/isAuthenticated',
		}),
	},
	mounted() {
		window.onload = () => {
			this.promptSignIn()
		}
	},
	methods: {
		promptSignIn() {
			const _google = google as any
			const accounts = _google.accounts
			if (accounts && !this.isAuthenticated && !this.$route.path.includes('auth')) {
				accounts.id.initialize({
					client_id: this.CLIENT_ID,
					cancel_on_tap_outside: false,
					prompt_parent_id: 'g_id_onload',
					itp_support: true,
					callback: async (response: any) => {
						try {
							if (response.credential) {
								await this.$store.dispatch('auth/loginOAuth', {
									provider: 'google',
									token: response.credential,
									strategy: 'login',
								})
								this.$nuxt.$emit('authenticated')
							}
						} catch (e) {}
					},
				})
				accounts.id.prompt()
				this.$mixpanel?.track('Google register impression')
			}
		},
	},
})
