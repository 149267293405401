























import Vue from 'vue'
import { mapGetters } from 'vuex'
import Chip from '~/components/PassporterUI/Chip.vue'

export default Vue.extend({
	name: 'SpotChip',
	components: { Chip },

	props: {
		title: {
			type: String,
			default: '',
		},
		description: {
			type: String,
			default: '',
		},
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
		}),
	},
})
