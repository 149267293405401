import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { MediaModuleState } from './media'
import { SpotModuleState } from '~/store/spot'
import Responsive from '~/models/Responsive'
import { MapsModuleState } from '~/store/maps'
import { AuthModuleState } from '~/store/auth'
import ErrorView from '~/models/ErrorView'
import ThemeCollection from '~/common/passporter-styles/themes/ThemeCollection'
import themes from '~/common/passporter-styles/themes'
import { serialize } from '~/models/helpers/serialize'

interface RootInitState {
	subdomain?: string
	appHeaderSize: number
	mainHeaderSize: number
	responsive: Responsive
	theme?: ThemeCollection
	scrolled: boolean
	spot?: SpotModuleState
	maps?: MapsModuleState
	auth?: AuthModuleState
	error?: ErrorView
	media?: MediaModuleState
}
const initState = (): RootInitState => ({
	appHeaderSize: 0,
	mainHeaderSize: 0,
	responsive: new Responsive(),
	scrolled: false,
	theme: undefined as ThemeCollection | undefined,
})
export const state = initState
export type RootState = ReturnType<() => RootInitState>

export const mutations: MutationTree<RootState> = {
	setSubdomain(state, subdomain: string) {
		state.subdomain = subdomain
	},
	serialize(state) {
		const responsive = new Responsive()
		responsive.screenSize = state.responsive.screenSize
		state.responsive = responsive
		state.theme = serialize(ThemeCollection, state.theme)
		state.error = serialize(ErrorView, state.error)
	},
	setScreenSize(state, size: number) {
		state.responsive.screenSize = size
	},
	setScreenWidth(state, width: number) {
		state.responsive.setScreenWidth(width)
	},
	setAppHeaderSize(state, size) {
		state.appHeaderSize = size
	},
	setMainHeaderSize(state, size) {
		state.mainHeaderSize = size
	},
	setScrollPosition(state, position) {
		if (position <= 1) {
			state.scrolled = false
		} else if (position && position > 150) {
			state.scrolled = true
		}
	},
	setErrorView(state, error: ErrorView) {
		state.error = error
	},
	setTheme(state, theme: ThemeCollection) {
		state.theme = theme
	},
}

export const actions: ActionTree<RootState, RootState> = {
	async nuxtServerInit({ dispatch, commit }, { req, route, $device }) {
		if ($device.isMobile) {
			commit('setScreenSize', 1)
		} else {
			commit('setScreenSize', 4)
		}
		try {
			await dispatch('auth/setUser')
		} catch (e: any) {
			await dispatch('auth/clear')
		}
		let subdomain = req.headers.host.split('.')[0].split(':')[0]

		if (route.query.b2b) {
			subdomain = route.query.b2b
		}

		const defaultTheme = themes.find((theme) => theme.name === 'passporter-light')

		if (defaultTheme) {
			const initThemes = new ThemeCollection({
				current: defaultTheme,
				list: themes,
			})
			commit('setTheme', initThemes)
		}

		commit('setSubdomain', subdomain)
	},
	nuxtClientInit({ commit, dispatch }) {
		commit('serialize')
		dispatch('auth/serialize')
		dispatch('media/serialize')
		// @ts-ignore
		const device = this.$device

		const resizeCallback = () => {
			if (!device.isMobile) {
				const width = window.innerWidth
				commit('setScreenWidth', width)
			}
		}
		resizeCallback()
		window.addEventListener('resize', resizeCallback)
	},
	setAppHeaderSize({ commit }, size) {
		commit('setAppHeaderSize', size)
	},
	setMainHeaderSize({ commit }, size) {
		commit('setMainHeaderSize', size)
	},
	goToScrollPosition({ commit }, position) {
		if (process.client) {
			const mainScroll = (document as any).querySelector('.v-application--wrap')
			if (mainScroll) {
				mainScroll.scrollTo({ top: 0 })
			}
			commit('setScrollPosition', position)
		}
	},
	setScrollPosition({ commit }, position) {
		commit('setScrollPosition', position)
	},
	async openHome(
		{ dispatch },
		{ query, replace = false }: { query?: any; replace?: boolean } = {}
	) {
		try {
			const route = `${this.$i18n.locale}/home${query || ''}`
			if (replace) {
				await this.$router.replace(`/${route}`)
			} else {
				await this.$router.push(`/${route}`)
			}
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async share(_, shareData: { title: string; text: string; url: string }) {
		try {
			if (window.navigator.share) {
				await window.navigator.share(shareData)
			}
		} catch (e) {}
	},
	toStore({}, data) {
		// @ts-ignore
		const device = this.$device
		if (data.platform === 'android' || (data.platform === 'app' && !device.isIos)) {
			if (data.redirect) {
				window.location.replace(process.env.APP_ANDROID_LINK || '')
			} else {
				window.open(process.env.APP_ANDROID_LINK, '_blank')
			}
			return true
		}
		if (data.platform === 'ios' || (data.platform === 'app' && device.isIos)) {
			if (data.redirect) {
				window.location.replace(process.env.APP_IOS_LINK || '')
			} else {
				window.open(process.env.APP_IOS_LINK, '_blank')
			}
			return true
		}
		if (data.platform === 'huawei') {
			if (data.redirect) {
				window.location.replace(process.env.APP_HUAWEI_LINK || '')
			} else {
				window.open(process.env.APP_HUAWEI_LINK, '_blank')
			}
			return true
		}
		if (data.platform === 'extension' || data.platform === 'ext') {
			if (data.redirect) {
				window.location.replace(process.env.EXTENSION_LINK || '')
			} else {
				window.open(process.env.EXTENSION_LINK, '_blank')
			}
			return true
		}
		return false
	},
	errorView({ commit }, e: any) {
		const code = e.response?.data?.code
		const status = e.response?.data.status
		if (code && status) {
			const error = new ErrorView({ message: code, statusCode: status })
			commit('setErrorView', error)
			if (
				['invalid_invitation', 'already_used_invitation', 'itinerary_not_found'].includes(code) &&
				process.client &&
				window.$nuxt
			) {
				window.$nuxt.error(error)
				return true
			}
			return false
		}
	},
	async error({ dispatch }, e) {
		if (e.formErrors && Object.values(e.formErrors).length) {
			throw e
		} else if (e.response?.data?.code) {
			await dispatch('alerts/apiError', e)
		} else {
			await dispatch('alerts/genericError')
		}
	},
}

export const getters: GetterTree<RootState, RootState> = {
	appHeaderSize: (state) => state.appHeaderSize,
	mainHeaderSize: (state) => state.mainHeaderSize,
	scrolled: (state) => state.scrolled,
	screenSize: (state) => state.responsive,
	theme: (state) => state.theme,
}
