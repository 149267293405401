




















import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
	name: 'Col',
	props: {
		tag: {
			type: String,
			default: 'div',
		},
		justify: {
			type: String,
			default: null,
		},
		align: {
			type: String,
			default: null,
		},
		cols: {
			type: [Number, String],
			default: 12,
		},
		content: {
			type: Boolean,
			default: false,
		},
		padding: {
			type: Boolean,
			default: false,
		},
		shrink: {
			type: Boolean,
			default: false,
		},
		gap: {
			type: [Number, String],
			default: undefined,
		},
		height: {
			type: String,
			default: undefined,
		},
		width: {
			type: String,
			default: undefined,
		},
	},
	data() {
		return {}
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
		}),
		style() {
			return {
				'width': this.width,
				'height': this.height,
				'min-width': this.width,
				'min-height': this.height,
				'max-width': this.width,
				'max-height': this.height,
				'gap': this.gap && `${Number(this.gap)}px`,
			}
		},
	},
	methods: {},
})
