





































import Vue from 'vue'

import Form from '~/components/UI/Form.vue'
import InputText from '~/components/PassporterUI/InputText.vue'
import PButton from '~/components/PassporterUI/PButton.vue'
import { validEmail } from '~/services/Validations'

export type MailFormData = {
	email: string
}

export default Vue.extend({
	name: 'PasswordlessLoginForm',
	components: { PButton, Form, InputText },
	props: {
		error: {
			type: String,
			default: undefined,
		},
	},
	data() {
		return {
			email: '',
		}
	},
	methods: {
		passwordValidate(value: string) {
			if (!value) {
				return this.$t('error_invalid_password')
			}
			return true
		},
		emailValidate(value: string) {
			if (!value) {
				return this.$t('E_error_invalid_email')
			}
			if (!validEmail(value)) {
				return this.$t('E_error_invalid_email')
			}
			return true
		},
	},
})
