import { AuthEntityMapper } from './entityMapper'
import { UserAuthToken } from '~/models/AuthToken'

export class AuthService {
	repository: any
	constructor(repository: any) {
		this.repository = repository
	}

	async login({
		email,
		password,
		token,
	}: {
		email?: string
		password?: string
		token?: string
	}): Promise<UserAuthToken | undefined> {
		const res = await this.repository.login({ email, password, token })
		const { status, data } = res
		if (status !== 200) return undefined
		return AuthEntityMapper.obtainTokenInfo(data)
	}

	async passwordlessLogin(email: string, metricsUrl?: string | undefined): Promise<boolean> {
		const res = await this.repository.passwordlessLogin({ email, url: metricsUrl })
		const { status } = res

		return status === 204
	}

	async loginOAuth(
		provider: string,
		token: string,
		clickId: string,
		referral?: string | null,
		metricsUrl?: string | undefined
	): Promise<Record<string, any> | undefined> {
		const res = await this.repository.loginOAuth({
			provider,
			token,
			click_id: clickId,
			referral,
			url: metricsUrl,
		})

		const { status, data } = res
		if (status !== 200) return undefined
		return AuthEntityMapper.obtainTokenInfo(data)
	}

	async register(
		email: string,
		password: string,
		clickId: string,
		referral?: string | null,
		metricsUrl?: string | undefined
	): Promise<Record<string, string>> {
		const res = await this.repository.register({
			email,
			password,
			click_id: clickId,
			referral,
			url: metricsUrl,
		})

		const { data } = res
		return AuthEntityMapper.obtainRegisteredUser(data)
	}

	async checkUsernameAvailability(username: string): Promise<Boolean> {
		const res = await this.repository.auth.availableUserName(username)
		const { data } = res
		return AuthEntityMapper.obtainAvailability(data)
	}

	async refresh(token: string): Promise<{ access: string; refresh: string }> {
		const res = await this.repository.refresh(token)
		const { data } = res
		return AuthEntityMapper.obtainRefreshedToken(data)
	}
}
