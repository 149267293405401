interface ItineraryCategoryData {
	id: string
	name: string
	logo: string
	selected?: boolean
}

export default class ItineraryCategory {
	_id: string
	_name: string
	_logo: string
	_selected: boolean
	constructor(itineraryCategory: ItineraryCategoryData | ItineraryCategory) {
		this._id = itineraryCategory.id
		this._name = itineraryCategory.name
		this._logo = itineraryCategory.logo
		this._selected = itineraryCategory.selected || false
	}

	get id(): string {
		return this._id
	}
	set id(id: string) {
		this._id = id
	}

	get name(): string {
		return this._name
	}
	set name(name: string) {
		this._name = name
	}

	get logo(): string {
		return this._logo
	}
	set logo(logo: string) {
		this._logo = logo
	}

	get selected(): boolean {
		return this._selected
	}
	set selected(selected: boolean) {
		this._selected = selected
	}

	toJSON() {
		return {
			id: this.id,
			name: this.name,
			logo: this.logo,
			selected: this.selected,
		}
	}
}
