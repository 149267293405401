






import Vue from 'vue'

export default Vue.extend({
	name: 'PSkeleton',
	props: {
		width: {
			type: [Number, String],
			default: '100%',
		},
		height: {
			type: [Number, String],
			default: '100%',
		},
	},
	computed: {
		_width(): number | string {
			return isNaN(Number(this.width)) ? this.width : `${this.width}px`
		},
		_height(): number | string {
			return isNaN(Number(this.height)) ? this.height : `${this.height}px`
		},
	},
})
