




























import Vue from 'vue'
import PButton from '~/components/PassporterUI/PButton.vue'
import Cover from '~/components/PassporterUI/Cover.vue'

export default Vue.extend({
	name: 'ConfirmMagicLinkScreen',
	components: { Cover, PButton },
	props: {
		email: {
			type: String,
			default: undefined,
		},
	},
})
