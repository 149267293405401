




















import Vue from 'vue'
import { mapGetters } from 'vuex'
import Itinerary, { ItineraryForm } from '~/models/Itinerary'
import { Spot2 as Spot } from '~/models/Spot'
import Media from '~/models/Media'
import ItineraryDialog from '~/components/context/guides/spots/ItineraryDialog.vue'
import { DialogType } from '~/components/UI/Dialog.vue'

const initForm = () => ({
	itineraryId: undefined as string | undefined,
	saved: false,
})

export default Vue.extend({
	name: 'SpotDialog',
	components: {
		ItineraryDialog,
	},
	data() {
		return {
			form: initForm(),
			step: undefined as undefined | number,
			showDialog: false,
			spot: undefined as undefined | Spot,
			coversUrl: [] as string[],
			dialog: undefined as undefined | DialogType,
			firstStep: 0,
			secondStep: 1,
			lastStep: 2,
		}
	},
	computed: {
		...mapGetters({
			guide: 'guide/guide',
			screenSize: 'screenSize',
			ownItineraries: 'profile/ownItineraries',
			moreOwnItineraries: 'profile/moreOwnItineraries',
			destination: 'destination/destination',
			isAuthenticated: 'auth/isAuthenticated',
			itineraries: 'profile/itineraries',
		}),
	},
	methods: {
		open(spot?: Spot) {
			this.spot = spot
			this.mosaicCovers()
			this.dialog = this.$refs.itineryDialog as DialogType
			this.dialog.open()
			this.form = initForm()
			this.form.saved = true
		},
		goPreviousStep() {
			this.dialog!.goToStep(this.secondStep)
		},
		async handleSaveSpot() {
			if (await this.addSpotToItinerary(this.form.itineraryId!, this.spot!.id!)) {
				this.dialog!.goToStep(this.lastStep)
				this.form.saved = true
				this.addTrackOfSaveSpot()
			} else {
				this.goPreviousStep()
			}
		},
		addTrackOfSaveSpot() {
			const context = this.$tracker.getContext(this.$route?.name)
			if (!context) return
			const parameters = {
				action: 'add spot',
				element: 'spot',
				platform: 'web',
				context,
				title: this.spot?.name || '',
				id: this.spot?.id || '',
				client: 'passporter',
			}
			this.$tracker.event('add', parameters)
		},
		async addSpotToItinerary(itineraryId: string, spotId: string): Promise<boolean> {
			return await this.$store.dispatch('itinerary/addSpotToItinerary', {
				itineraryId,
				spotId,
			})
		},
		async whenCreateItineraryGoLastStep(): Promise<void> {
			this.dialog!.goToStep(this.lastStep)
			this.addButtonTracker('create_itinerary_cta')
			const itinerary = await this.createItinerary()
			if (!itinerary) return this.dialog!.goToStep(this.firstStep)

			await this.obtainUserItineraries()
			this.form.itineraryId = itinerary.id!
			await this.handleSaveSpot()
		},

		async createItinerary(): Promise<Itinerary | undefined> {
			const _form: ItineraryForm = this.obtainForm()
			return await this.$store.dispatch('itinerary/create', _form)
		},
		async openItinerary() {
			await this.$store.dispatch('itinerary/openItinerary', {
				itineraryId: this.form.itineraryId,
			})
		},
		async obtainUserItineraries() {
			await this.$store.dispatch('profile/getOwnItineraries')
		},
		setChoosenItinerary(id: string) {
			this.form.itineraryId = id
		},
		mosaicCovers(): void {
			this.coversUrl = []
			if (!this.spot) return

			this.addCoverUrl(this.spot.cover)
			this.addMediaUrls(this.spot.medias)
			this.makeSureCoversAreOk()
		},

		makeSureCoversAreOk() {
			const badNumberToSeeImages = 2
			if (this.coversUrl.length === badNumberToSeeImages) {
				this.coversUrl.splice(-1)
			}
		},

		loadMoreItineraries() {
			this.$store.dispatch('profile/getMoreOwnItineraries')
		},
		obtainForm(): ItineraryForm {
			return {
				id: null,
				destination: this.destination,
				isPrivate: true,
				startDate: undefined,
				endDate: undefined,
				showDate: false,
				option: 'next',
				coverId: null,
				recommendedSpots: false,
			}
		},
		addButtonTracker(value: string) {
			this.$mixpanel.track('Click', {
				page: 'spot',
				context: 'modal',
				value,
				component: 'button',
			})
		},
		addCoverUrl(url: string | undefined): void {
			if (!url) return
			this.coversUrl.push(url)
		},
		addMediaUrls(medias: Media[] | undefined): void {
			if (!medias) return
			medias.forEach((media: Media) => {
				if (media.url) {
					this.coversUrl.push(media.url)
				}
			})
		},
	},
})
