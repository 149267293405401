var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',_vm._b({staticClass:"p-item-list gap-2",class:( _obj = {
		'background-hover-color': !_vm.selected
	}, _obj[_vm.backgroundColor] = _vm.selected && !_vm.isHexBgColor, _obj['cursor-pointer'] =  Boolean(_vm.$listeners.click) && !_vm.disabled, _obj[_vm.textColor] = Boolean(_vm.textColor) && !_vm.isHexTextColor, _obj.disabled = _vm.disabled, _obj ),style:(Object.assign(( _obj$1 = {}, _obj$1["--item-bg-hover"] = _vm.hoverColorValue, _obj$1 ),
		{color: _vm.isHexTextColor && _vm.textColor},
		{background: _vm.selected && _vm.isHexBgColor && _vm.backgroundColor},
		_vm.styles)),on:{"click":function($event){return _vm.$emit('click', $event)}}},'li',_vm.$attrs,false),[_vm._t("icon"),_vm._v(" "),_c('div',{staticClass:"d-flex flex-grow-1"},[_vm._t("default")],2),_vm._v(" "),_vm._t("action")],2)}
var staticRenderFns = []

export { render, staticRenderFns }