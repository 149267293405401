import { Context } from '@nuxt/types'
import { AxiosRequestConfig } from 'axios'

export interface AxiosConfig extends AxiosRequestConfig {
	api?: {
		multilang: boolean
		url: string
		nonAuthenticated: boolean
	}
}

export default function ({ store, app: { $axios, i18n }, redirect }: Context) {
	const IGNORED_PATHS = ['auth/login/', 'auth/logout/', 'auth/refresh/']

	$axios.onRequest((config: AxiosConfig) => {
		if (config.api) {
			config.baseURL = config.api.url
			config.headers = {}
			if (config.api.multilang) {
				config.baseURL = config.baseURL.concat(`${i18n.locale}/`)
			}
		}
		if (store.state.auth.accessToken && !config.api?.nonAuthenticated) {
			config.headers.Authorization = 'Bearer ' + store.state.auth.accessToken
		}
		config.headers['Accept-Language'] = i18n.locale
		return config
	})

	$axios.onError((error) => {
		return new Promise((resolve, reject) => {
			const isIgnored = IGNORED_PATHS.some((path) => error.config.url?.includes(path))

			const statusCode = error.response ? error.response.status : -1
			const { data: { code } = { code: null } } = error.response || {}
			if (Object.prototype.hasOwnProperty.call(error.config, 'retryAttempts')) {
				if (process.client) {
					store
						.dispatch('auth/clear')
						.then(() => {
							return redirect(`/${i18n.locale}`)
						})
						.catch(() => {
							return reject(error)
						})
				} else {
					return reject(error)
				}
			} else if ((statusCode === 401 || statusCode === 422) && !isIgnored) {
				const refreshToken = store.state.auth.refreshToken

				if (code === 'authentication_failed' && refreshToken) {
					const config = { retryAttempts: 1, ...error.config }

					try {
						store
							.dispatch('auth/refresh')
							.then(() => {
								return resolve($axios(config))
							})
							.catch(() => {
								if (process.client) {
									store
										.dispatch('auth/clear')
										.then(() => {
											return redirect(`/${i18n.locale}`)
										})
										.catch(() => {
											return reject(error)
										})
								} else {
									return reject(error)
								}
							})
					} catch (e: any) {
						if (process.client) {
							store
								.dispatch('auth/clear')
								.then(() => {
									return redirect(`/${i18n.locale}`)
								})
								.catch(() => {
									return reject(error)
								})
						} else {
							return reject(error)
						}
					}
				} else {
					return reject(error)
				}
			} else {
				return reject(error)
			}
		})
	})
}
