import Block, { BlockContentType } from '~/models/Block'
import BaseService from '~/services/BaseService'
import { BlockEntityMapper } from '~/passporter-services/block/entityMapper'

export default class DiscoveryService extends BaseService {
	async obtainBlocks(): Promise<Block<BlockContentType>[]> {
		const res = await this.repositories.discovery.getHomeBlocks()
		const { data } = res
		const blocks = []
		for (const block of data.results) {
			const parsedBlock = BlockEntityMapper.toBlock(block)
			blocks.push(parsedBlock)
		}
		return blocks
	}
}
