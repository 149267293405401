


















import Vue from 'vue'

export default Vue.extend({
	name: 'InputRadio',
	props: {
		value: {
			type: [Boolean, String],
			default: undefined,
		},
		option: {
			type: [Boolean, String],
			default: undefined,
		},
		label: {
			type: String,
			default: undefined,
		},
		bgColor: {
			type: String,
			default: undefined,
		},
		width: {
			type: Number,
			default: 20,
		},
		height: {
			type: Number,
			default: 20,
		},
	},
	data() {
		return {
			inputValue: undefined as string | undefined,
		}
	},
	computed: {
		color(): string {
			if (this.bgColor) {
				return 'var(--color-' + this.bgColor + ')'
			} else {
				return 'currentColor'
			}
		},
		style(): Object {
			const style = {
				accentColor: ` ${this.color}`,
				width: ` ${this.width}px`,
				height: ` ${this.height}px`,
				verticalAlign: 'middle',
			}
			return style
		},
		localValue: {
			get(): any {
				return this.value ?? this.inputValue
			},
			set(newValue: string) {
				this.inputValue = newValue
				this.$emit('input', newValue)
			},
		},
	},
})
