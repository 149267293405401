






























































































import Vue, { PropOptions } from 'vue'
import { mapGetters } from 'vuex'
import MosaicImages from '~/components/PassporterUI/MosaicImages.vue'
import PrivateTag from '~/components/context/items/itineraries/PrivateTag.vue'
import Itinerary from '~/models/Itinerary'
import Col from '~/components/UI/Col.vue'
import Row from '~/components/UI/Row.vue'
import PSkeleton from '~/components/PassporterUI/PSkeleton.vue'
import EmptyCardItinerary from '~/components/context/items/itineraries/EmptyCardItinerary.vue'
import Avatar from '~/components/PassporterUI/Avatar.vue'
import Traveler from '~/models/Traveler'

export default Vue.extend({
	name: 'CardItinerary',
	components: {
		PSkeleton,
		Row,
		PrivateTag,
		MosaicImages,
		Col,
		EmptyCardItinerary,
		Avatar,
	},
	props: {
		itinerary: {
			type: Object,
			default: undefined,
		} as PropOptions<Itinerary | undefined>,
		baseDomain: {
			type: Boolean,
			required: false,
		},
		showPrivacity: {
			type: Boolean,
			required: false,
		},
		cols: {
			type: Number,
			default: undefined,
		},
		textColor: {
			type: String,
			default: 'content',
		},
		aspectRatio: {
			type: Number,
			default: 4 / 3,
		},
		mobileWidth: {
			type: String,
			default: '175px',
		},
		desktopWidth: {
			type: String,
			default: '310px',
		},
		footerShadow: {
			type: Boolean,
			default: false,
		},
		avatar: {
			type: Traveler,
			default: undefined,
		},
		noMargin: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {}
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
		}),
		covers(): string[] {
			const covers = []
			if (this.itinerary?.cover) {
				covers.push(this.itinerary?.cover)
			} else if (this.itinerary?.covers) {
				covers.push(...this.itinerary?.covers)
			}
			return covers
		},
		itineraryRoute(): string {
			if (this.itinerary?.id) {
				return this.$navigation.itinerary.getRoute(this.itinerary.id)
			}
			return ''
		},
		itineraryClass(): Object {
			return {
				'card': !this.screenSize.xs && !this.footerShadow,
				'border-card': this.footerShadow,
				'cursor-pointer': this.itinerary,
				'rounded-2': this.screenSize.xs,
				'rounded-4': !this.screenSize.xs,
				'border-xs': !this.screenSize.xs,
			}
		},
	},
	methods: {
		goToItinerary(itinerary?: Itinerary) {
			if (this.$listeners.click) {
				this.$emit('click', itinerary)
			} else if (itinerary?.id) {
				this.$router.push(this.$navigation.itinerary.getRoute(itinerary.id))
			}
		},
		countDays(date: any, date2: any) {
			if (date && date2) {
				return Math.ceil(
					(new Date(date2).getTime() - new Date(date).getTime()) / (1000 * 3600 * 24)
				)
			} else {
				return 0
			}
		},
	},
})
