











import Vue from 'vue'
import PIconClose from '~/components/PassporterUI/Icon/PIconClose.vue'
import PButton from '~/components/PassporterUI/PButton.vue'

export default Vue.extend({
	name: 'PdfViewer',
	components: { PIconClose, PButton },
	data() {
		return {
			src: '',
		}
	},
	methods: {
		open(src: string) {
			this.src = src
		},
		close(): void {
			this.src = ''
		},
	},
})
