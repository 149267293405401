export interface CurrencyData {
	code: string
}
export default class Currency implements CurrencyData {
	private readonly _code
	constructor(currency: CurrencyData) {
		this._code = currency.code
	}

	get code(): string {
		return this._code
	}

	get symbol(): string {
		return (0)
			.toLocaleString(undefined, {
				style: 'currency',
				currency: this.code,
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
			})
			.replace(/[\d ]/g, '')
			.trim()
	}

	toJSON(): CurrencyData {
		return {
			code: this.code,
		}
	}
}
