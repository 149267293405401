import Note from '~/models/Note'

const parseNotes = (notes: any[]) => {
	return notes.map(
		(note: any) =>
			new Note({
				description: note.description,
				id: note.id,
				numMedias: note.num_medias,
				title: note.title,
				data: note.data
					? {
							origin: note.data.origin,
							destination: note.data.destination,
							departure: note.data.departure,
							bookingNumber: note.data.booking_number,
							reference: note.data.reference,
							checkin: note.data.checkin,
							checkout: note.data.checkout,
							address: note.data.address,
							spotId: note.data.spotId,
							date: note.data.date,
							fileUrl: note.data.file_url,
							voucherUrl: note.data.voucher_url,
					  }
					: undefined,
				type: note.type,
				isEditable: note.editable,
			})
	)
}

export default () => ({
	parseNotes,
})
