var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dropdown',{scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var isActive = ref.isActive;
return [_c('div',{staticClass:"rounded-pill"},[_c('Chip',{staticClass:"text-primary-500",attrs:{"default-avatar":true,"x-large":"","selected-outline-color":"neutral-200","outlined":"","is-hover-background":"","hover-color":"neutral-100","color":"neutral-25","avatar":_vm.user.avatar}},[_c('span',[_c('PIconChevron',{staticClass:"text-primary-500",attrs:{"down":!isActive,"up":isActive,"width":"20","height":"20"}})],1)])],1)]}},{key:"default",fn:function(ref){
var close = ref.close;
return [_c('PList',{staticClass:"py-4",attrs:{"width":"300"}},[_c('PListItem',{staticClass:"px-4",on:{"click":function($event){_vm.goToMyProfile()
					close()}}},[_c('span',{staticClass:"text-link"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('Menu_My_Profile'))+"\n\t\t\t\t")])]),_vm._v(" "),_c('PListItem',{staticClass:"px-4",on:{"click":function($event){_vm.goToMyItineraries()
					close()}}},[_c('span',{staticClass:"text-link"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('Profile_Owner_Tapbar_My_itineraries'))+"\n\t\t\t\t")])]),_vm._v(" "),(!_vm.screenSize.xs)?[_c('Divider',{staticClass:"my-4"}),_vm._v(" "),_c('PListItem',{staticClass:"px-4",on:{"click":function($event){_vm.download('app')
						close()}},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('img',{attrs:{"loading":"lazy","src":require("assets/icons/ArrowRight.svg"),"height":"24px","width":"24px","alt":""}})]},proxy:true}],null,true)},[_c('p',{staticClass:"text-body ma-0"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.$t('Web_Header_Menu_DownloadApp'))+"\n\t\t\t\t\t")]),_vm._v(" "),_c('Spacer')],1),_vm._v(" "),_c('PListItem',{staticClass:"px-4",on:{"click":function($event){_vm.download('extension')
						close()}},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('img',{attrs:{"loading":"lazy","src":require("assets/icons/ArrowRight.svg"),"height":"24px","width":"24px","alt":""}})]},proxy:true}],null,true)},[_c('p',{staticClass:"text-body ma-0"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.$t('Web_Header_Menu_Extension'))+"\n\t\t\t\t\t")]),_vm._v(" "),_c('Spacer')],1)]:_vm._e(),_vm._v(" "),(!_vm.screenSize.xs)?_c('Divider',{staticClass:"my-4"}):_vm._e(),_vm._v(" "),(!_vm.screenSize.xs)?_c('PListItem',{staticClass:"px-4",on:{"click":function($event){_vm.$nuxt.$emit('open-profileEdit')
					close()}}},[_c('p',{staticClass:"text-body ma-0"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('Menu_Account'))+"\n\t\t\t\t")])]):_vm._e(),_vm._v(" "),_c('PListItem',{staticClass:"px-4",attrs:{"text-color":"text-error-500"},on:{"click":function($event){_vm.logout()
					close()}}},[_c('p',{staticClass:"text-body ma-0"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('Profile_Owner_Log_Out_Popup_Logout_Button'))+"\n\t\t\t\t")])])],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }