var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Row',{staticClass:"chip transition-all cursor-pointer",class:{
		'box-shadow': _vm.shadow,
		'background-active-color': _vm.isActiveBackground,
		'background-hover-color': _vm.isHoverBackground,
		'chip-x-small': _vm.xSmall,
		'chip-small': _vm.small,
		'chip-large': _vm.large,
		'chip-x-large': _vm.xLarge,
		'px-3': !(_vm.avatar || _vm.avatarEnd || _vm.defaultAvatar),
		'pl-0 pr-3': _vm.avatar || _vm.defaultAvatar,
		'pl-3 pr-0': _vm.avatarEnd,
		'pr-0': _vm.avatarEnd || _vm.$slots.avatarIcon,
		'chip-icon': _vm.isIcon,
		'border-xs': _vm.outlined || (_vm.selected && _vm.selectedOutlined) || (!_vm.selected && _vm.unselectedOutlined),
	},style:(( _obj = {}, _obj["--chip-bg-hover"] = _vm.hoverColorValue, _obj["--chip-bg-active"] = _vm.activeColorValue, _obj["--chip-border-hover"] = _vm.borderHoverColorValue, _obj.backgroundColor = (!(_vm.selected && _vm.selectedColor) && _vm.hexColor(_vm.color)) || (_vm.selected && _vm.hexColor(_vm.selectedColor)), _obj.color = (!(_vm.selected && _vm.selectedTextColor) && _vm.hexColor(_vm.textColor)) ||
			(_vm.selected && _vm.hexColor(_vm.selectedTextColor)), _obj.borderColor = (!(_vm.selected && _vm.selectedOutlinedColor) && _vm.hexColor(_vm.outlinedColor)) ||
			(_vm.selected && _vm.hexColor(_vm.selectedOutlinedColor)), _obj )),attrs:{"cols":_vm.cols,"align":"center","gap":8},on:{"click":_vm.clicked,"mouseenter":_vm.mouseEntered,"mouseleave":_vm.mouseLeaved}},[(_vm.defaultAvatar)?_c('Avatar',{staticClass:"ml-1 bg-neutral-200",attrs:{"src":_vm.avatar,"width":32,"height":32,"chip":""}}):_vm._e(),_vm._v(" "),_vm._t("icon",function(){return [(_vm.icon)?_c(_vm.asyncIcon,{tag:"component",attrs:{"width":24,"height":24}}):_vm._e()]}),_vm._v(" "),(_vm.$slots.default)?[(_vm.xSmall)?_c('span',{staticClass:"text-detail-s d-flex align-center"},[_vm._t("default")],2):_c('span',{staticClass:"text-body d-flex align-center",class:{
				'font-weight-bold': _vm.selectedBold,
				'max-content': _vm.maxContent,
			}},[_vm._t("default")],2)]:_vm._e(),_vm._v(" "),_vm._t("after"),_vm._v(" "),(_vm.avatarEnd || _vm.$slots.avatarIcon)?_c('Avatar',{staticClass:"mr-1 bg-neutral-200",attrs:{"src":_vm.avatarEnd,"width":32,"height":32,"chip":""}},[(_vm.$slots.avatarIcon)?_vm._t("avatarIcon"):_vm._e()],2):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }