











































import Vue from 'vue'
import Cover from '~/components/PassporterUI/Cover.vue'
import PIconChevron from '~/components/PassporterUI/Icon/PIconChevron.vue'

export default Vue.extend({
	name: 'ImagesCarousel',
	components: { Cover, PIconChevron },
	props: {
		transition: {
			type: String,
			default: 'slide-to-left',
		},
		width: {
			type: String,
			default: undefined,
		},
		height: {
			type: String,
			default: undefined,
		},
		images: {
			type: Array,
			default: () => [],
		},
		original: {
			type: Boolean,
			default: false,
		},
		showArrows: {
			type: Boolean,
			default: false,
		},
		showDots: {
			type: Boolean,
			default: true,
		},
		dotsOffset: {
			type: String,
			default: '0',
		},
		autoplay: {
			type: Boolean,
			default: false,
		},
		autoplayInterval: {
			type: Number,
			default: 8000,
		},
		continuous: {
			type: Boolean,
			default: false,
		},
		size: {
			type: [Number, String],
			default: 6,
		},
		lazy: {
			type: Boolean,
			default: true,
		},
		flex: {
			type: Boolean,
			default: false,
		},
		overlayFromBottom: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			active: 0 as number,
			interval: null as null | ReturnType<typeof setInterval>,
		}
	},
	computed: {
		dotsSize(): { width?: string; height?: string } {
			return {
				height: `${this.size}px `,
				width: `${this.size}px `,
			}
		},
		styles(): { width?: string; height?: string } {
			return {
				height: this.height,
				width: this.width,
			}
		},
	},
	watch: {
		images() {
			if (this.active > 0) {
				this.active = 0
			}
		},
	},
	mounted() {
		if (this.autoplay) {
			this.interval = setInterval(() => {
				this.slide(1)
			}, this.autoplayInterval)
		}
	},
	beforeDestroy() {
		if (this.autoplay && this.interval) {
			clearInterval(this.interval)
		}
	},
	methods: {
		jump(index: number) {
			this.active = index
			this.$emit('slide', index)
		},
		slide(sum: number) {
			if (this.continuous || this.autoplay) {
				if (sum > 0) {
					if (this.active + 1 > this.images?.length - 1) {
						this.active = 0
					} else {
						this.active += 1
					}
				} else if (this.active - 1 < 0) {
					this.active = this.images?.length - 1
				} else {
					this.active -= 1
				}
			} else if (sum > 0) {
				this.active = Math.min(this.images?.length - 1, Math.max(0, this.active + 1))
			} else {
				this.active = Math.min(this.images?.length - 1, Math.max(0, this.active - 1))
			}
			this.$emit('slide', this.active)
		},
	},
})
