const resource = 'v1/destinations'
export default (http: any) => ({
	getDestination(id: string) {
		return http.get(`${resource}/${id}/`)
	},
	search(q: string, page: number) {
		return http.get(`${resource}/search/`, {
			params: {
				q,
				page,
				page_size: 10,
			},
		})
	},
	categories(destinationId: string, page: number) {
		return http.get(`${resource}/${destinationId}/categories/`, {
			params: {
				page,
				page_size: 10,
			},
		})
	},
	find(placeId: string) {
		return http.get(`${resource}/find/`, {
			params: {
				place_id: placeId,
			},
		})
	},
	googleFind({ placeId }: { placeId: string }) {
		return http.get(`${resource}/find_place/`, {
			params: {
				place_id: placeId,
			},
		})
	},
	markers(destinationId: string) {
		const _destinationId = destinationId
		return http.get(`${resource}/${_destinationId}/markers/`)
	},
	trendy() {
		return http.get(`${resource}/trendy/`)
	},
	getCurrentWeather(destinationId: string) {
		return http.get(`${resource}/${destinationId}/weather/current/`)
	},
	getNextWeather(destinationId: string) {
		return http.get(`${resource}/${destinationId}/weather/forecast/`)
	},
})
