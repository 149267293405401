export function validEmail(text: string) {
	const pattern = /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
	return pattern.test(text)
}

export function validPassword(text: string) {
	const pattern = /^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[\W_]).{8,}$/
	return pattern.test(text)
}

export function validTextLength(
	text: string | undefined,
	minLength: number,
	maxLength: number
): boolean {
	if (!text) return false
	return text.length >= minLength && text.length <= maxLength
}
