


































import Vue from 'vue'
import { mapGetters } from 'vuex'
import Dialog, { DialogType } from '~/components/UI/Dialog.vue'
import { SiteInfo } from '~/models/SiteInfo'
import MicrositeInfoDialogContent from '~/components/context/microsites/MicrositeInfoDialogContent.vue'
import BottomSheet from '~/components/PassporterUI/BottomSheet.vue'

export default Vue.extend({
	name: 'MicrositeInfoDialog',
	components: {
		Dialog,
		MicrositeInfoDialogContent,
		BottomSheet,
	},
	data() {
		const show: boolean = false
		const siteInfo: SiteInfo = {} as SiteInfo
		const dialog: DialogType | null = null as unknown as DialogType
		return {
			show,
			siteInfo,
			dialog,
		}
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
		}),
	},
	methods: {
		open(info: SiteInfo) {
			this.show = true
			this.siteInfo = info
			this.dialog = this.$refs.dialog as DialogType
			this.dialog?.open()
		},
		close() {
			this.show = false
		},
	},
})
