







import Vue from 'vue'
import RootDialogs from '~/components/context/core/RootDialogs.vue'
import Col from '~/components/UI/Col.vue'

export default Vue.extend({
	components: { Col, RootDialogs },
	middleware({ store }) {
		store.dispatch('goToScrollPosition', 0)
	},
})
