export interface ProfileData {
	id: string
	name?: string
	username?: string
	cover?: string
	avatar?: string
	email?: string
	location?: string
}

export default class Profile implements ProfileData {
	private readonly _id
	private _name
	private _username
	private readonly _cover
	private readonly _avatar
	private readonly _email
	private readonly _location
	constructor(profile: ProfileData) {
		this._id = profile.id
		this._name = profile.name
		this._username = profile.username
		this._cover = profile.cover
		this._avatar = profile.avatar
		this._email = profile.email
		this._location = profile.location
	}

	get id(): string {
		return this._id
	}
	get name(): string | undefined {
		return this._name
	}
	set name(name: string | undefined) {
		this._name = name
	}
	get firstName(): string | undefined {
		return this._name?.split(' ').shift()
	}
	get initials(): string {
		const _fullName = this._name?.split(' ')
		const _name = _fullName?.shift()
		const _lastname = _fullName?.pop()
		const _initials =
			(_name ? _name.charAt(0) : '') + (_lastname ? _lastname.charAt(0) : '')
		return _initials.toUpperCase()
	}
	get username(): string | undefined {
		return this._username
	}
	set username(username: string | undefined) {
		this._username = username
	}
	get cover(): string | undefined {
		return this._cover
	}
	get avatar(): string | undefined {
		return this._avatar
	}
	get email(): string | undefined {
		return this._email
	}
	get location(): string | undefined {
		return this._location
	}

	toJSON(): ProfileData {
		return {
			id: this.id,
			name: this.name,
			username: this.username,
			cover: this.cover,
			avatar: this.avatar,
			email: this.email,
			location: this.location,
		}
	}
}
