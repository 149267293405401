























import Vue from 'vue'
import PIconMapPin from '~/components/PassporterUI/Icon/PIconMapPin.vue'
import PIconPhone from '~/components/PassporterUI/Icon/PIconPhone.vue'
import PIconGlobe from '~/components/PassporterUI/Icon/PIconGlobe.vue'
import SpotChip from '~/components/context/guides/spots/SpotChip.vue'
import Divider from '~/components/PassporterUI/Divider.vue'

export default Vue.extend({
	name: 'SpotChips',
	components: { PIconMapPin, PIconPhone, PIconGlobe, SpotChip, Divider },

	props: {
		web: {
			type: String,
			default: '',
		},
		phone: {
			type: String,
			default: '',
		},
		address: {
			type: String,
			default: '',
		},
	},
})
