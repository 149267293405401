// @ts-ignore
window.dataLayer = window.dataLayer || []
export function gAnalytics4() {
	// @ts-ignore
	dataLayer.push(arguments)
}
// @ts-ignore
gAnalytics4('js', new Date())
// @ts-ignore

gAnalytics4('config', process.env.GOOGLE_ANALYTICS_ID)
