import Media from './Media'
import { serialize } from './helpers/serialize'

export enum NoteType {
	Basic = 'basic',
	Transport = 'transport',
	Accommodation = 'accommodation',
	Transfer = 'transfer',
	Activity = 'activity',
	Internet = 'internet',
	Insurance = 'insurance',
	Visa = 'visa',
	Voucher = 'voucher',
}

export interface NoteData {
	title: string
	type: NoteType
	description: string
	isEditable?: boolean
	mediaIds?: string[]
	id?: string | null
	data?: ExtraCategoryNote
	numMedias?: number
	isTemplate?: boolean
}

export type ExtraCategoryNote =
	| Transport
	| Accomodation
	| Transfer
	| Activity
	| Voucher

export type Transport = {
	origin?: string
	destination?: string
	departure?: Date
	arrival?: Date
	bookingNumber?: string
	reference?: string
}

export type Accomodation = {
	checkin?: Date
	checkout?: Date
	address?: string
	spotId?: string
}

export type Transfer = {
	origin?: string
	destination?: string
	date?: Date
}

export type Activity = {
	address?: string
	date?: Date
}

export type Voucher = {
	voucherUrl?: string
	fileUrl?: string
}

export type VoucherNote = Note2 & {
	data: Voucher
}

export type Note2 = {
	title: string
	type: NoteType
	description: string
	isEditable?: boolean
	mediaIds?: string[]
	id?: string | null
	data?: ExtraCategoryNote
	numMedias?: number
	medias?: Media[]
	isTemplate?: boolean
}

export default class Note implements NoteData {
	title: string
	type: NoteType
	description: string
	isEditable?: boolean
	mediaIds?: string[]
	id?: string | null
	data?: Transport | Accomodation | Transfer | Activity | Voucher
	numMedias?: number
	medias?: Media[]
	isTemplate?: boolean

	constructor(note?: NoteData) {
		this.id = note?.id || null
		this.title = note?.title || ''
		this.type = (note?.type as NoteType) || ''
		this.numMedias = note?.numMedias || 0
		this.mediaIds = serialize(Media, note?.mediaIds)
		this.description = note?.description || ''
		this.data = note?.data
		this.isEditable = note?.isEditable ?? true
		this.isTemplate = note?.isTemplate ?? false
	}
}
