






import Vue from 'vue'

export default Vue.extend({
	name: 'Container',
	props: {
		height: {
			type: [String, Number],
			default: undefined,
		},
		width: {
			type: [String, Number],
			default: undefined,
		},
	},
	data() {
		return {}
	},
	computed: {
		style() {
			return {
				width: this.width,
				height: this.height,
			}
		},
	},
})
