import Traveler from '~/models/Traveler'

const parseTraveler = (traveler: any) =>
	new Traveler({
		name: traveler.name,
		picture: traveler.picture ?? traveler.picture_url,
		role: traveler.role,
		id: traveler.user_id ?? traveler.id,
		username: traveler.username,
		email: traveler.email,
		language: traveler.language,
	})

const parseTravelers = (travelers: any[]) => {
	return travelers.map((traveler) => parseTraveler(traveler))
}

export default () => ({
	parseTraveler,
	parseTravelers,
})
